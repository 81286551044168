/* ------ Module imports ------ */
import { ChevronRightIcon, ChevronUpIcon } from '@heroicons/react/solid';

/* ------ Local components ------ */
import Expanded from './expanded';

function YoutubeChannel(props) {
  const {
    channel,
    expanded,
    onRemoved,
    onToggleExpanded,
  } = props;

  return (
    <div className="mb-2 rounded-lg border border-slate-200 overflow-hidden">
      <button
        className="w-full text-left flex items-center justify-between px-4 py-2 bg-slate-100 hover:bg-slate-200"
        onClick={onToggleExpanded}
      >
        <div className="flex items-center">
          <div
            className="bg-cover w-8 h-8 rounded-full"
            style={{ backgroundImage: `url(${channel.profile_image_url})` }}
          />
          <div className="ml-4">
            <p className="text-sm font-semibold text-slate-900">{channel.name}</p>
            <p className="text-sm text-slate-900">{`Added by ${channel.youtube_user.name}`}</p>
          </div>
        </div>

        {expanded
          ? <ChevronUpIcon className="h-4" />
          : <ChevronRightIcon className="h-4" />}
      </button>

      {expanded && (
        <Expanded
          channel={channel}
          onRemoved={onRemoved}
        />
      )}
    </div>
  );
}

export default YoutubeChannel;
