import Button from '@mui/material/Button';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';

import DataWarning from './data-warning';
import Settings from './settings';
import { getSourceBlob } from 'views/game/utils/game-file-downloader';

export default function Setup(props) {
  return (
    <div className='flex flex-col w-full justify-start items-center'>
      <Button
        startIcon={<DownloadForOfflineOutlinedIcon />}
        onClick={props.onStart}
        size='small'
        color='inherit'
        variant='contained'
        className='bg-blue-500 hover:bg-blue-600 text-white mt-6'>
        Start Download
      </Button>
      {
        !getSourceBlob() &&
        <DataWarning />
      }
      {
        getSourceBlob() &&
        <span className='inline-block text-xs text-slate-400 leading-tight mt-2'>
          There is a copy of this video ready for download.
        </span>
      }
      <Settings />
    </div>
  );
}