/* ------ Module imports ------ */
import { Link } from 'react-router-dom';

function AcceptInvite(props) {
  const {
    token,
    view,
  } = props;

  if (view === 'loading') {
    // TODO - loading spinner
    return null;
  }

  function renderInner() {
    if (view === 'error') {
      return (
        <div>
          <h1>Could not find invite</h1>
          <p>This invite may have already been accepted, or expired.</p>
        </div>
      );
    }

    return (
      <div>
        <p className="font-semibold">Do you already have a My Action Sport account?</p>
        <Link
          className="block mt-4 bg-slate-100 hover:bg-slate-200 rounded-lg w-full text-left px-4 py-4"
          to={`/login?redirect=/invitation/${token}`}
        >
          Yes take me to login
        </Link>
        <Link
          className="block mt-4 bg-slate-100 hover:bg-slate-200 rounded-lg w-full text-left px-4 py-4"
          to={`/signup?redirect=/invitation/${token}`}
        >
          No I need to signup
        </Link>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md py-20">
        <img
          alt="MAR Logo"
          className="w-28 mx-auto mb-10"
          src="/assets/img/logo.png"
        />

        <div className="border border-slate-200 rounded-lg p-4">
          {renderInner()}
        </div>
      </div>
    </div>
  );
}

export default AcceptInvite;
