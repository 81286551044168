/* ------ Components ------ */
import Input from 'components/input';

function Signup(props) {
  const {
    confirmPassword,
    email,
    name,
    onChange,
    onSubmit,
    password,
    submitting,
    error
  } = props;

  return (
    <div className="p-6">
      {error && <div className="text-red-500 text-center"> {error} </div>}
      <form
        disabled={submitting}
        onSubmit={onSubmit}
      >
        <Input
          disabled={submitting}
          label="Your Name"
          autoComplete='name'
          onChange={v => onChange('name', v)}
          placeholder="Eg. John Appleseed"
          value={name}
          required
        />

        <Input
          className="mt-6"
          disabled={submitting}
          label="Your Email"
          placeholder='e.g. john@appleseed.com'
          autoComplete='email'
          onChange={v => onChange('email', v)}
          type="email"
          value={email}
          required
        />

        <Input
          className="mt-6"
          disabled={submitting}
          label="Unique Password"
          placeholder='••••••'
          autoComplete='new-password'
          onChange={v => onChange('password', v)}
          type="password"
          value={password}
          required
        />

        <Input
          className="mt-6"
          disabled={submitting}
          label="Confirm Password"
          autoComplete='new-password'
          placeholder='••••••'
          onChange={v => onChange('confirmPassword', v)}
          type="password"
          value={confirmPassword}
          required
        />

        <p className='mt-2'>Please make sure these are <em>your personal login values</em>. You will be asked to join or create a business in the next step.</p>

        <button
          className="mt-6 w-full bg-blue-500 hover:bg-blue-600 rounded-lg text-white py-3 text-sm font-semibold"
          disabled={submitting}
          type="submit"
        >
          Signup
        </button>
      </form>
    </div>
  );
}

export default Signup;
