function Details(props) {
  const {
    channel,
    onRemove,
  } = props;

  return (
    <div className="border-t border-slate-200 p-4">
      <div>
        <p className="text-sm text-slate-900 font-medium">Linked from Google Account</p>
        <div className="mt-1 flex items-center">
          <div
            className="bg-cover w-5 h-5 rounded-full"
            style={{ backgroundImage: `url(${channel.youtube_user.profile_image_url})` }}
          />
          <p className="ml-2 text-slate-900 text-sm">{channel.youtube_user.name}</p>
        </div>
      </div>

      <button
        className="mt-6 bg-red-100 hover:bg-red-200 text-red-600 rounded-lg px-2 py-1 inline-block font-semibold text-xs"
        onClick={onRemove}
      >
        Delete this channel
      </button>
    </div>
  );
}

export default Details;
