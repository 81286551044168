import dayjs from 'dayjs';

import ActionsMenu from './actions-menu';
import { useGame, useSport } from '../utils/game-manager';
import { EyeIcon, ClockIcon } from '@heroicons/react/outline';
import capitalize from 'utils/capitalize';

export default function Title() {
  const game = useGame();
  const sport = useSport();
  return (
    <div className='flex w-full flex-row justify-start items-center gap-4 mt-4'>
      {
        sport?.icon?.startsWith('http') &&
        <img
          alt=''
          role='presentation'
          className="w-8"
          src={sport.icon} />
      }
      <div className='flex flex-1 flex-col justify-start items-start'>
        <h1 className="font-black text-slate-900 text-lg">{game?.title || 'Match'}</h1>
        {
          (game?.created || game?.['stream_started']) &&
          <span className="inline-flex justify-start items-center leading-none gap-1 text-xs text-slate-700 mt-2">
            <ClockIcon className='h-3' />
            {dayjs.unix(game.created || game['stream_started']).format('h:mma, D MMM YYYY')}
          </span>
        }
        <span className="inline-flex justify-start items-center leading-none gap-1 text-xs text-slate-700 mt-1">
          <EyeIcon className='h-3' />
          {capitalize(game?.visibility || 'public')}
        </span>
      </div>
      <ActionsMenu />
    </div>
  );
}