/* ------ View import ------ */
import ImageList from './image-list.view';

function ImageListContainer(props) {
  const {
    data,
    field,
  } = props;

  return (
    <ImageList
      data={data}
      field={field}
    />
  );
}

export default ImageListContainer;
