/* ------ Module imports ------ */
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Components ------ */
import Popover from 'components/popover';

/* ------ Utils ------ */
import auth from 'utils/auth';

function Dropdown(props) {
  const { onClose } = props;

  const { user } = useContext(PlatformContext);

  const navigate = useNavigate();

  async function onLogout() {
    await auth.logout();
    navigate('/login');
  }

  return (
    <Popover
      className="mr-7"
      onClose={onClose}
    >
      <div className="px-4 py-3">
        <p className="font-medium text-slate-700">{user.name}</p>
        <p className="text-xs text-slate-700">{user.email}</p>
      </div>

      <div className="py-1 border-t border-slate-200">
        <Link
          className="block text-left w-full px-4 py-1 text-sm font-semibold text-blue-600 hover:bg-slate-100"
          to="/profile"
        >
          Profile
        </Link>
        <button
          className="text-left w-full px-4 py-1 text-sm font-semibold text-amber-500 hover:bg-slate-100"
          onClick={onLogout}
          type="button"
        >
          Logout
        </button>
      </div>
    </Popover>
  );
}

export default Dropdown;
