import { useEffect, useState } from 'react';

import storage from 'utils/storage';
import { getGameId } from './game-manager';

let storageKey;
let available;
let selected = [];
let listeners = [];

export function useHighlights() {
  const [invalidating, setInvalidating] = useState(false);
  const [highlights, setHighlights] = useState(get());
  useEffect(() => {
    const onUpdate = () => {
      setInvalidating(true);
      requestAnimationFrame(() => {
        setHighlights(get() || []);
        requestAnimationFrame(() => setInvalidating(false));
      });
    };
    onUpdate();
    return registerListener(onUpdate);
  }, []);
  return !invalidating ? (Array.isArray(highlights) ? highlights : []) : undefined;
}

export default async function init(highlights) {
  available = (Array.isArray(highlights) ? highlights : []).filter(h => (h?.id?.length || 0) > 0);
  if ((getGameId()?.length || 0) > 0) {
    storageKey = `highlights_manager_${getGameId()}`;
    selected = await storage.get(storageKey);
    if (!Array.isArray(selected)) {
      selected = [];
    }
    const valid = available.map(h => h?.id);
    selected = selected.filter(s => valid.includes(s?.id));
  }
  else {
    storageKey = null;
    selected = [];
  }
  notifyListeners();
}

export function get() {
  return Array.isArray(available) ? JSON.parse(JSON.stringify(available)) : undefined;
}

function notifyListeners() {
  for (const listener of listeners) {
    requestAnimationFrame(function () { try { listener(); } catch (err) { } });
  }
}

export function registerListener(listener) {
  listeners.push(listener);
  requestAnimationFrame(function () { try { listener(); } catch (err) { } });
  return () => deregisterListener(listener);
}

export function deregisterListener(listener) {
  listeners = listeners.filter(l => l !== listener);
}