import { Component, useState } from 'react';

import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PencilIcon from '@heroicons/react/outline/PencilIcon';

import { toast } from 'react-toastify';

import api from 'utils/api';

import Spinner from 'components/spinner/spinner';
import { getGameId, getGameTitle, invalidate } from '../utils/game-manager';

let instance;

function TitleInput(props) {
  const [focussed, setFocussed] = useState(false);
  return (
    <TextField
      variant='outlined'
      error={(props.value?.length || 0) === 0}
      placeholder='A title for this match'
      margin='none'
      multiline={false}
      value={props.value}
      fullWidth={true}
      required={true}
      onChange={(e) => {
        const v = e?.target?.value;
        if (typeof v === 'string' && v.length <= 64) {
          props.onValueChange(v);
        }
      }}
      inputProps={{
        'aria-label': 'match title',
        maxLength: 64
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {
              props.loading &&
              <Spinner className='text-blue-500 h-4' />
            }
            {
              !props.loading &&
              <>
                {
                  focussed &&
                  <span className={`text-xs ${(props.value?.length || 0) < 64 ? 'text-blue-500' : 'text-red-500'}`.trim()}>
                    {props.value.length}
                  </span>
                }
                {
                  !focussed &&
                  <PencilIcon className='h-4' />
                }
              </>
            }
          </InputAdornment>
        ),
      }}
      autoFocus={true}
      onFocus={() => setFocussed(true)}
      onBlur={() => setFocussed(false)}
      helperText='Max length 64 characters. Cannot be empty.'
      className={props.className} />
  );
}

export default class RenameMatchTitleDialog extends Component {
  static open() {
    return new Promise((resolve) => {
      if (instance && !instance?.state.open) {
        instance.setState({
          open: true,
          value: getGameTitle() || '',
        }, () => resolve(!!instance?.state?.open));
      }
      else {
        resolve(!!instance?.state?.open);
      }
    });
  }
  static close() {
    return new Promise((resolve) => {
      if (instance && instance?.state.open) {
        instance.setState({
          open: false,
        }, () => resolve(!instance?.state?.open));
      }
      else {
        resolve(!instance?.state?.open);
      }
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      value: getGameTitle() || '',
    };
    instance = this;
  }
  componentDidMount() {
    instance = this;
  }
  componentWillUnmount() {
    instance = instance === this ? null : instance;
  }
  _onConfirm(e) {
    try { e.stopPropagation(); } catch (err) { }
    try { e.preventDefault(); } catch (err) { }
    if (this.state.value !== getGameTitle()) {
      this.setState({
        loading: true,
      }, async () => {
        try {
          await api.patch(`/game/${getGameId()}`, { title: this.state.value });
          await invalidate();
          RenameMatchTitleDialog.close();
        } catch (err) {
          toast.error('Something went wrong, please check title length and try again. You may need to reload the page.');
        } finally {
          this.setState({ loading: false });
        }
      });
    }
    else {
      RenameMatchTitleDialog.close();
    }
  }
  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={this.state.open && (getGameId()?.length || 0) > 0}
        disableEscapeKeyDown={this.state.loading}
        onClose={!this.state.loading ? RenameMatchTitleDialog.close : undefined}
        aria-labelledby='rename-dialog-title'>
        <DialogTitle id='rename-dialog-title' className='text-lg font-semibold'>
          Rename video title
        </DialogTitle>
        <DialogContent className='p-4'>
          <form onSubmit={this._onConfirm.bind(this)} className='w-full'>
            <TitleInput
              value={this.state.value}
              onValueChange={(v) => this.setState({ value: v })}
              loading={this.state.loading} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            color='inherit'
            disabled={this.state.loading}
            onClick={RenameMatchTitleDialog.close}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='inherit'
            disabled={this.state.loading || (this.state.value?.length || 0) <= 0 || (this.state.value?.length || 0) > 64}
            onClick={this._onConfirm.bind(this)}
            className='bg-blue-500 hover:bg-blue-600 text-white'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}