/* ------ Module imports ------ */
import { Link, useSearchParams } from 'react-router-dom';

/* ------ Local components ------ */
import Login from './login';
import Signup from './signup';
import RequestResetPassword from './requestresetpassword/requestresetpassword.container';
import ResetPassword from './resetPassword/resetpassword.container';

import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';

function Auth(props) {
  const { view } = props;
  const [searchParams] = useSearchParams();
  return (
    <div className="w-full min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md py-20">
        <img
          alt="MAR Logo"
          className="w-28 mx-auto mb-10"
          src="/assets/img/logo.png"
        />

        <div className="border border-slate-200 rounded-lg">
          {
            (view === 'login' || view === 'signup') &&
            <div className="flex px-6 border-b border-slate-200">
              <Link
                className={`-mb-px pt-4 pb-2 uppercase text-sm font-semibold border-b-4 ${view === 'login' ? 'text-blue-500 border-blue-500' : 'text-slate-700 border-transparent'}`}
                to="/login"
              >
                Login
              </Link>
              <Link
                className={`-mb-px pt-4 pb-2 ml-4 uppercase text-sm font-semibold border-b-4 ${view === 'signup' ? 'text-blue-500 border-blue-500' : 'text-slate-700 border-transparent'}`}
                to="/signup"
              >
                Signup
              </Link>
            </div>
          }
          {
            ((view?.length || 0) === 0 || view === 'login') &&
            <Login />
          }
          {
            view === 'signup' &&
            <Signup />
          }
          {
            view === 'reset-password' && !searchParams.get("token") &&
            <RequestResetPassword />
          }
          {
            view === 'reset-password' && searchParams.get("token") &&
            <ResetPassword token={searchParams.get("token")} />
          }
        </div>

        <a href="https://scoring.myactionsport.com" className="inline-flex gap-1 justify-center items-center w-full text-xs text-blue-600 font-semibold whitespace-pre-line leading-tight text-center mt-6">
          This is the admin platform - are you looking to score a match?
          <OpenInNewOutlined className='text-xs' />
        </a>

        <span className="inline-block text-xs text-slate-500 whitespace-pre-line leading-tight text-center mt-6">
          {'This form is protected by reCAPTCHA and the Google '}
          <a href="https://policies.google.com/privacy" target='_blank' rel='noopener noreferrer' className="inline underline">Privacy&nbsp;Policy</a>
          {' and '}
          <a href="https://policies.google.com/terms" target='_blank' rel='noopener noreferrer' className="inline underline">Terms&nbsp;of&nbsp;Service</a> apply.
        </span>

      </div>
    </div>
  );
}

export default Auth;
