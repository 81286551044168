import { Component } from 'react';

import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';

import { toast } from 'react-toastify';

import api from 'utils/api';

import Spinner from 'components/spinner/spinner';
import { getGame, getGameId, invalidate } from '../utils/game-manager';
import capitalize from 'utils/capitalize';
import { InputAdornment } from '@mui/material';

let instance;

function getHelperText(value) {
  let text;
  switch (value) {
    case 'public':
      text = 'Your video will feature on the Watch and/or third party platforms and anybody can search for, discover, and watch this match.';
      break;
    case 'unlisted':
      text = 'Your video will not feature on the Watch or third party platforms (where they support hiding videos) or on its search - so the general public won\'t be able to discover it. You can directly link users to view it however.';
      break;
    case 'private':
      text = 'Your video will not be available on the Watch or third party platforms at all.';
      break;
    default:
      text = '';
      break;
  }
  return text;
}

function VisibilitySelect(props) {
  return (
    <div className='flex flex-row justify-center items-center w-full max-w-[500px] mt-2'>
      <FormControl fullWidth={true}>
        <InputLabel htmlFor='visibility-dialog-select'>Visibility</InputLabel>
        <Select
          value={props.value}
          disabled={props.loading}
          onChange={(e) => props.onValueChange(e?.target?.value)}
          autoFocus={true}
          displayEmpty={true}
          required={true}
          renderValue={capitalize}
          input={
            <OutlinedInput
              label='Visibility'
              id='visibility-dialog-select'
              aria-describedby="visibility-helper-text"
              endAdornment={
                props.loading &&
                (
                  <InputAdornment position='end'>
                    <Spinner className='text-blue-500' />
                  </InputAdornment>
                )
              }
            />}
          MenuProps={{
            className: 'w-full max-w-[450px]'
          }}
          classes={{
            icon: props.loading ? 'hidden' : undefined,
          }}>
          <MenuItem value='' disabled={true}>
            <em>Please select</em>
          </MenuItem>
          <MenuItem value='public' className='flex flex-col justify-start items-start w-full'>
            Public
            <div className='text-xs text-slate-500 whitespace-normal'>{getHelperText('public')}</div>
          </MenuItem>
          <MenuItem value='unlisted' className='flex flex-col justify-start items-start'>
            Unlisted
            <span className='text-xs text-slate-500 whitespace-normal'>{getHelperText('unlisted')}</span>
          </MenuItem>
          <MenuItem value='private' className='flex flex-col justify-start items-start'>
            Private
            <span className='text-xs text-slate-500 whitespace-normal'>{getHelperText('private')}</span>
          </MenuItem>
        </Select>
        <FormHelperText id="visibility-helper-text" className='leading-tight mt-2'>{getHelperText(props.value)}</FormHelperText>
      </FormControl>
    </div>
  );
}

export default class ChangeVideoVisibilityDialog extends Component {
  static open() {
    return new Promise((resolve) => {
      if (instance && !instance?.state.open) {
        instance.setState({
          open: true,
          value: getGame()?.visibility || 'public',
        }, () => resolve(!!instance?.state?.open));
      }
      else {
        resolve(!!instance?.state?.open);
      }
    });
  }
  static close() {
    return new Promise((resolve) => {
      if (instance && instance?.state.open) {
        instance.setState({
          open: false,
        }, () => resolve(!instance?.state?.open));
      }
      else {
        resolve(!instance?.state?.open);
      }
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      value: getGame()?.visibility || 'public',
    };
    instance = this;
  }
  componentDidMount() {
    instance = this;
  }
  componentWillUnmount() {
    instance = instance === this ? null : instance;
  }
  _onConfirm(e) {
    try { e.stopPropagation(); } catch (err) { }
    try { e.preventDefault(); } catch (err) { }
    if (this.state.value !== getGame()?.visibility) {
      this.setState({
        loading: true,
      }, async () => {
        try {
          await api.patch(`/game/${getGameId()}`, { visibility: this.state.value });
          await invalidate();
          ChangeVideoVisibilityDialog.close();
        } catch (err) {
          toast.error('Something went wrong, please check the video is still live and try again. You may need to reload the page.');
        } finally {
          this.setState({ loading: false });
        }
      });
    }
    else {
      ChangeVideoVisibilityDialog.close();
    }
  }
  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={this.state.open && (getGameId()?.length || 0) > 0}
        disableEscapeKeyDown={this.state.loading}
        onClose={!this.state.loading ? ChangeVideoVisibilityDialog.close : undefined}
        aria-labelledby='visibility-dialog-title'>
        <DialogTitle id='visibility-dialog-title' className='text-lg font-semibold'>
          Change video visibility
        </DialogTitle>
        <DialogContent className='flex justify-center items-center p-4'>
          <VisibilitySelect
            value={this.state.value}
            onValueChange={(v) => this.setState({ value: v })}
            loading={this.state.loading} />
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            color='inherit'
            disabled={this.state.loading}
            onClick={ChangeVideoVisibilityDialog.close}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='inherit'
            disabled={this.state.loading || (this.state.value?.length || 0) <= 0 || (this.state.value?.length || 0) > 64}
            onClick={this._onConfirm.bind(this)}
            className='bg-blue-500 hover:bg-blue-600 text-white'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}