/* ------ Module imports ------ */
import { ChevronRightIcon } from '@heroicons/react/solid';

function List(props) {
  const {
    channels,
    onSelect,
  } = props;

  return (
    <div className="px-12 py-10">
      <h1 className="mb-6 font-black text-3xl text-slate-900">Select a YouTube channel to use</h1>
      {channels.length === 0 && (
        <div>
          <p className="text-sm font-medium text-slate-900">There are no YouTube channels available for the authorized account</p>
          <p className="text-sm text-slate-900">Please see <a className="underline" target="_blank" href="https://myactionsport.com" rel="noreferrer">this link</a> for more info</p>
        </div>
      )}

      {channels.map(channel => (
        <button
          className="mb-2 border border-slate-200 rounded-lg flex items-center justify-between bg-slate-100 hover:bg-slate-200 px-4 py-2 w-full"
          key={channel.youtube_id}
          onClick={() => onSelect(channel)}
        >
          <div className="flex items-center">
            <div
              className="bg-cover w-8 h-8 rounded-full"
              style={{ backgroundImage: `url(${channel.profile_image_url})` }}
            />
            <span className="ml-2 font-semibold text-slate-900">{channel.name}</span>
          </div>

          <ChevronRightIcon className="h-4" />
        </button>
      ))}
    </div>
  );
}

export default List;
