import { useState } from 'react';

import { DownloadIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/outline';

import { remove } from '../utils/file-manager';
import { Row, RowButton } from '../accessory-container';
import capitalize from 'utils/capitalize';

function formatBytes(bytes, decimals = 2) {
  if (bytes <= 0) {
    return '';
  }
  else {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`.replace('.00', '');
  }
}

export default function Download(props) {
  const [removing, setRemoving] = useState(false);
  const {
    id,
    title,
    highlight,
    filename,
    blob,
  } = props;
  return (
    <Row
      title={title}
      buttons={
        <>
          <RowButton
            component='a'
            href={URL.createObjectURL(blob)}
            download={filename}
            target='_blank'
            rel='noopener noreferrer'
            tip='Download file as MP4'
            title='Download'
            icon={<DownloadIcon />}
            className='ml-1' />
          <RowButton
            tip='Remove file from download manager (does not delete the highlight)'
            title='Remove file'
            loading={removing}
            onClick={async () => {
              setRemoving(true);
              await remove(id);
              setRemoving(false);
            }}
            icon={<XIcon />}
            className='-mx-2' />
        </>
      }>
      <a
        href={URL.createObjectURL(blob)}
        download={filename}
        target='_blank'
        rel='noopener noreferrer'
        className='w-full flex flex-row items-center text-left justify-start '>
        <div className='flex-1 flex flex-col justify-start items-start'>
          <span className='text-sm font-semibold leading-none line-clamp-1'>
            {title || capitalize(highlight?.type?.replace('-', ' ').replace('_', ' ')) || 'Download'}
            <p className='inline whitespace-pre text-xs text-slate-500'>
              {' ' + formatBytes(blob.size)}
            </p>
          </span>
          {
            (filename?.length || 0) > 0 &&
            <span className='text-xs text-slate-400 leading-snug line-clamp-1'>
              {filename}
            </span>
          }
        </div>
      </a>
    </Row>
  );
}