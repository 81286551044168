/* ------ Module CSS load (as early as possible) ------ */
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';

/* ------ Module imports ------ */
import { createRoot } from 'react-dom/client';

/* ------ Main app import ------ */
import App from './app';

/* ------ Toast provider ------ */
import { toast, ToastContainer } from 'react-toastify';

/* ------ Style imports ------ */
import './styles/tailwind.css';

createRoot(document.getElementById('root'))
  .render(
    <>
      <App />
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnHover={true}
        toastClassName='max-w-[calc(100vw_-_2rem)] mx-auto' />
    </>
  );
