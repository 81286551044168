/* ------ Module imports ------ */
import React from 'react';

/* ------ View import ------ */
import Image from './image.view';

export default function ImageContainer(props) {
  return (
    <Image {...props} />
  );
}