/* ------ Module imports ------ */
import { useContext, useEffect, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Users from './users.view';

function UsersContainer() {
  const { account } = useContext(PlatformContext);

  const [users, setUsers] = useState(null);
  const [invites, setInvites] = useState(null);
  const [inviteToDelete, setInviteToDelete] = useState(null);
  const [view, setView] = useState('loading');
  const [modal, setModal] = useState(null);

  async function fetchUsers() {
    const { data } = (await api.get(`/user?account=${account.id}`)).data;
    return data;
  }

  async function fetchInvites() {
    const { data } = (await api.get(`/invite?account=${account.id}`)).data;
    return data;
  }

  async function fetchData() {
    let loadedUsers = null;
    let loadedInvites = null;
    try {
      [loadedUsers, loadedInvites] = await Promise.all([
        fetchUsers(),
        fetchInvites(),
      ]);
    } catch (e) {
      // Silently ignore
    }

    if (loadedUsers && loadedInvites) {
      setUsers(loadedUsers);
      setInvites(loadedInvites);

      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onInviteAdded(inv) {
    setInvites([...invites, inv]);
  }

  function onInviteRemoved(invite) {
    const updatedInvites = invites.filter(inv => inv.id !== invite.id);
    setInvites(updatedInvites);
  }

  function onRemove(invite) {
    setInviteToDelete(invite);
    setModal('remove');
  }

  return (
    <Users
      invites={invites}
      inviteToDelete={inviteToDelete}
      modal={modal}
      onCloseModal={() => setModal(null)}
      onInvite={() => setModal('invite')}
      onInviteAdded={onInviteAdded}
      onInviteRemoved={onInviteRemoved}
      onRemove={onRemove}
      users={users}
      view={view}
    />
  );
}

export default UsersContainer;
