/* ------ Components ------ */
import Input from 'components/input';
import Button from '@mui/material/Button';
import KeyIcon from '@heroicons/react/outline/KeyIcon';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

/* ------ Local components ------ */
import ChangeEmailModal from './modals/change-email';
import ChangePasswordModal from './modals/change-password';

import { setStep, useStep } from 'views/induction/induction';

function ResetInduction() {
  const step = useStep();
  return (
    <Button
      component='a'
      href='/'
      disabled={step === 0}
      variant='contained'
      color='inherit'
      startIcon={<HelpOutlineOutlinedIcon className='h-4' />}
      className="mt-6 bg-blue-500 hover:bg-blue-600 text-white"
      onClick={() => setStep(0)}>
      Replay setup hints
    </Button>
  );
}

function Details(props) {
  const {
    email,
    modal,
    name,
    onChangePassword,
    onCloseModal,
    onConfirm,
    onEmailChanged,
    onNameChanged,
    onSubmit,
    submitting,
  } = props;

  return (
    <>
      <div className="px-12 py-10 border-b border-slate-200">
        <h2 className="font-black text-xl text-slate-900">Details</h2>

        <div className="w-full max-w-sm">
          <Input
            className="mt-6"
            disabled={submitting}
            label="Name"
            onChange={onNameChanged}
            value={name}
          />
          <Input
            className="mt-6"
            disabled={submitting}
            label="Email"
            onChange={onEmailChanged}
            value={email}
          />

          <Button
            variant='contained'
            startIcon={<SaveOutlinedIcon />}
            color='inherit'
            className="mt-6 bg-blue-500 hover:bg-blue-600 text-white"
            disabled={submitting}
            onClick={onSubmit}>
            Save
          </Button>
        </div>
      </div>

      <div className="flex flex-col justify-start items-start px-12 py-10">
        <h2 className="font-black text-xl text-slate-900">Settings</h2>
        <Button
          variant='contained'
          color='inherit'
          startIcon={<KeyIcon className='h-4' />}
          className="mt-6 bg-blue-500 hover:bg-blue-600 text-white"
          onClick={onChangePassword}>
          Change password
        </Button>
        <ResetInduction />
      </div>

      {modal === 'email' && (
        <ChangeEmailModal
          onCancel={onCloseModal}
          onConfirm={onConfirm}
        />
      )}

      {modal === 'password' && (
        <ChangePasswordModal onClose={onCloseModal} />
      )}
    </>
  );
}

export default Details;
