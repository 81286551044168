import { Component, useEffect, useState } from 'react';

import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { toast } from 'react-toastify';

import {
  EmailShareButton, EmailIcon,
  FacebookShareButton, FacebookIcon,
  FacebookMessengerShareButton, FacebookMessengerIcon,
  LinkedinShareButton, LinkedinIcon,
  RedditShareButton, RedditIcon,
  TumblrShareButton, TumblrIcon,
  TwitterShareButton, TwitterIcon,
  WhatsappShareButton, WhatsappIcon,
} from 'react-share';

import formatDuration from '../utils/timestamp-formatter';

let instance;

function Carousel(props) {
  return (
    <div className='w-full flex flex-row justify-center items-center overflow-auto gap-4 py-4'>
      <EmailShareButton subject={props.data.title} body={props.data.text} url={props.data.url}>
        <EmailIcon size={50} round={true} />
      </EmailShareButton>
      <FacebookShareButton quote={props.data.text} url={props.data.url}>
        <FacebookIcon size={50} round={true} />
      </FacebookShareButton>
      <FacebookMessengerShareButton appId='3335708376442149' url={props.data.url}>
        <FacebookMessengerIcon size={50} round={true} />
      </FacebookMessengerShareButton>
      <TwitterShareButton title={props.data.title} via='CricHQ' url={props.data.url}>
        <TwitterIcon size={50} round={true} />
      </TwitterShareButton>
      <LinkedinShareButton title={props.data.title} summary={props.data.text} url={props.data.url}>
        <LinkedinIcon size={50} round={true} />
      </LinkedinShareButton>
      <RedditShareButton title={props.data.title} url={props.data.url}>
        <RedditIcon size={50} round={true} />
      </RedditShareButton>
      <TumblrShareButton title={props.data.title} caption={props.data.text} url={props.data.url}>
        <TumblrIcon size={50} round={true} />
      </TumblrShareButton>
      <WhatsappShareButton title={props.data.title} url={props.data.url}>
        <WhatsappIcon size={50} round={true} />
      </WhatsappShareButton>
    </div>
  );
}

function CopyButton(props) {
  const [canUseClipboard, setCanUseClipboard] = useState(typeof window === 'undefined' || 'clipboard' in navigator);
  useEffect(() => {
    setCanUseClipboard('clipboard' in navigator);
  }, []);
  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(props.data?.url);
      toast.info('Copied sharing details to clipboard.');
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <button
      autoFocus={true}
      onClick={canUseClipboard ? onCopy : undefined}
      className='group w-full flex flex-row justify-center items-center p-4 mt-6 rounded-lg leading-none bg-slate-100 border border-slate-300'>
      <p className='flex-1 leading-none truncate'>{props.data?.url}</p>
      {
        canUseClipboard &&
        <p className='ml-4 py-2 px-4 leading-none bg-blue-500 rounded-lg text-white transition-opacity opacity-100 group-hover:opacity-75'>
          Copy
        </p>
      }
    </button>
  );
}

export default class ShareGameDialog extends Component {
  static open(title, data, startsAt) {
    return new Promise((resolve) => {
      if (instance && !instance?.state.open) {
        instance.setState({
          open: true,
          title: title,
          data: data,
          allowStartsAt: typeof startsAt === 'number',
          startsAt: startsAt,
        }, () => resolve(!!instance?.state?.open));
      }
      else {
        resolve(!!instance?.state?.open);
      }
    });
  }
  static close() {
    return new Promise((resolve) => {
      if (instance && instance?.state.open) {
        instance.setState({
          open: false,
          title: undefined,
          data: undefined,
          allowStartsAt: true,
          startsAt: undefined,
        }, () => resolve(!instance?.state?.open));
      }
      else {
        resolve(!instance?.state?.open);
      }
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      title: undefined,
      open: false,
      allowStartsAt: true,
      data: undefined,
      startsAt: undefined,
    };
    instance = this;
  }
  componentDidMount() {
    instance = this;
  }
  componentWillUnmount() {
    instance = instance === this ? null : instance;
  }
  render() {
    const data = Object.assign({}, this.state.data);
    if (!this.state.allowStartsAt && data?.url?.includes('?')) {
      try {
        const params = new URLSearchParams(data.url.substring(data.url.indexOf('?') + 1));
        params.delete('t');
        data.url = `${data.url.substring(0, data.url.indexOf('?'))}?${params.toString()}`;
      } catch (err) {
        console.log(err);
      }
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={this.state.open}
        onClose={ShareGameDialog.close}
        aria-labelledby='share-dialog-title'>
        <DialogTitle id='share-dialog-title' className='text-lg font-semibold'>
          {this.state.title || this.state.data?.title || 'Share'}
        </DialogTitle>
        <DialogContent
          dividers={true}
          className='p-4'>
          <Carousel data={data} />
          {
            (data?.url?.length || 0) > 0 &&
            <CopyButton data={data} />
          }
          {
            data?.url?.includes('player.myactionsport.com') &&
            <p className='w-full text-center text-xs px-4 mt-2 text-slate-400 leading-none'>This video is currently private, sharing this link will allow any person on the internet to view it by following and sharing this link. It will otherwise not appear in search engines or&nbsp;be&nbsp;discoverable.</p>
          }
          {
            typeof this.state.startsAt === 'number' && this.state.startsAt > 0 &&
            <FormControlLabel
              control={<Checkbox />}
              checked={this.state.allowStartsAt}
              onChange={() => this.setState({ allowStartsAt: !this.state.allowStartsAt })}
              label={`Starts at ${formatDuration(this.state.startsAt)}`}
              className='mt-2' />
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            color='inherit'
            disabled={this.state.loading}
            onClick={ShareGameDialog.close}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}