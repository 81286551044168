/* ------ Module imports ------ */
import { useContext, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Local components ------ */
import Account from './account';
import Billing from './billing';

function Settings() {
  const { account } = useContext(PlatformContext);

  const [tab, setTab] = useState('account');

  return (
    <div>
      <div className="px-12 pt-10 border-b border-slate-200">
        <h1 className="font-black text-3xl text-slate-900">Account</h1>

        <div className="mt-10 -mb-px flex">
          <button
            className={`px-4 py-3 border-b rounded-t text-sm font-medium ${tab === 'account' ? 'text-blue-600 bg-blue-100 border-blue-200' : 'text-slate-600 bg-slate-100 border-slate-200'}`}
            onClick={() => setTab('account')}
            type="button"
          >
            My Account
          </button>
          <button
            className={`ml-2 px-4 py-3 border-b rounded-t text-sm font-medium ${tab === 'billing' ? 'text-blue-600 bg-blue-100 border-blue-200' : 'text-slate-600 bg-slate-100 border-slate-200'}`}
            onClick={() => setTab('billing')}
            type="button"
          >
            Billing & Invoices
          </button>
        </div>
      </div>

      {tab === 'account' && <Account account={account} />}
      {tab === 'billing' && <Billing account={account} />}
    </div>
  );
}

export default Settings;
