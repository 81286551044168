import { XIcon } from '@heroicons/react/outline';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MaterialIconButton from '@mui/material/IconButton';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useHighlights as useUnfilteredHighlights } from '../../utils/highlights-manager';
import { setTime, useFilters } from '../../utils/highlights-filter-manager';
import { IconButton } from '../../accessory-container';

import dayjs from 'dayjs';
import { useCreated, useStarted } from 'views/game/utils/game-manager';

function Picker(props) {
  return (
    <div className={`flex flex-1 flex-col justify-start items-start ${props.className || ''}`.trim()}>
      <MobileDateTimePicker
        value={props.value}
        minDateTime={props.min}
        maxDateTime={props.max}
        onAccept={(v) => props.onValueChange(dayjs.isDayjs(v) ? v : dayjs(v))}
        format='h:mm a'
        slotProps={{
          textField: {
            size: 'small',
            inputProps: {
              className: 'pl-2 pr-0 py-1.5 text-slate-600 text-sm'
            },
            InputProps: {
              placeholder: props.placeholder || 'Time',
              value: props.value?.isValid() ? props.value.format('h:mma') : '',
              endAdornment: !!props.onClear && dayjs.isDayjs(props.value) && (
                <MaterialIconButton
                  size='small'
                  onClick={(e) => {
                    try { e?.preventDefault(); } catch (err) { }
                    try { e?.stopPropagation(); } catch (err) { }
                    props.onClear();
                  }}>
                  <HighlightOffIcon fontSize='inherit' className='text-slate-400 hover:text-slate-500' />
                </MaterialIconButton>
              ),
              className: 'p-0',
            },
            className: 'p-0 bg-white',
          }
        }} />
      {
        dayjs.isDayjs(props.value) && props.value.isValid() &&
        <span className='text-[0.6rem] text-slate-400 font-normal px-2 mt-1 mb-2 leading-none line-clamp-1'>
          {props.value.format('MMM Do')}
        </span>
      }
    </div>
  );
}

function Start(props) {
  const filters = useFilters();
  return (
    <Picker
      value={filters?.time?.start}
      placeholder='Start'
      min={props.earliest}
      max={props.latest}
      onValueChange={(value) => setTime('start', value)}
      onClear={() => setTime('start', null)} />
  );
}

function End(props) {
  const filters = useFilters();
  return (
    <Picker
      value={filters?.time?.end}
      placeholder='End'
      min={dayjs.isDayjs(filters?.time?.start) && filters.time.start.isValid() && (!props.earliest || filters.time.start.isAfter(props.earliest)) ? filters.time.start : props.earliest}
      max={props.latest}
      onValueChange={(value) => setTime('end', value)}
      onClear={() => setTime('end', null)} />
  );
}

export default function Time(props) {
  const created = useCreated();
  const started = useStarted();
  const highlights = useUnfilteredHighlights();
  let earliest;
  let latest;
  for (const highlight of (Array.isArray(highlights) ? highlights : [])) {
    if (typeof highlight?.['timestamp_start'] === 'number' && (!earliest || highlight['timestamp_start'] < earliest)) {
      earliest = highlight['timestamp_start'];
    }
    if (typeof highlight?.['timestamp_end'] === 'number' && (!earliest || highlight['timestamp_end'] < earliest)) {
      earliest = highlight['timestamp_end'];
    }
    if (typeof highlight?.['timestamp_start'] === 'number' && (!latest || highlight['timestamp_start'] > latest)) {
      latest = highlight['timestamp_start'];
    }
    if (typeof highlight?.['timestamp_end'] === 'number' && (!latest || highlight['timestamp_end'] > latest)) {
      latest = highlight['timestamp_end'];
    }
    if (created < earliest || started < earliest) {
      earliest = Math.min(created || Number.MAX_SAFE_INTEGER, started || Number.MAX_SAFE_INTEGER);
    }
  }
  return (
    <div className={`flex w-full flex-row justify-center items-center ${props.className || ''}`.trim()}>
      <div className='flex flex-row flex-1 gap-2 justify-center items-start overflow-hidden'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Start
            earliest={earliest ? dayjs.unix(earliest) : undefined}
            latest={latest ? dayjs.unix(latest) : undefined} />
          <End
            earliest={earliest ? dayjs.unix(earliest) : undefined}
            latest={latest ? dayjs.unix(latest) : undefined} />
        </LocalizationProvider>
      </div>
      <IconButton
        title='Remove filter'
        icon={<XIcon className='!h-4' />}
        onClick={() => {
          setTime('start', null);
          setTime('end', null);
          props.onClose();
        }}
        className='-mr-2' />
    </div>
  );
}