/* ------ Module imports ------ */
import { useContext, useEffect, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Teams from './teams.view';

function TeamsContainer() {
  const { account } = useContext(PlatformContext);

  const [teams, setTeams] = useState(null);
  const [view, setView] = useState('loading');

  async function fetchTeams() {
    let loadedTeams = null;
    try {
      const { data } = (await api.get(`/team?account=${account.id}&expand=sport`)).data;
      loadedTeams = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedTeams) {
      setTeams(loadedTeams);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchTeams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Teams
      teams={teams}
      view={view}
    />
  );
}

export default TeamsContainer;
