/* ------ Module imports ------ */
import { useState } from 'react';

/* ------ Local components ------ */
import Accounts from './accounts';
import Details from './details';

function Profile() {
  const [tab, setTab] = useState('details');

  return (
    <div>
      <div className="px-12 pt-10 border-b border-slate-200">
        <h1 className="font-black text-3xl text-slate-900">Profile</h1>

        <div className="mt-10 -mb-px flex">
          <button
            className={`px-4 py-3 border-b rounded-t text-sm font-medium ${tab === 'details' ? 'text-blue-600 bg-blue-100 border-blue-200' : 'text-slate-600 bg-slate-100 border-slate-200'}`}
            onClick={() => setTab('details')}
            type="button"
          >
            Details
          </button>
          <button
            className={`ml-2 px-4 py-3 border-b rounded-t text-sm font-medium ${tab === 'accounts' ? 'text-blue-600 bg-blue-100 border-blue-200' : 'text-slate-600 bg-slate-100 border-slate-200'}`}
            onClick={() => setTab('accounts')}
            type="button"
          >
            Accounts
          </button>
        </div>
      </div>

      {tab === 'details' && <Details />}
      {tab === 'accounts' && <Accounts />}
    </div>
  );
}

export default Profile;
