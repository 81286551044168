/* ------ Module imports ------ */
import { Link } from 'react-router-dom';
import { PlusCircleIcon, ChevronRightIcon } from '@heroicons/react/solid';

function Devices(props) {
  const {
    devices,
    view,
  } = props;

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <div className="px-12 py-10 border-b border-slate-200">
        <div className="flex items-center justify-between">
          <h1 className="font-black text-3xl text-slate-900">Devices</h1>
          <div>
            <Link
              className="bg-blue-600 text-white flex items-center rounded px-2 py-2"
              to="/device/new"
            >
              <PlusCircleIcon className="h-4 text-white" />
              <span className="text-sm font-medium ml-2">New device</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="px-12 pt-6 pb-10">
        <div className="border border-slate-200 rounded overflow-hidden">
          <div className="border-b border-slate-200 flex pl-4 pr-2 py-2">
            <p className="text-sm text-slate-600 flex-1">Name</p>
            <p className="text-sm text-slate-600 w-48 mr-4">Type</p>
          </div>

          {devices.map(device => (
            <Link
              className="text-left pl-4 pr-2 py-2 w-full even:bg-slate-100 flex justify-start items-center transition-opacity opacity-100 hover:opacity-75"
              key={device.id}
              to={`/device/${device.id}`}
            >
              <p className="truncate text-sm text-slate-900 flex-1">{device.name}</p>
              <p className="text-sm text-slate-600 w-48">{device.type.toUpperCase()}</p>
              <ChevronRightIcon className="w-4" />
            </Link>
          ))}
          {devices.length === 0 && (
            <div className="px-4 py-2">
              <p className="text-sm text-slate-900">No devices added yet</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Devices;
