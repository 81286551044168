/* ------ Module imports ------ */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/* ------ Utils ------ */
import initGameManager, { getGame } from './utils/game-manager';
import { get as getHighlights } from './utils/highlights-manager';
import { create, destroy } from './utils/ffmpeg';

/* ------ View import ------ */
import Game from './game.view';
import DownloadGameDialog from './dialogs/download-game/download-game-dialog';
import ShareGameDialog from './dialogs/share-dialog';
import RenameMatchTitleDialog from './dialogs/rename-match-title-dialog';
import ChangeVideoVisibilityDialog from './dialogs/change-video-visibility-dialog';
import Spinner from 'components/spinner/spinner';

/* ------ View wide drag and drop ------ */
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

export const ItemTypes = {
  HIGHLIGHT_ITEM: 'highlight-item',
  WORKSPACE_ITEM: 'workspace-item',
};

export default function GameContainer() {
  const { id } = useParams();
  const [view, setView] = useState('loading');
  useEffect(() => {
    (async () => {
      setView('loading');
      await initGameManager(id);
      if (getGame() && getHighlights()) {
        setView('main');
      } else {
        setView('error');
      }
    })();
  }, [id]);
  useEffect(() => {
    (async () => { destroy(await create()); })(); //Try and trigger the ffmpeg loading process as early as possible
  }, []);
  if (view === 'loading' || view === 'error') {
    return (
      <div className="px-12 py-10 flex flex-wrap justify-center items-center max-w-[1920px] min-h-[calc(100vh-50px)]">
        {
          view === 'loading' &&
          <Spinner className='text-blue-500' />
        }
        {
          view === 'error' &&
          <div className='flex flex-col w-full justify-center items-center text-center max-w-[500px]'>
            <ExclamationCircleIcon className='h-20' />
            <h1 className='text-lg mt-8'>Something went wrong loading this video</h1>
            <p className='mt-2'>Please check you have sufficient access from the account owner and try again or reach out to our support for&nbsp;more&nbsp;assistance.</p>
          </div>
        }
      </div>
    );
  }
  else {
    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <Game view={view} />
        </DndProvider>
        <DownloadGameDialog />
        <ShareGameDialog />
        <RenameMatchTitleDialog />
        <ChangeVideoVisibilityDialog />
      </>
    );
  }
}