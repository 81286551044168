import Select from 'react-select'

import { XIcon, TrashIcon, ChevronDownIcon } from '@heroicons/react/outline';

import { useHighlights as useUnfilteredHighlights } from '../../utils/highlights-manager';
import { get, set, useFilters } from '../../utils/highlights-filter-manager';
import { IconButton } from '../../accessory-container';

function toOption(value, includeCount = true) {
  const count = includeCount ? get()?.flatMap(h => h?.tags).filter(t => (t?.length || 0) > 0).reduce((a, v) => v === value ? a + 1 : a, 0) : -1;
  return {
    value: value,
    label: `${`${value[0].toUpperCase()}${value.length > 1 ? value.slice(1) : ''}`.replace(/[^0-9a-z]/gi, ' ')}${count > 1 ? ` (x${count})` : ''}`
  };
}

export default function Type(props) {
  const filters = useFilters().type;
  const highlights = useUnfilteredHighlights();
  const types = new Array(...new Set(highlights?.map(h => h?.type).filter(t => (t?.length || 0) > 0)));
  return (
    <div className={`flex w-full flex-row justify-center items-center ${props.className || ''}`.trim()}>
      <Select
        isDisabled={(types?.length || 0) === 0}
        isClearable={true}
        placeholder='Highlight types...'
        isMulti={true}
        value={filters?.map((v) => toOption(v, false))}
        components={{
          ClearIndicator: ({ innerRef, innerProps }) => <button ref={innerRef} {...innerProps} className='px-2'><TrashIcon className='h-4 text-slate-400' /></button>,
          DropdownIndicator: ({ innerRef, innerProps }) => <button ref={innerRef} {...innerProps} className='px-2'><ChevronDownIcon className='h-4 text-slate-400' /></button>,
        }}
        styles={{
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            maxHeight: 70,
            overflowY: 'auto',
          })
        }}
        options={types.map((v) => toOption(v, true))}
        onChange={(values, { action }) => {
          values = values?.map(v => v?.value)?.filter(v => (v?.length || 0) > 0);
          if ((values.length || 0) === 0 || action === 'clear') {
            set('type', null);
          }
          else {
            set('type', values);
          }
        }}
        className='flex-1 font-normal text-xs' />
      <IconButton
        title='Remove filter'
        icon={<XIcon className='!h-4' />}
        onClick={() => {
          set('type', null);
          props.onClose();
        }}
        className='ml-1 -mr-2' />
    </div>
  );
}