/* ------ Module imports ------ */
import { PhotographIcon } from '@heroicons/react/outline';

function Image(props) {
  const {
    disabled,
    image,
    onUpload,
  } = props;

  return (
    <button
      className="w-20 h-20 flex items-center justify-center rounded-lg bg-gray-100 border border-dashed border-slate-400 bg-contain bg-center bg-no-repeat hover:border-blue-500"
      disabled={disabled}
      style={{ backgroundImage: image ? `url(${image})` : undefined }}
      onClick={onUpload}
    >
      {!image && <PhotographIcon className="w-6 text-slate-500" />}
    </button>
  );
}

export default Image;
