import { useState } from 'react';

import { ViewGridAddIcon, FolderAddIcon } from '@heroicons/react/outline';

import AccessoryContainer, { Title, IconButton } from '../accessory-container';
import Highlight from './highlight';

import { add } from '../utils/workspace-manager';
import { useHighlights as useAllHighlights } from '../utils/highlights-manager';
import { useHighlights as useFilteredHighlights, clear } from '../utils/highlights-filter-manager';
import Spinner from 'components/spinner/spinner';

import Filters from './filters';
import { useVideo } from '../game.view';
import useResizeObserver from 'utils/use-resize-observer';

export default function Highlights(props) {
  const { height } = useResizeObserver(useVideo());
  const [workspace, setWorkspace] = useState(false);
  const [collection, setCollection] = useState(false);
  const highlights = useAllHighlights();
  const filtered = useFilteredHighlights();
  const massActionsDisabled = (highlights?.length || 0) === 0;
  return (
    <AccessoryContainer
      title={
        <div className='flex w-full flex-col justify-start items-start'>
          <Title title='Match Highlights'>
            <IconButton
              tip='Copy all highlights in selection to Workspace'
              title='Copy to workspace'
              loading={workspace}
              disabled={massActionsDisabled}
              icon={<ViewGridAddIcon />}
              onClick={async () => {
                setWorkspace(true);
                for (const highlight of highlights) {
                  await add(highlight);
                }
                setWorkspace(false);
              }} />
            <IconButton
              tip='Copy all highlights in selection to a Collection'
              title='Copy to collection'
              loading={collection}
              disabled={massActionsDisabled || true}
              icon={<FolderAddIcon />}
              onClick={async () => {
                //TODO
              }}
              className='-ml-1 -mr-2' />
          </Title>
          <Filters />
        </div>
      }
      {...props}
      style={{
        minHeight: height
      }}>
      {
        !Array.isArray(highlights) &&
        <div className='w-full flex justify-center items-center flex-1'>
          <Spinner className='text-blue-500' />
        </div>
      }
      {
        (filtered?.length || 0) > 0 &&
        filtered.map(highlight => (
          <Highlight
            id={highlight.id}
            highlight={highlight}
            key={highlight.id} />
        ))
      }
      {
        (filtered?.length || 0) === 0 && (highlights?.length || 0) > 0 &&
        <div className='flex flex-col flex-1 w-full justify-center items-center gap-4 font-normal text-sm text-slate-700 text-center px-2 pb-4'>
          {'No highlights available, try clearing filters.'}
          <button
            onClick={clear}
            className='text-blue-600 hover:text-blue-500'>
            Clear filters
          </button>
        </div>
      }
      {
        (filtered?.length || 0) === 0 && (highlights?.length || 0) === 0 &&
        <p className='inline-flex justify-center items-center flex-1 text-slate-700 text-sm px-2 pb-4 w-full text-center'>There are no highlights for this game.</p>
      }
    </AccessoryContainer>
  );
}