import { getVideo } from '../game.view';

import initPlayFunctions, { setDuration, setTime } from './player-functions';

let COMM_LINK_ESTABLISHED = false;
let DISPATCH_QUEUE = [];

export default function init() {
  COMM_LINK_ESTABLISHED = false;
  initPlayFunctions();
  try { window.removeEventListener('message', onWindowMessage); } catch (err) { }
  try { window.addEventListener('message', onWindowMessage, false); } catch (err) { }
}

export function dispatch(request) {
  if (COMM_LINK_ESTABLISHED) {
    try { getVideo().contentWindow.postMessage(request, '*'); } catch (err) { console.log(err) }
  }
  else {
    DISPATCH_QUEUE.push(request);
  }
}

function onCommLinkEstablished() {
  console.debug('[COMMUNICATOR READY]', DISPATCH_QUEUE.length + ' items in the queue.');
  COMM_LINK_ESTABLISHED = true;
  const video = getVideo();
  if (video?.contentWindow?.postMessage) {
    const onDispatchNextQueuedMessage = () => {
      if (DISPATCH_QUEUE.length > 0) {
        const request = DISPATCH_QUEUE.shift();
        if (request) {
          try { video.contentWindow.postMessage(request, '*'); } catch (err) { }
        }
        requestAnimationFrame(onDispatchNextQueuedMessage);
      }
      else {
        DISPATCH_QUEUE = [];
      }
    }
    onDispatchNextQueuedMessage();
  }
  else {
    DISPATCH_QUEUE = [];
  }
}

function onWindowMessage(message) {
  const client = getVideo();
  if (typeof message === 'string') {
    try { message = JSON.parse(message); } catch (err) { }
  }
  if (client) {
    let response = null;
    switch (message?.data?.type || '') {
      case 'ready':
        onCommLinkEstablished();
        break;
      case 'timeupdate':
        setTime(message.data.data.value);
        break;
      case 'durationchange':
        setDuration(message.data.data.value);
        break;
      default:
        break;
    }
    if (Object.keys(response || {}).length === 0) {
      response = {};
    }
    if (!response.data) {
      response.data = {
        success: false,
      };
    }
    if (typeof response.data.success !== 'boolean') {
      response.data.success = false;
    }
    response.type = message?.data?.type + '.result';
    try { client.contentWindow.postMessage(response, '*'); } catch (err) { }
  }
}