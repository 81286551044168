/* ------ Module imports ------ */
import { useState } from 'react';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Remove from './remove.view';

function RemoveContainer(props) {
  const {
    device,
    onCancel,
    onRemoved,
  } = props;

  const [submitting, setSubmitting] = useState(false);

  async function onRemove() {
    setSubmitting(true);

    let success = false;
    try {
      await api.remove(`/device/${device.id}`);
      success = true;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (success) {
      onRemoved();
    } else {
      // TODO - error
    }
  }

  return (
    <Remove
      onCancel={onCancel}
      onRemove={onRemove}
    />
  );
}

export default RemoveContainer;
