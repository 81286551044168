/* ------ Module imports ------ */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import User from './user.view';

function UserContainer() {
  const { id } = useParams();

  const [user, setUser] = useState(null);
  const [view, setView] = useState('loading');

  async function fetchUser() {
    setView('loading');

    let loadedUser = null;
    try {
      const { data } = (await api.get(`/user/${id}`)).data;
      loadedUser = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedUser) {
      setUser(loadedUser);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <User
      user={user}
      view={view}
    />
  );
}

export default UserContainer;
