import JSZip from 'jszip';

export default function zip(files) {
  files = (files || []).filter(f => (f?.blob?.size || 0) > 0);
  if (files.length > 0) {
    const archive = new JSZip();
    for (const file of files) {
      if ((file?.blob?.size || 0) > 0 && (file.filename?.length || 0) > 0) {
        archive.file(file.filename, file.blob, { binary: true });
      }
    }
    return archive.generateAsync({ type: 'blob' });
  }
  else {
    throw new Error('Invalid highlights provided, please check and try again.');
  }
}