/* ------ Components ------ */
import Input from 'components/input';
import Modal from 'components/modal';
import Spinner from 'components/spinner';

function ChangePassword(props) {
  const {
    confirmNewPassword,
    existingPassword,
    newPassword,
    onCancel,
    onConfirmNewPasswordChanged,
    onExistingPasswordChanged,
    onNewPasswordChanged,
    onSubmit,
    submitting,
  } = props;

  return (
    <Modal onClose={onCancel}>
      <div className="px-6 py-4">
        <h3 className="font-black text-slate-900 text-lg mb-2">Change password</h3>
        <p className="text-sm text-slate-900">Enter a new password below. Passwords must be at least 8 characters long.</p>
      </div>

      <form
        disabled={submitting}
        onSubmit={onSubmit}
      >
        <div className="border-t border-slate-200 px-6 py-4">
          <Input
            disabled={submitting}
            label="Current password"
            onChange={onExistingPasswordChanged}
            type="password"
            autoComplete='current-password'
            value={existingPassword}
          />
          <Input
            className="mt-6"
            disabled={submitting}
            label="New password"
            onChange={onNewPasswordChanged}
            type="password"
            autoComplete='new-password'
            value={newPassword}
          />
          <Input
            className="mt-6"
            disabled={submitting}
            label="Re-enter new password"
            onChange={onConfirmNewPasswordChanged}
            type="password"
            autoComplete='new-password'
            value={confirmNewPassword}
          />
        </div>

        <div className="border-t border-slate-200 px-6 py-4 flex justify-end">
          <button
            className="bg-slate-200 text-slate-700 flex items-center rounded px-3 py-2 text-sm font-medium"
            disabled={submitting}
            onClick={onCancel}
            type="button"
          >
            Cancel
          </button>
          <button
            className="ml-2 bg-blue-600 text-white flex items-center rounded px-3 py-2 text-sm font-medium relative"
            disabled={submitting}
            type="submit"
          >
            <span className={submitting ? 'invisible' : ''}>Continue</span>
            {submitting && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <Spinner />
              </div>
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ChangePassword;
