import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MaterialIconButton from '@mui/material/IconButton';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import dayjs from 'dayjs';

import { setEndTime, setStartTime, useEndTime, useStartTime } from 'views/game/utils/game-file-downloader';
import { useGame } from 'views/game/utils/game-manager';

function Picker(props) {
  return (
    <div className='flex flex-col w-full justify-start items-start mb-2'>
      <MobileDateTimePicker
        value={props.value}
        label={props.label}
        minDateTime={props.min}
        maxDateTime={props.max}
        onAccept={(v) => props.onValueChange(dayjs.isDayjs(v) ? v : dayjs(v))}
        format='MMM Do, h:mm a'
        slotProps={{
          textField: {
            size: 'small',
            InputProps: {
              placeholder: props.placeholder || 'Time',
              endAdornment: !!props.onClear && dayjs.isDayjs(props.value) && (
                <MaterialIconButton
                  size='small'
                  edge='end'
                  onClick={(e) => {
                    try { e?.preventDefault(); } catch (err) { }
                    try { e?.stopPropagation(); } catch (err) { }
                    props.onClear();
                  }}>
                  <HighlightOffIcon fontSize='inherit' className='text-slate-400 hover:text-slate-500' />
                </MaterialIconButton>
              ),
              className: 'w-full'
            },
            className: 'w-full'
          }
        }}
        className='w-full' />
      <span className='text-xs text-slate-400 mt-1 leading-tight px-2'>{props.hint}</span>
    </div>
  );
}

function Start(props) {
  const start = useStartTime();
  return (
    <Picker
      label='Start At'
      value={start}
      placeholder='Start video from...'
      hint='Leave empty to select from the beginning of the video.'
      min={props.earliest}
      max={props.latest}
      onValueChange={(value) => setStartTime(value)}
      onClear={() => setStartTime(null)} />
  );
}

function End(props) {
  const start = useStartTime();
  const end = useEndTime();
  return (
    <Picker
      label='End At'
      value={end}
      placeholder='End video at...'
      hint='Leave empty to select through to the end of the video.'
      min={dayjs.isDayjs(start) && start.isValid() && (!props.earliest || start.isAfter(props.earliest)) ? start : props.earliest}
      max={props.latest}
      onValueChange={(value) => setEndTime(value)}
      onClear={() => setEndTime(null)} />
  );
}

export default function Time(props) {
  const game = useGame();
  const earliest = game?.created || game?.['stream_started'] || dayjs.unix();
  const latest = game?.duration ? earliest + game.duration : dayjs.unix();
  return (
    <div className={`flex flex-row w-full justify-start items-start gap-4 ${props.className || ''}`.trim()}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Start
          earliest={earliest ? dayjs.unix(earliest) : undefined}
          latest={latest ? dayjs.unix(latest) : undefined} />
        <End
          earliest={earliest ? dayjs.unix(earliest) : undefined}
          latest={latest ? dayjs.unix(latest) : undefined} />
      </LocalizationProvider>
    </div>
  );
}