function Accounts(props) {
  const {
    account,
    accounts,
    onSwitch,
    submitting,
    view,
  } = props;

  function renderAccount(acc) {
    if (!acc) {
      return null;
    }

    return (
      <div
        className="mt-2 border border-slate-200 rounded-lg flex items-center justify-between px-4 py-2"
        key={acc.id}
      >
        <div className="flex items-center">
          <div
            className="inline-block rounded-full w-8 h-8 bg-slate-300 bg-cover bg-center"
            style={{ backgroundImage: account.profile_image ? `url(${account.profile_image})` : null }}
          >
            {!account.profile_image && (
              <div className="w-full h-full flex items-center justify-center">
                <i className="mar-icon-users text-gray-500 text-lg" />
              </div>
            )}
          </div>
          <p className="ml-4 text-slate-900">{acc.name}</p>
        </div>

        {acc.id === account.id && <span className="font-semibold text-green-500 text-sm">Active</span>}
        {acc.id !== account.id && (
          <button
            className="font-medium bg-blue-100 hover:bg-blue-200 text-blue-600 text-sm rounded px-2 py-1 -mr-2"
            disabled={submitting}
            onClick={() => onSwitch(acc)}
            type="button"
          >
            Switch to this account
          </button>
        )}
      </div>
    );
  }

  if (view === 'error' || view === 'loading') {
    return null; // TODO
  }

  return (
    <div className="px-12 py-10 border-b border-slate-200">
      <h2 className="font-black text-xl text-slate-900">Your accounts</h2>

      {accounts.length === 0 && (
        <>
          <p className="mt-6 text-sm text-slate-900 font-semibold">You don't have any accounts on My Action Sport</p>
          <p className="text-sm text-slate-900">Please contact support if you would like to start an account, or if you think this is an error</p>
        </>
      )}

      {renderAccount(account)}
      {accounts.filter(acc => acc.id !== account.id).map(renderAccount)}
    </div>
  );
}

export default Accounts;
