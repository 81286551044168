/* ------ Module imports ------ */
import { useState } from 'react';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Recode from './recode.view';

function RecodeContainer(props) {
  const {
    onClose,
    onRecoded,
    team,
  } = props;

  const [code, setCode] = useState(team.code);
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    setSubmitting(true);

    let updatedTeam = null;
    try {
      const { data } = (await api.patch(`/team/${team.id}`, { code })).data;
      updatedTeam = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (updatedTeam) {
      onRecoded(updatedTeam.code);
    } else {
      // TODO - error
    }
  }

  function onCodeChanged(newCode) {
    setCode(newCode.trim().toUpperCase().slice(0, 3));
  }

  return (
    <Recode
      code={code}
      onCancel={onClose}
      onCodeChanged={onCodeChanged}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default RecodeContainer;
