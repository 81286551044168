/* ------ Module imports ------ */
import { Link } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/solid';

/* ------ Local components ------ */
import InviteModal from './modals/invite';
import RemoveModal from './modals/remove';

function Users(props) {
  const {
    invites,
    inviteToDelete,
    modal,
    onCloseModal,
    onInvite,
    onInviteAdded,
    onInviteRemoved,
    onRemove,
    users,
    view,
  } = props;

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <div>
      <div className="px-12 py-10 border-b border-slate-200">
        <div className="flex items-center justify-between">
          <h1 className="font-black text-3xl text-slate-900">Users</h1>
          <button
            className="bg-blue-600 text-white flex items-center rounded px-2 py-2"
            onClick={onInvite}
          >
            <PlusCircleIcon className="h-4 text-white" />
            <span className="text-sm font-medium ml-2">Invite user</span>
          </button>
        </div>
      </div>

      <div className="px-12 py-10">
        <h2 className="font-black text-xl text-slate-900 mb-6">Registered users</h2>

        <div className="border border-slate-200 rounded overflow-hidden">
          <div className="border-b border-slate-200 px-4 py-2">
            <p className="text-sm text-slate-600 w-full">Name</p>
          </div>

          {users.map(user => (
            <Link
              className="block text-left px-4 py-2 w-full even:bg-slate-100"
              key={user.id}
              to={`/user/${user.id}`}
            >
              <p className="truncate text-sm text-slate-900 w-full">{user.name}</p>
            </Link>
          ))}
        </div>
      </div>

      {invites.length > 0 && (
        <div className="px-12 py-10 border-t border-slate-200">
          <h2 className="font-black text-xl text-slate-900 mb-6">Invited users</h2>

          <div className="border border-slate-200 rounded overflow-hidden">
            <div className="border-b border-slate-200 px-4 py-2">
              <p className="text-sm text-slate-600 w-full">Email</p>
            </div>

            {invites.map(invite => (
              <div
                className="text-left px-4 py-2 flex items-center even:bg-slate-100"
                key={invite.id}
              >
                <p className="truncate text-sm text-slate-900 w-full">{invite.email}</p>

                <button
                  className="ml-2 text-xs font-medium px-1 rounded shadow hover:shadow-md bg-white border border-slate-200"
                  onClick={() => onRemove(invite)}
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {modal === 'invite' && (
        <InviteModal
          onClose={onCloseModal}
          onInviteAdded={onInviteAdded}
        />
      )}
      {modal === 'remove' && (
        <RemoveModal
          invite={inviteToDelete}
          onClose={onCloseModal}
          onRemoved={onInviteRemoved}
        />
      )}
    </div>
  );
}

export default Users;
