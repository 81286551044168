import { useEffect, useState } from 'react';

import equal from 'fast-deep-equal';

import dayjs from 'dayjs';
import { Parser } from 'm3u8-parser';

import { create as createFFmpeg, destroy as destroyFFmpeg } from './ffmpeg';
import { fetchFile } from '@ffmpeg/ffmpeg';

import pLimit from 'p-limit';
import storage from 'utils/storage';
import { getGame, getGameId } from './game-manager';

const RATE_LIMITER = pLimit(20);

const settings = {
  video: 'libx264',
  audio: 'aac',
  start: undefined,
  end: undefined,
};

let storageKey;
let downloadProgress;
let conversionProgress;

let interrupt = false;
let ffmpeg;

let sourceBlob;
let convertedBlob;

let settingsListeners = [];
let progressListeners = [];
let blobListeners = [];

export function useSettings() {
  const [values, setValues] = useState(getSettings());
  useEffect(() => {
    const onUpdate = () => {
      const newValues = getSettings();
      if (!equal(newValues, values)) {
        setValues(newValues);
      }
    };
    return registerSettingsListener(onUpdate);
  }, [values]);
  return values;
}

export function useSetting(key = '') {
  const values = useSettings();
  const [value, setValue] = useState(values?.[key]);
  useEffect(() => {
    if (value !== values[key]) {
      setValue(values?.[key]);
    }
  }, [key, value, values]);
  return value;
}

export function useVideoCodec() {
  return useSetting('video');
}

export function useAudioCodec() {
  return useSetting('audio');
}

export function useStartTime() {
  return useSetting('start');
}

export function useEndTime() {
  return useSetting('end');
}

export function useProgress() {
  const [values, setValues] = useState(getProgress());
  useEffect(() => {
    const onUpdate = () => {
      const newValues = getProgress();
      if (!equal(newValues, values)) {
        setValues(newValues);
      }
    };
    return registerProgressListener(onUpdate);
  }, [values]);
  return values;
}

export function useProgressValue(key = '') {
  const values = useProgress();
  const [value, setValue] = useState(values?.[key]);
  useEffect(() => {
    if (value !== values[key]) {
      setValue(values?.[key]);
    }
  }, [key, value, values]);
  return value;
}

export function useDownloadProgress() {
  return useProgressValue('download');
}

export function useConversionProgress() {
  return useProgressValue('conversion');
}

export function useBlobs() {
  const [blobs, setBlobs] = useState({
    source: sourceBlob,
    converted: convertedBlob,
  });
  useEffect(() => {
    const onUpdate = () => {
      if (blobs.sourceBlob !== sourceBlob || blobs.converted !== convertedBlob) {
        setBlobs({
          source: sourceBlob,
          converted: convertedBlob,
        });
      }
    };
    return registerSettingsListener(onUpdate);
  }, [blobs]);
  return blobs;
}

export function useSourceBlob() {
  const blobs = useBlobs();
  const [blob, setBlob] = useState(blobs.source);
  useEffect(() => {
    if (blobs.source !== blob) {
      setBlob(blobs.source);
    }
  }, [blob, blobs]);
  return blob;
}

export function useConvertedBlob() {
  const blobs = useBlobs();
  const [blob, setBlob] = useState(blobs.converted);
  useEffect(() => {
    if (blobs.converted !== blob) {
      setBlob(blobs.converted);
    }
  }, [blob, blobs]);
  return blob;
}

export default async function init() {
  storageKey = `game_file_${getGameId()}`;
  sourceBlob = await storage.get(storageKey);
  convertedBlob = null;
  downloadProgress = undefined;
  conversionProgress = undefined;
  settings.start = undefined;
  settings.end = undefined;
  notifySettingsListeners();
  notifyProgressListeners();
  notifyBlobListeners();
}

export function getSourceBlob() {
  return sourceBlob;
}

export function getConvertedBlob() {
  return convertedBlob;
}

export function getSettings() {
  try {
    return structuredClone(settings);
  } catch (err) {
    return JSON.parse(JSON.stringify(settings));
  }
}

export function setSetting(key, value) {
  if (key in settings) {
    settings[key] = value;
    notifySettingsListeners();
  }
}

export function getVideoCodec() {
  return getSettings()?.video;
}

export function setVideoCodec(codec) {
  setSetting('video', ['libx264', 'libx265', 'libvpx', 'libvpx-vp9'].includes(codec?.toLowerCase()) ? codec.toLowerCase() : undefined);
  return getVideoCodec();
}

export function getAudioCodec() {
  return getSettings()?.audio;
}

export function setAudioCodec(codec) {
  setSetting('audio', ['aac', 'libmp3lame', 'libvorbis', 'libopus', 'wav'].includes(codec?.toLowerCase()) ? codec.toLowerCase() : undefined);
  return getAudioCodec();
}

export function getStartTime() {
  return getSettings()?.start;
}

export function setStartTime(time) {
  if (!dayjs.isDayjs(time) || !time.isValid()) {
    time = undefined;
  }
  settings.start = time;
  if (dayjs.isDayjs(settings.start) && settings.start.isValid() && dayjs.isDayjs(settings.end) && settings.end.isValid() && settings.start.isAfter(settings.end)) {
    const swap = settings.start;
    settings.start = settings.end;
    settings.end = swap;
  }
  notifySettingsListeners();
  return getStartTime();
}

export function getEndTime() {
  return getSettings()?.end;
}

export function setEndTime(time) {
  if (!dayjs.isDayjs(time) || !time.isValid()) {
    time = undefined;
  }
  settings.end = time;
  if (dayjs.isDayjs(settings.start) && settings.start.isValid() && dayjs.isDayjs(settings.end) && settings.end.isValid() && settings.start.isAfter(settings.end)) {
    const swap = settings.start;
    settings.start = settings.end;
    settings.end = swap;
  }
  notifySettingsListeners();
  return getEndTime();
}

export function getContainerExtension() {
  let extension = '';
  switch (settings.video || '') {
    case 'libx264':
    case 'libx265':
      extension = 'mp4';
      break;
    case 'libvpx':
    case 'libvpx-vp9':
      extension = 'webm';
      break;
    default:
      break;
  }
  if ((extension?.length || 0) === 0) {
    switch (settings.audio || '') {
      case 'aac':
        extension = 'aac';
        break;
      case 'libmp3lame':
        extension = 'mp3';
        break;
      case 'libvorbis':
      case 'libopus':
        extension = 'ogg';
        break;
      case 'wav':
        extension = 'wav';
        break;
      default:
        break;
    }
  }
  return extension;
}

export async function getManifest() {
  let m3u8 = (await (await fetch(`https://playlists.myactionsport.com/game/${getGameId()}.m3u8`)).text()).split('\n');
  for (let i = 0; i < m3u8.length; i++) {
    const line = m3u8[i];
    if (line.startsWith('http')) {
      m3u8[i] = line.substring(line.lastIndexOf('/') + 1);
    }
  }
  m3u8 = m3u8.join('\n');
  const parser = new Parser();
  parser.push(m3u8);
  parser.end();
  return { m3u8, manifest: parser.manifest };
}

export async function download() {
  convertedBlob = null;
  downloadProgress = !sourceBlob ? 0 : 1;
  conversionProgress = 0;
  notifyProgressListeners();
  notifyBlobListeners();
  try { await ensureSourceBlob(); } catch (err) { }
  if (!interrupt && sourceBlob && (settings.video !== 'libx264' || settings.audio !== 'aac' || settings.start || settings.end)) {
    try {
      await applyConversions();
    } catch (err) {
      convertedBlob = sourceBlob;
    }
  }
  else if (!interrupt) {
    convertedBlob = sourceBlob;
  }
  else {
    convertedBlob = null;
  }
  downloadProgress = undefined;
  conversionProgress = undefined;
  notifyProgressListeners();
  notifyBlobListeners();
  return convertedBlob;
}

async function ensureSourceBlob() {
  const game = getGame();
  if ((game?.id?.length || 0) > 0) {
    if (!sourceBlob) {
      sourceBlob = await storage.get(storageKey);
      if (!sourceBlob) {
        cancel();
        interrupt = false;
        const target = `${(game.title?.length || 0) > 0 ? encodeURIComponent(game.title) : dayjs().unix()}.mp4`;
        let promises = await Promise.allSettled([createFFmpeg(), getManifest()]);
        ffmpeg = promises[0].value;
        const { m3u8, manifest } = promises[1].value;
        const ts = manifest.segments.map(s => s?.uri || '');
        let completed = 0;
        promises = await Promise.allSettled(ts.map(t => RATE_LIMITER(async () => {
          if (!interrupt) {
            const segment = await fetchFile(`https://streams.ralli.co.nz/${game.id}/output/${t}`);
            downloadProgress = (++completed) / ts.length;
            notifyProgressListeners();
            return segment;
          }
          else {
            return null;
          }
        })));
        if (!interrupt) {
          for (let i = 0; i < ts.length; i++) {
            ffmpeg.FS('writeFile', ts[i], promises[i].value);
          }
          ffmpeg.FS('writeFile', `${game.id}.m3u8`, m3u8);
          await ffmpeg.run(
            '-y',
            '-hide_banner',
            '-i', `${game.id}.m3u8`,
            '-c:v', 'copy',
            '-c:a', 'copy',
            target
          );
          const data = ffmpeg.FS('readFile', target);
          destroyFFmpeg(ffmpeg);
          sourceBlob = new Blob([data.buffer], { type: 'video/mp4' });
          if (game.status === 'ended') {
            try { await storage.set(storageKey, sourceBlob, 7, 'days'); } catch (err) { }
          }
        }
      }
    }
    return sourceBlob;
  }
  else {
    throw new Error('Invalid game provided, please check and try again.');
  }
}

async function applyConversions() {
  const game = getGame();
  convertedBlob = null;
  downloadProgress = 1;
  conversionProgress = 0;
  notifyBlobListeners();
  notifyProgressListeners();
  cancel();
  interrupt = false;
  ffmpeg = await createFFmpeg({ log: true });
  ffmpeg.FS('writeFile', 'source.mp4', new Uint8Array(await sourceBlob.arrayBuffer()));
  ffmpeg.setProgress(({ ratio }) => {
    conversionProgress = ratio;
    notifyProgressListeners();
  });
  const extension = getContainerExtension();
  const target = `output.${extension}`;
  const commands = [
    '-y',
    '-hide_banner',
    '-i', 'source.mp4',
  ];
  switch (settings.video || '') {
    case 'libvpx':
      commands.push('-c:v', 'libvpx', '-g', '90', '-quality', 'realtime', '-qmin', '4', '-qmax', '48', '-b:v', '4500k');
      break;
    case 'libvpx-vp9':
      commands.push('-c:v', 'libvpx-vp9', '-g', '90', '-quality', 'realtime', '-speed', '8', '-qmin', '4', '-qmax', '48', '-row-mt', '1', '-b:v', '4500k');
      break;
    case 'libx265':
      commands.push('-c:v', 'libx265', '-vtag', 'hvc1', '-b:v', '4500k');
      break;
    case 'none':
    case '':
      commands.push('-vn');
      break;
    case 'libx264':
    default:
      commands.push('-c:v', 'copy');
      break;
  }
  if ((settings.audio?.length || 0) > 0 && settings.audio !== 'none') {
    commands.push('-c:a', settings.audio === 'aac' ? 'copy' : settings.audio);
  }
  else {
    commands.push('-an');
  }
  const start = dayjs(game?.['stream_started'] || game?.created || dayjs.unix());
  const end = dayjs(game?.duration ? start + game.duration : dayjs.unix());
  if (dayjs.isDayjs(settings.start) && settings.start.isValid() && settings.start.isAfter(start) && settings.start.isBefore(end)) {
    commands.push('-ss', dayjs.duration(settings.start.unix() - start.unix()).format('HH:mm:ss'));
  }
  if (dayjs.isDayjs(settings.end) && settings.end.isValid() && settings.end.isAfter(end) && settings.end.isBefore(end)) {
    commands.push('-to', dayjs.duration(end.unix() - settings.end.unix()).format('HH:mm:ss'));
  }
  commands.push(target);
  await ffmpeg.run(...commands);
  const data = ffmpeg.FS('readFile', target);
  destroyFFmpeg(ffmpeg);
  convertedBlob = new Blob([data.buffer], { type: `video/${extension}` });
  notifyBlobListeners();
}

export function cancel() {
  interrupt = true;
  try { destroyFFmpeg(ffmpeg); } catch (err) { }
  ffmpeg = null;
  convertedBlob = null;
}

export function getProgress() {
  return {
    conversion: conversionProgress,
    download: downloadProgress,
  };
}

export function registerSettingsListener(listener) {
  if (listener) {
    settingsListeners.push(listener);
    requestAnimationFrame(() => { try { listener(getSettings()); } catch (err) { } });
  }
  return () => deregisterSettingsListener(listener);
}

export function deregisterSettingsListener(listener) {
  settingsListeners = settingsListeners.filter(l => l !== listener);
}

function notifySettingsListeners() {
  for (const listener of settingsListeners) {
    requestAnimationFrame(() => { try { listener(getSettings()); } catch (err) { } });
  }
}

export function registerProgressListener(listener) {
  if (listener) {
    progressListeners.push(listener);
    requestAnimationFrame(() => { try { listener(getProgress()); } catch (err) { } });
  }
  return () => deregisterProgressListener(listener);
}

export function deregisterProgressListener(listener) {
  progressListeners = progressListeners.filter(l => l !== listener);
}

function notifyProgressListeners() {
  for (const listener of progressListeners) {
    requestAnimationFrame(() => { try { listener(getProgress()); } catch (err) { } });
  }
}

export function registerBlobListener(listener) {
  if (listener) {
    blobListeners.push(listener);
    requestAnimationFrame(() => { try { listener(getProgress()); } catch (err) { } });
  }
  return () => deregisterProgressListener(listener);
}

export function deregisterBlobListener(listener) {
  blobListeners = blobListeners.filter(l => l !== listener);
}

function notifyBlobListeners() {
  for (const listener of blobListeners) {
    requestAnimationFrame(() => { try { listener(getProgress()); } catch (err) { } });
  }
}