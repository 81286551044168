/* ------ Module imports ------ */
import { useContext, useEffect, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Accounts from './accounts.view';

function AccountsContainer() {
  const { account } = useContext(PlatformContext);

  const [view, setView] = useState('loading');
  const [accounts, setAccounts] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  async function fetchAccounts() {
    let loadedAccounts = null;
    try {
      const { data } = (await api.get('/account')).data;
      loadedAccounts = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedAccounts) {
      setAccounts(loadedAccounts);
      setView('main');
    } else {
      setView('error');;
    }
  }

  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSwitch(acc) {
    setSubmitting(true);

    let updatedUser = null;
    try {
      const { data } = (await api.patch('/user/me', { last_used_account: acc.id })).data;
      updatedUser = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (updatedUser) {
      window.location = '/';
    } else {
      // TODO - error
    }
  }

  return (
    <Accounts
      account={account}
      accounts={accounts}
      onSwitch={onSwitch}
      submitting={submitting}
      view={view}
    />
  );
}

export default AccountsContainer;
