/* ------ Module imports ------ */
import React, { useEffect, useState } from 'react';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import TemplateSelector from './template-selector.view';

function TemplateSelectorContainer(props) {
  const {
    disabled,
    onTemplateSelected,
    sport,
  } = props;

  const [view, setView] = useState('hidden');
  const [templates, setTemplates] = useState(null);
  const [template, setTemplate] = useState(null);

  function onSelected(templateId) {
    let tmp = null;
    templates.forEach(t => {
      if (t.id === templateId) {
        tmp = t;
      }
    });

    if (tmp) {
      setTemplate(tmp.id);
      onTemplateSelected(tmp);
    } else {
      // TODO - error or something
    }
  }

  async function fetchTemplates() {
    if (!sport) {
      setView('hidden');
      return;
    }

    setView('loading');

    let loadedTemplates = null;
    try {
      const { data } = (await api.get(`/template?sport=${sport}`)).data;
      loadedTemplates = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedTemplates) {
      setTemplates(loadedTemplates);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    setTemplate(null);
    fetchTemplates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport]);

  return (
    <TemplateSelector
      disabled={disabled}
      onSelected={onSelected}
      template={template ? template.id : ''}
      templates={templates}
      view={view}
    />
  );
}

export default TemplateSelectorContainer;
