/* ------ Module imports ------ */
import { initializeApp } from 'firebase/app';
import {
  EmailAuthProvider,
  getAuth,
  getIdToken,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  sendPasswordResetEmail,
} from 'firebase/auth';

/* ------ Config ------ */
import config from 'config';

const firebaseApp = initializeApp(config.firebase);
const auth = getAuth(firebaseApp);

async function init() {
  return new Promise(resolve => {
    onAuthStateChanged(auth, () => resolve());
  });
}

async function login(email, password) {
  await signInWithEmailAndPassword(auth, email, password);
}

async function logout() {
  await signOut(auth);
}

async function getToken() {
  if (!auth.currentUser) {
    throw new Error();
  }

  const token = await getIdToken(auth.currentUser, false);
  return token;
}

async function verifyPassword(password) {
  if (!auth.currentUser) {
    throw new Error();
  }

  const credential = EmailAuthProvider.credential(auth.currentUser.email, password);

  await reauthenticateWithCredential(auth.currentUser, credential);
}

async function changePassword(password, newPassword) {
  if (!auth.currentUser) {
    throw new Error();
  }

  await verifyPassword(password);

  await updatePassword(auth.currentUser, newPassword);
}

function resetPassword(email) {
  return sendPasswordResetEmail(auth, email);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  init,
  getToken,
  login,
  logout,
  changePassword,
  resetPassword
};
