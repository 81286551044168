/* ------ Module imports ------ */
import { useEffect, useState } from 'react';

/* ------ Components ------ */
import Input from 'components/input';

function Text(props) {
  const {
    disabled,
    field,
    onUpdated,
  } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    onUpdated(value);
  }, [value]);

  return (
    <div className="mt-10 w-full max-w-sm">
      <p className="mb-1 text-slate-900">{field.name}</p>
      <Input
        disabled={disabled}
        onChange={setValue}
        type="text"
        value={value}
      />
    </div>
  );
}

export default Text;
