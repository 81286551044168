/* ------ Module imports ------ */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import AcceptInvite from './accept-invite.view';

function AcceptInviteContainer() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [view, setView] = useState('loading');

  async function fetchInvite() {
    let loadedInvite = null;
    try {
      const { data } = (await api.get(`/invite/${token}`, false)).data;
      loadedInvite = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedInvite) {
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchInvite();
  }, [token]);

  return (
    <AcceptInvite
      token={token}
      view={view}
    />
  );
}

export default AcceptInviteContainer;
