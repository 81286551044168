import { useEffect, useState } from 'react';

import { reorder, getSelected, registerListener, add } from '../utils/workspace-manager';
import Highlight from './highlight';
import View from './workspace.view';

import { useDrop } from 'react-dnd';
import { ItemTypes } from '../game.container';

export default function Container(props) {
  const [moving, setMoving] = useState(false);
  const [rows, setRows] = useState();
  const moveCard = (dragIndex, hoverIndex) => {
    setMoving(true);
    setRows((current) => {
      const copy = current.slice();
      copy.splice(dragIndex, 0, copy.splice(hoverIndex, 1)[0]);
      return copy;
    });
    setMoving(false);
  };
  const onSaveOrder = () => { reorder(rows.map(r => r.id)) };
  useEffect(() => {
    return registerListener(() => {
      setRows((getSelected() || []).slice());
    });
  }, []);
  const [{ highlightDragging }, drop] = useDrop({
    accept: ItemTypes.HIGHLIGHT_ITEM,
    collect: (monitor) => ({ highlightDragging: monitor.canDrop() }),
    drop: add,
  });
  let view;
  if (highlightDragging) {
    view = (
      <div className='flex justify-center items-center absolute inset-0 p-4 text-center bg-slate-100/75 border-2 border-dashed border-slate-400 text-slate-600'>
        Drop highlight here to add to workspace
      </div>
    );
  }
  else if ((rows?.length || 0) > 0 && !moving) {
    view = rows?.map((item, index) => (
      <Highlight
        {...item}
        key={index}
        index={index}
        highlight={item?.highlight}
        onReorder={moveCard}
        onDropped={onSaveOrder} />
    ));
  }
  return (
    <View ref={drop} {...props}>
      {view}
    </View>
  );
}