/* ------ Components ------ */
import Input from 'components/input';
import Spinner from 'components/spinner';
import {ExclamationCircleIcon,} from "@heroicons/react/solid";

function ResetPassword(props) {
  const{
    onSubmit,
    error,
    expired,
    submitting,
    setPassword,
    setConfirmPassword,
    showForm,
    showSuccess,
  } = props;

  return (
    <>
      <div className="flex px-6 border-b border-slate-200 justify-center">
        <h3 className="text-blue-500 font-semibold m-4"> {showSuccess ? "Congratulations!" : "Change password"} </h3>
      </div>
      <div className="p-6">
        {error && !expired &&
          <div className="text-center flex flex-row text-red-600">
            <ExclamationCircleIcon className="h-5 text-red-600 flex flex-shrink-0 pr-2" />
            {error}
          </div>
        }
        {
          expired && (
            <>
              <div className="text-center">
                This token has expired please try again
              </div>
              <a
              className="mt-6 w-full bg-blue-500 text-center hover:bg-blue-600 disabled:hover:bg-blue-500 rounded-lg text-white py-3 text-sm font-semibold relative"
              type="submit"
              href="/reset-password"
              >
                Retry password reset
              </a>
            </>
          )
        }
        {
          showForm && (
            <form
              onSubmit={onSubmit}
            >
              <Input
                className="mt-6"
                disabled={submitting}
                onChange={setPassword}
                label="Password"
                type="password"
                error={error}
              />
              <Input
                className="mt-6"
                disabled={submitting}
                onChange={setConfirmPassword}
                label="Confirm Password"
                type="password"
                error={error}
              />
              <button
                className="mt-4 w-full bg-blue-500 hover:bg-blue-600 disabled:hover:bg-blue-500 rounded-lg text-white py-3 text-sm font-semibold relative"
                disabled={submitting}
                type="submit"
              >
                <span className={submitting ? 'invisible' : ''}> Reset Password</span>
                {
                  submitting && (
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <Spinner/>
                  </div>
                )}
              </button>
            </form>
          )
        }
        {
          showSuccess && (
            <div className="flex text-center flex-col">
              <div>
                Your password has been reset
              </div>
              <a
                className="mt-6 w-full bg-blue-500 text-center hover:bg-blue-600 disabled:hover:bg-blue-500 rounded-lg text-white py-3 text-sm font-semibold relative"
                type="submit"
                href="/login"
              >
                Return to login
              </a>
            </div>
          )
        }
      </div>
    </>
  )
}

export default ResetPassword;
