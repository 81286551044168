/* ------ Module imports ------ */
import { XIcon } from '@heroicons/react/outline';

/* ------ Local components ------ */
import Image from './image';

function ImageList(props) {
  const {
    field,
    images,
    onAdd,
    onRemove,
    onUploaded,
  } = props;

  return (
    <div className="mt-10">
      <p className="text-slate-900">{field.name}</p>
      <p className="text-slate-600 text-xs">Please upload a jpgs or pngs, no larger than 1mb each</p>

      <div className="mt-4 flex">
        {images.map((image, i) => (
          <div
            className="mr-4 inline-block relative"
            key={i}
          >
            <Image
              image={image.path}
              onImageUploaded={img => onUploaded(img, i)}
            />

            <button
              className="bg-white absolute top-0 right-0 -mt-2 -mr-2 w-4 h-4 rounded-full border border-slate-300 flex items-center justify-center"
              onClick={() => onRemove(i)}
            >
              <XIcon className="w-3" />
            </button>
          </div>
        ))}
        <button
          className="w-20 h-20 flex items-center justify-center rounded-lg bg-gray-100 border border-dashed border-slate-400 bg-contain bg-center bg-no-repeat hover:border-blue-500 text-xs px-4"
          onClick={onAdd}
        >
          Add another
        </button>
      </div>
    </div>
  );
}

export default ImageList;
