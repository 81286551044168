import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';

dayjs.extend(durationPlugin);

export default function formatDuration(value) {
  const duration = dayjs.duration(Math.max(value || 0, 0), 'seconds');
  if (dayjs.isDuration(duration)) {
    const bigFormat = [];
    const smallFormat = [];
    if (duration.years() > 0) {
      bigFormat.push('Y[y]');
    }
    if (duration.months() > 0) {
      bigFormat.push('M[mo]');
    }
    if (duration.days() > 0) {
      bigFormat.push('D[d]');
    }
    if (duration.hours() > 0) {
      smallFormat.push('H');
    }
    if (duration.hours() > 0) {
      smallFormat.push('mm');
    }
    else {
      smallFormat.push('m');
    }
    smallFormat.push('ss');
    return duration.format((bigFormat.join(' ') + ' ' + smallFormat.join(':')).trim());
  }
  else {
    return '';
  }
}