/* ------ Module imports ------ */
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Invitation from './invitation.view';

function InvitationContainer() {
  const { token } = useParams();

  const { onAccountSelected } = useContext(PlatformContext);

  const navigate = useNavigate();

  const [invite, setInvite] = useState(null);
  const [view, setView] = useState('loading');
  const [submitting, setSubmitting] = useState(false);

  async function fetchInvite() {
    let loadedInvite = null;
    try {
      const { data } = (await api.get(`/invite/${token}?expand=account`)).data;
      loadedInvite = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedInvite) {
      setInvite(loadedInvite);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchInvite();
  }, [token]);

  async function onAccept() {
    setSubmitting(true);

    let success = false;
    try {
      await api.post(`/invite/${token}/accept`);
      success = true;
    } catch (e) {
      // Silently ignore
    }

    if (!success) {
      // TODO - error
      setSubmitting(false);
      return;
    }

    try {
      await api.patch('/user/me', { last_used_account: invite.account.id });
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    onAccountSelected(invite.account);
    navigate('/');
  }

  return (
    <Invitation
      invite={invite}
      onAccept={onAccept}
      onCancel={() => navigate('/')}
      submitting={submitting}
      view={view}
    />
  );
}

export default InvitationContainer;
