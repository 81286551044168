import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { get as getAllHighlights, useHighlights as useAllHighlights } from './highlights-manager';

const filters = {
  type: [],
  time: {
    start: undefined,
    end: undefined,
  },
  tags: [],
};
let listeners = [];

export function useHighlights() {
  const highlights = useAllHighlights();
  const [filtered, setFiltered] = useState(get());
  useEffect(() => {
    const onUpdate = () => setFiltered(get());
    onUpdate();
    return registerListener(onUpdate);
  }, [highlights]);
  return filtered;
}

export function useFilters() {
  const highlights = useHighlights();
  const [values, setValues] = useState(getFilters());
  useEffect(() => {
    const onUpdate = () => setValues(getFilters());
    onUpdate();
    return registerListener(onUpdate);
  }, [highlights]);
  return values;
}

export function get() {
  const all = getAllHighlights();
  let filtered = Array.isArray(all) ? all : [];
  if ((filters?.type?.length || 0) > 0) {
    filtered = filtered.filter(h => filters.type.includes(h.type));
  }
  if (dayjs.isDayjs(filters?.time.start)) {
    filtered = filtered.filter(h => (h['timestamp_start'] || h['timestamp_end']) && ((h['timestamp_start'] || h['timestamp_end']) >= filters.time.start.unix()));
  }
  if (dayjs.isDayjs(filters?.time.end)) {
    filtered = filtered.filter(h => (h['timestamp_start'] || h['timestamp_end']) && ((h['timestamp_start'] || h['timestamp_end']) < filters.time.end.unix()));
  }
  if ((filters?.tags?.length || 0) > 0) {
    filtered = filtered.filter(h => filters.tags.some(t => h.tags?.includes(t)));
  }
  return filtered;
}

export function getFilters() {
  let clone;
  const time = filters.time;
  try {
    clone = structuredClone(filters);
  } catch (err) {
    clone = JSON.parse(JSON.stringify(filters || {}));
  }
  if (time?.start) {
    try { clone.time.start = dayjs(time.start); } catch (err) { clone.time.start = undefined; }
  }
  if (time?.end) {
    try { clone.time.end = dayjs(time.end); } catch (err) { clone.time.end = undefined; }
  }
  return clone || {};
}

export function set(key, value) {
  if (key in filters) {
    filters[key] = value;
  }
  notifyListeners();
}

export function setTime(key, value) {
  const time = filters.time;
  if (key in time) {
    time[key] = dayjs.isDayjs(value) && value.isValid() ? value : undefined;
  }
  try {
    if (time.start?.isValid() && time.end?.isValid() && time.start.isAfter(time.end)) {
      const swap = time.end;
      time.end = time.start;
      time.start = swap;
    }
  } catch (err) { }
  notifyListeners();
}

export function clear() {
  filters.type = [];
  filters.time = { start: undefined, end: undefined };
  filters.tags = [];
  notifyListeners();
}

function notifyListeners() {
  for (const listener of listeners) {
    requestAnimationFrame(function () { try { listener(); } catch (err) { } });
  }
}

export function registerListener(listener) {
  listeners.push(listener);
  requestAnimationFrame(function () { try { listener(); } catch (err) { } });
  return () => deregisterListener(listener);
}

export function deregisterListener(listener) {
  listeners = listeners.filter(l => l !== listener);
}