/* ------ Module imports ------ */
import { useState } from 'react';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Rename from './rename.view';

function RenameContainer(props) {
  const {
    onClose,
    onRenamed,
    team,
  } = props;

  const [name, setName] = useState(team.name);
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    setSubmitting(true);

    let updatedTeam = null;
    try {
      const { data } = (await api.patch(`/team/${team.id}`, { name })).data;
      updatedTeam = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (updatedTeam) {
      onRenamed(updatedTeam.name);
    } else {
      // TODO - error
    }
  }

  return (
    <Rename
      name={name}
      onCancel={onClose}
      onNameChanged={setName}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default RenameContainer;
