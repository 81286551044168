/* ------ Module imports ------ */
import { useState } from 'react';

/* ------ Local components ------ */
import Details from './details';
import Remove from './remove';

function Expanded(props) {
  const {
    channel,
    onRemoved,
  } = props;

  const [view, setView] = useState('details');

  if (view === 'remove') {
    return (
      <Remove
        channel={channel}
        onCancel={() => setView('details')}
        onRemoved={onRemoved}
      />
    );
  }

  return (
    <Details
      channel={channel}
      onRemove={() => setView('remove')}
    />
  );
}

export default Expanded;
