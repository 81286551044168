/* ------ Module imports ------ */
import React, { useContext, useEffect, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Overlays from './overlays.view';

function OverlaysContainer() {
  const { account } = useContext(PlatformContext);

  const [view, setView] = useState('loading');
  const [overlays, setOverlays] = useState(null);
  const [tab, setTab] = useState('custom');

  async function fetchOverlays() {
    let loadedOverlays = null;
    try {
      const { data } = (await api.get(`/overlay?account=${account.id}&expand=template.sport`)).data;
      loadedOverlays = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedOverlays) {
      setOverlays(loadedOverlays);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchOverlays();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let overlaysToShow = [];
  if (overlays) {
    overlaysToShow = overlays.filter(overlay => {
      if (tab === 'custom' && overlay.account) {
        return true;
      }

      if (tab === 'default' && !overlay.account) {
        return true;
      }

      return false;
    });
  }

  return (
    <Overlays
      onTabChanged={setTab}
      overlays={overlaysToShow}
      tab={tab}
      view={view}
    />
  );
}

export default OverlaysContainer;
