// eslint-disable-next-line import/no-anonymous-default-export
export default {
  firebase: {
    apiKey: 'AIzaSyBoTUBqmnK5HRHYLjuFLOdw5iPUMZpPJRQ',
    authDomain: 'mar-staging.firebaseapp.com',
  },
  urls: {
    api: 'https://api.myactionsport.com/platform',
  },
};
