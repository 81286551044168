/* ------ Components ------ */
import Input from 'components/input';
import Modal from 'components/modal';
import Spinner from 'components/spinner';

function Recode(props) {
  const {
    code,
    onCancel,
    onCodeChanged,
    onSubmit,
    submitting,
  } = props;

  return (
    <Modal onClose={onCancel}>
      <div className="px-6 py-4">
        <h3 className="font-black text-slate-900 text-lg mb-2">Change teams three letter code</h3>
        <p className="text-sm text-slate-900">Enter a new code for the team below</p>
      </div>

      <form
        disabled={submitting}
        onSubmit={onSubmit}
      >
        <div className="border-t border-slate-200 px-6 py-4">
          <Input
            disabled={submitting}
            label="Overlay code"
            onChange={onCodeChanged}
            value={code}
          />
        </div>

        <div className="border-t border-slate-200 px-6 py-4 flex justify-end">
          <button
            className="bg-slate-200 text-slate-700 flex items-center rounded px-3 py-2 text-sm font-medium"
            disabled={submitting}
            onClick={onCancel}
            type="button"
          >
            Cancel
          </button>
          <button
            className="ml-2 bg-blue-600 text-white flex items-center rounded px-3 py-2 text-sm font-medium relative"
            disabled={submitting}
            type="submit"
          >
            <span className={submitting ? 'invisible' : ''}>Continue</span>
            {submitting && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <Spinner />
              </div>
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default Recode;
