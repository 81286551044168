/* ------ Components ------ */
import Popover from 'components/popover';

function Dropdown(props) {
  const {
    onClose,
    onDelete,
    onRename,
  } = props;

  return (
    <Popover onClose={onClose}>
      <div className="py-1">
        <button
          className="block text-left w-full px-4 py-1 text-sm font-semibold text-blue-600 hover:bg-slate-100"
          onClick={onRename}
        >
          Rename
        </button>
        <button
          className="block text-left w-full px-4 py-1 text-sm font-semibold text-red-600 hover:bg-slate-100"
          onClick={onDelete}
        >
          Delete overlay
        </button>
      </div>
    </Popover>
  );
}

export default Dropdown;
