/* ------ Module imports ------ */
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { EyeIcon, ClockIcon } from '@heroicons/react/outline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import capitalize from 'utils/capitalize';

import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

function Game(props) {
  const { game } = props;

  function renderStatus() {
    if (game.status === 'created') {
      return (
        <div className="inline-flex justify-start items-center gap-1 text-xs text-yellow-700">
          <FiberManualRecordIcon className='text-xs animate-pulse' />
          Waiting to start
        </div>
      );
    }

    if (game.status === 'live') {
      return (
        <div className="inline-flex justify-start items-center gap-1 text-xs text-red-700">
          <FiberManualRecordIcon className='text-xs animate-pulse' />
          Live now
        </div>
      );
    }

    return (
      <Link
        className="inline-flex justify-start items-center gap-1 text-xs text-slate-700"
        to={`/game/${game.id}`}
      >
        <ClockIcon className='h-3' />
        {dayjs.unix(game.created).fromNow()}
      </Link>
    );
  }

  return (
    <div className="w-full md:w-1/2 lg:w-1/3 px-2 pb-10">
      <div className="aspect-w-16 aspect-h-9">
        <Link
          className="block bg-slate-200 rounded bg-cover bg-center"
          style={{ backgroundImage: game.thumbnail ? `url(${game.thumbnail})` : undefined }}
          to={`/game/${game.id}`}
        />
      </div>
      <Link
        className="inline-block mt-2 text-slate-900"
        to={`/game/${game.id}`}
      >
        {game.title}
      </Link>
      <div className='flex flex-col justify-start items-start mt-1'>
        {renderStatus()}
        <span className="inline-flex justify-start items-center leading-none gap-1 text-xs text-slate-700 mt-1">
          <EyeIcon className='h-3' />
          {capitalize(game?.visibility || 'public')}
        </span>
      </div>
    </div>
  );
}

export default Game;
