import { useEffect } from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { setAudioCodec, useAudioCodec, useVideoCodec } from 'views/game/utils/game-file-downloader';

export default function AudioCodec() {
  const audio = useAudioCodec();
  const video = useVideoCodec();
  useEffect(() => {
    if ((audio === 'aac' || audio === 'libmp3lame') && (video === 'libvpx' || video === 'libvpx-vp9')) {
      setAudioCodec('libopus');
    }
  }, [audio, video]);
  return (
    <FormControl size='small' fullWidth={true}>
      <InputLabel id='audio-codec'>Audio File</InputLabel>
      <Select
        labelId='audio-codec'
        id='audio-codec'
        value={audio || 'none'}
        label='Audio File'
        renderValue={(v) => {
          let label;
          switch (v || '') {
            case 'aac':
              label = 'AAC';
              break;
            case 'libmp3lame':
              label = 'MP3';
              break;
            case 'libvorbis':
              label = 'Vorbis';
              break;
            case 'libopus':
              label = 'Opus';
              break;
            case '':
            case 'none':
            default:
              label = 'None';
              break;
          }
          return label;
        }}
        onChange={(e) => setAudioCodec(e.target.value)}>
        <MenuItem value='none' className='flex flex-col justify-start items-start text-left'>
          <em>None</em>
          <span className='text-sm text-slate-400'>The download will be video only</span>
        </MenuItem>
        <MenuItem value='aac' disabled={video === 'libvpx' || video === 'libvpx-vp9'}>
          AAC
        </MenuItem>
        <MenuItem value='libmp3lame' disabled={video === 'libvpx' || video === 'libvpx-vp9'}>
          MP3
        </MenuItem>
        <MenuItem value='libvorbis'>
          Vorbis
        </MenuItem>
        <MenuItem value='libopus'>
          Opus
        </MenuItem>
      </Select>
    </FormControl>
  );
}