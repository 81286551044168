function Invitation(props) {
  const {
    invite,
    onAccept,
    onCancel,
    submitting,
    view,
  } = props;

  if (view === 'loading' || view === 'error') {
    return null;
  }

  return (
    <div>
      <div className="px-12 py-10 border-b border-slate-200">
        <h1 className="font-black text-3xl text-slate-900">Invite</h1>
      </div>

      <div className="px-12 py-10">
        <p>You have been invited to join <span className="font-semibold">{invite.account.name}</span>.</p>
        <p>Are you sure you want to accept this invitation and join this account?</p>

        <div className="mt-6">
          <button
            className="bg-blue-200 text-blue-700 rounded px-3 py-2 font-medium"
            disabled={submitting}
            onClick={onCancel}
            type="button"
          >
            Cancel
          </button>
          <button
            className="ml-2 bg-blue-600 text-white rounded px-5 py-2 font-medium disabled:cursor-not-allowed"
            disabled={submitting}
            onClick={onAccept}
            type="button"
          >
            Accept invite
          </button>
        </div>
      </div>
    </div>
  );
}

export default Invitation;
