function Input(props) {
  const {
    className,
    disabled,
    error,
    label,
    autoComplete,
    onChange,
    placeholder,
    type,
    required,
    value,
  } = props;

  return (
    <div className={className}>
      {label && <p className="text-slate-700 text-sm font-semibold mb-1">{label}</p>}
      <input
        className={`w-full border ${error ? 'border-red-400' : 'border-slate-200'} bg-slate-100 rounded-lg px-4 py-2`}
        disabled={disabled}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        type={type || 'text'}
        autoComplete={autoComplete}
        value={value}
        required={required}
      />
    </div>
  );
}

export default Input;
