import React, { useRef, useState } from 'react';

import { toast } from 'react-toastify';

import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';

import { XIcon, DownloadIcon, SelectorIcon } from '@heroicons/react/solid';

import capitalize from 'utils/capitalize';

import { download, open } from '../utils/file-manager';
import { remove } from '../utils/workspace-manager';
import { Row, RowButton } from '../accessory-container';

import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from '../game.container';
import formatDuration from '../utils/timestamp-formatter';
import { useStarted } from '../utils/game-manager';

dayjs.extend(durationPlugin);

function Timestamp(props) {
  const formattedStarted = typeof props.started === 'number' ? formatDuration(props.started) : 'Pre-game';
  const formattedEnded = formatDuration(props.ended);
  if ((formattedStarted?.length || 0) > 0 && (formattedEnded?.length || 0) > 0) {
    let formattedDuration = dayjs.duration(Math.max(props.duration || 0, 0), 'seconds');
    return (
      <p className='inline whitespace-pre text-xs text-slate-500'>
        {` ${formattedStarted}${formattedStarted === 'Pre-game' ? '' : ' - ' + formattedEnded} (${(formattedDuration.asSeconds() < 60 ? formattedDuration.asSeconds() + 's' : formattedDuration.asMinutes() + 'm')})`}
      </p>
    );
  }
  else {
    return null;
  }
}

export default function Highlight(props) {
  const {
    id,
    index,
    highlight,
    onDropped,
    onReorder,
    onPlay,
  } = props;
  const ref = useRef();
  const started = useStarted();
  const [downloading, setDownloading] = useState(false);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.WORKSPACE_ITEM,
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    drop: onDropped,
    hover: (item, monitor) => {
      if (ref.current) {
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex !== hoverIndex) {
          const hoverBoundingRect = ref.current?.getBoundingClientRect();
          const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          const hoverClientY = monitor.getClientOffset() - hoverBoundingRect.top;
          if (
            !(dragIndex < hoverIndex && hoverClientY < hoverMiddleY) &&
            !(dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
          ) {
            onReorder(dragIndex, hoverIndex);
            item.index = hoverIndex;
          }
        }
      }
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.WORKSPACE_ITEM,
    item: () => ({ id, index }),
    isDragging: (monitor) => id === monitor.getItem().id,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });
  drag(drop(ref));
  return (
    <Row
      ref={ref}
      title={highlight.title}
      dragging={isDragging}
      handlerId={handlerId}
      buttons={
        <>
          <RowButton
            tip='Download highlight as MP4'
            title='Download'
            loading={downloading}
            icon={<DownloadIcon />}
            onClick={async () => {
              setDownloading(true);
              try {
                open(await download(highlight));
              } catch (err) {
                toast.error('Something went wrong, please reload and try again or contact support for help.');
              }
              setDownloading(false);
            }}
            className='ml-1' />
          <RowButton
            tip='Remove from workspace manager (does not delete the highlight from the server)'
            title='Remove from workspace'
            onClick={() => remove(id)}
            icon={<XIcon />}
            className='-mx-2' />
        </>
      }
      className='cursor-move'>
      <button
        title='Jump to highlight in live feed'
        onClick={onPlay}
        className={`w-full flex flex-row justify-start items-center text-left ${isDragging ? 'cursor-move' : ''}`.trim()}>
        <SelectorIcon className='h-6 -ml-2 mr-2 cursor-move text-slate-400' />
        <div className='flex-1 flex-col justify-start items-start'>
          <span className='text-sm font-semibold leading-none'>
            {capitalize(highlight?.type?.replace('-', ' ').replace('_', ' ') || 'Highlight')}
            <Timestamp
              started={(highlight?.['timestamp_start'] || 0) > (started || 0) ? highlight['timestamp_start'] - started : undefined}
              ended={(highlight?.['timestamp_end'] || 0) > (started || 0) ? highlight['timestamp_end'] - started : undefined}
              duration={typeof highlight?.['timestamp_start'] === 'number' && typeof highlight?.['timestamp_end'] === 'number' ? highlight['timestamp_end'] - highlight['timestamp_start'] : undefined}  />
          </span>
          {
            (highlight?.title?.length || 0) > 0 &&
            <p className='text-xs text-slate-400 leading-snug line-clamp-1'>
              {highlight.title}
            </p>
          }
        </div>
      </button>
    </Row>
  );
}