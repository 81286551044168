import { useEffect, useState } from 'react';

import storage from 'utils/storage';
import { download } from './file-manager';
import { getGameId } from './game-manager';
import { get as getAvailableHighlights } from './highlights-manager';

let highlights;
let listeners = [];

const STORAGE_KEY = 'workspace-manager';

export function useSelected() {
  const [invalidating, setInvalidating] = useState(false);
  useEffect(() => {
    const onUpdate = () => {
      setInvalidating(true);
      requestAnimationFrame(() => setInvalidating(false));
    };
    return registerListener(onUpdate);
  }, []);
  return !invalidating ? getSelected() : undefined;
}

export default async function init() {
  highlights = [];
  if ((getGameId()?.length || 0) > 0) {
    for (const stored of ((await storage.get(STORAGE_KEY) || {})[getGameId()] || [])) {
      stored.highlight = getAvailableHighlights().find(v => v?.id === stored?.highlight?.id);
      if ((stored?.highlight?.id?.length || 0) > 0) {
        highlights.push(stored);
      }
    }
  }
  notifyListeners();
  return getSelected();
}

export function getSelected() {
  try { return highlights.slice(); } catch (err) { return []; }
}

export async function reorder(ids) {
  const reordered = [];
  for (const id of ids) {
    reordered.push(highlights.find(h => h.id === id));
  }
  highlights = reordered;
  await save();
}

export async function add(highlight) {
  if (typeof highlight === 'object') {
    highlights.push({
      id: performance.now(),
      highlight: highlight
    });
    download(highlight); //A heuristic to speed up possible later interactions
    await save();
  }
  return highlight;
}

export async function remove(highlightOrId) {
  if (typeof highlightOrId === 'object') {
    highlightOrId = highlightOrId?.id || highlightOrId?.highlight?.id;
  }
  highlights = highlights.filter(h => h?.id !== highlightOrId);
  const stored = await storage.get(STORAGE_KEY) || {};
  stored[getGameId()] = highlights;
  await storage.set(STORAGE_KEY, stored);
  notifyListeners();
}

export async function clear() {
  highlights = [];
  const stored = await storage.get(STORAGE_KEY) || {};
  stored[getGameId()] = highlights;
  await storage.set(STORAGE_KEY, stored);
  notifyListeners();
}

async function save() {
  const stored = await storage.get(STORAGE_KEY) || {};
  stored[getGameId()] = highlights;
  await storage.set(STORAGE_KEY, stored);
  notifyListeners();
}

function notifyListeners() {
  for (const listener of listeners) {
    requestAnimationFrame(function () { try { listener(); } catch (err) { } });
  }
}

export function registerListener(listener) {
  listeners.push(listener);
  requestAnimationFrame(function () { try { listener(); } catch (err) { } });
  return () => deregisterListener(listener);
}

export function deregisterListener(listener) {
  listeners = listeners.filter(l => l !== listener);
}