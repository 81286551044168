import localforage from 'localforage';
import dayjs from 'dayjs';

const VERSION = 1;
const ISO_8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

function get(key) {
  return getItem(key);
}

async function getItem(key) {
  if ((key?.length || 0) > 0) {
    try {
      let value = deserialize(await localforage.getItem(key));
      if (value !== null && typeof value !== 'undefined') {
        if (value.expiry !== null && typeof value.expiry !== 'undefined') {
          if (dayjs.isDayjs(value.expiry) && dayjs().isBefore(value.expiry)) {
            value = value.value;
          }
          else {
            value = null;
            await remove(key);
          }
        }
        else if (value.version !== VERSION) {
          value = null;
          await remove(key);
        }
        else {
          value = value.value;
        }
      }
      return value;
    } catch (err) {
      return null;
    }
  }
  else {
    return null;
  }
}

function deserialize(value) {
  if (typeof value === 'object' && value !== null && !(value instanceof Blob)) {
    for (const key in value) {
      if (typeof value[key] === 'string' && ISO_8601.test(value[key])) {
        const original = value[key];
        try {
          value[key] = dayjs(value[key]);
          if (!value[key] || !value[key].isValid()) {
            value[key] = original;
          }
        } catch (err) { 
          value[key] = original;
        }
      }
      else {
        value[key] = deserialize(value[key]);
      }
    }
  }
  else if (Array.isArray(value)) {
    value.forEach(function (v, index) {
      this[index] = deserialize(v);
    }, value);
  }
  return value;
}

function set(key, value, duration, unit) {
  return setItem(key, value, duration, unit);
}

async function setItem(key, value, duration, unit) {
  if ((key?.length || 0) > 0) {
    if (typeof value !== 'undefined') {
      try {
        value = {
          value: value,
          version: VERSION,
        };
        if (typeof duration === 'number' && typeof unit === 'string') {
          value.expiry = dayjs().add(duration, unit);
        }
        await localforage.setItem(key, serialize(value));
      } catch (err) {}
    }
    else {
      await removeItem(key);
    }
  }
}

function serialize(value) {
  if (typeof value === 'object' && value !== null && !(value instanceof Blob)) {
    for (const key in value) {
      if (value[key] !== null && typeof value[key] !== 'undefined' && dayjs.isDayjs(value[key])) {
        value[key] = value[key].toISOString();
      }
      else {
        value[key] = serialize(value[key]);
      }
    }
  }
  else if (Array.isArray(value)) {
    value.forEach(function (v, index) {
      this[index] = serialize(v);
    }, value);
  }
  return value;
}

function clear() {
  return localforage.clear();
}

function remove(key) {
  return removeItem(key);
}

function deleteItem(key) {
  return removeItem(key);
}

function clearItem(key) {
  return removeItem(key);
}

async function removeItem(key) {
  if ((key?.length || 0) > 0) {
    try { await localforage.removeItem(key); } catch (err) { }
  }
}

export default {
  get,
  getItem,
  set,
  setItem,
  clear,
  remove,
  deleteItem,
  clearItem,
  removeItem
};