/* ------ Module imports ------ */
import { useEffect, useState } from 'react';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Account from './account.view';

function AccountContainer(props) {
  const { account } = props;

  const [view, setView] = useState('loading');
  const [youtubeChannels, setYoutubeChannels] = useState(null);
  const [addingYoutubeChannel, setAddingYoutubeChannel] = useState(false);

  async function fetchYoutubeChannels() {
    let loadedChannels = null;
    try {
      const { data } = (await api.get(`/youtube_channel?account=${account.id}&expand=youtube_user`)).data;
      loadedChannels = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedChannels) {
      setYoutubeChannels(loadedChannels);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchYoutubeChannels();
  }, []);

  async function onAddYoutubeChannel() {
    setAddingYoutubeChannel(true);

    let oauthUrl = null;
    try {
      const { data } = (await api.get('/youtube_user/authorize')).data;
      oauthUrl = data;
    } catch (e) {
      // Silently ignore
    }

    setAddingYoutubeChannel(false);

    if (oauthUrl) {
      window.location = oauthUrl;
    } else {
      // TODO - error
    }
  }

  function onChannelRemoved(channel) {
    const updatedChannels = youtubeChannels.filter(c => c.id !== channel.id);
    setYoutubeChannels(updatedChannels);
  }

  return (
    <Account
      account={account}
      addingYoutubeChannel={addingYoutubeChannel}
      onAddYoutubeChannel={onAddYoutubeChannel}
      onChannelRemoved={onChannelRemoved}
      view={view}
      youtubeChannels={youtubeChannels}
    />
  );
}

export default AccountContainer;
