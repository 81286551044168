/* ------ Module imports ------ */
import { Link } from 'react-router-dom';
import {ChevronLeftIcon, DotsHorizontalIcon} from '@heroicons/react/solid';
import React from "react";

/* ------ Local components ------ */
import Dropdown from './dropdown';
import RemoveModal from './modals/remove';
import RenameModal from './modals/rename';
import RecodeModal from './modals/recode';


function Team(props) {
  const {
    dropdownOpen,
    modal,
    onCloseDropdown,
    onCloseModal,
    onDelete,
    onOpenDropdown,
    onRename,
    onRenamed,
    onRecode,
    onRecoded,
    team,
    view,
  } = props;

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <div className="px-12 py-10 border-b border-slate-200">
        <Link
          className="inline-block rounded border border-slate-200 bg-slate-100 rounded px-2 py-2"
          to="/teams"
        >
          <div className="flex items-center">
            <ChevronLeftIcon className="h-4 text-slate-900" />
            <span className="text-sm text-slate-700 ml-2">Back to teams</span>
          </div>
        </Link>

        <div className="mt-6 flex justify-between">
          <div className="flex items-center">
            <div
              className="w-16 h-16 rounded-full bg-cover bg-slate-200 border border-slate-200"
              style={{ backgroundImage: team.logo_url ? `url(${team.logo_url})` : '' }}
            />
            <h1 className="ml-4 font-black text-3xl text-slate-900">{team.name}</h1>

          </div>

          <div className="relative">
            <button
              className="flex items-center justify-center rounded-lg h-8 w-8 bg-blue-100 hover:bg-blue-200"
              onClick={onOpenDropdown}
            >
              <DotsHorizontalIcon className="h-4 text-blue-700" />
            </button>

            {dropdownOpen && (
              <div className="relative">
                <Dropdown
                  onClose={onCloseDropdown}
                  onDelete={onDelete}
                  onRename={onRename}
                  onRecode={onRecode}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <h2 className="font-black text-xl text-slate-900 mb-4">Details</h2>

        <div className="flex items-center justify-between">
          <p className="w-48 text-sm text-slate-700">Sport</p>
          <p className="flex-1 text-sm font-medium text-slate-900">{team.sport.name}</p>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <p className="w-48 text-sm text-slate-700">3 letter team code</p>
          <p className="flex-1 text-sm font-medium text-slate-900">{team.code}</p>
        </div>
      </div>

      {modal === 'rename' && (
        <RenameModal
          onClose={onCloseModal}
          onRenamed={onRenamed}
          team={team}
        />
      )}

      {modal === 'recode' && (
        <RecodeModal
          onClose={onCloseModal}
          onRecoded={onRecoded}
          team={team}
        />
      )}

      {modal === 'remove' && (
        <RemoveModal
          onClose={onCloseModal}
          team={team}
        />
      )}
    </>
  );
}

export default Team;
