/* ------ Module imports ------ */
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';

function Pagination(props) {
  const {
    onChangePage,
    page,
    total,
  } = props;

  let startingNumber = Math.max(page - 2, 1);
  if (page + 1 === total) {
    startingNumber = Math.max(startingNumber - 1, 1);
  }
  if (page === total) {
    startingNumber = Math.max(startingNumber - 2, 1);
  }

  let labels = [];
  for (let i = startingNumber; i <= total; i++) {
    labels.push(i);

    if (labels.length >= 5) {
      break;
    }
  }

  return (
    <div className="border-t border-slate-200 flex justify-between">
      <button
        className={`${page <= 1 ? 'invisible' : ''}`}
        onClick={() => onChangePage(page - 1)}
      >
        <ArrowLeftIcon className="h-4 text-slate-600" />
      </button>

      <div className="-mt-px flex">
        <div className={`px-3 py-4 text font-medium text-slate-600 ${labels[0] === 1 ? 'invisible' : ''}`}>
          &hellip;
        </div>

        {labels.map(num => (
          <button
            className={`px-3 py-4 text font-medium border-t-2 ${num === page ? 'text-blue-600 border-blue-600' : 'text-slate-600 border-transparent'}`}
            key={num}
            onClick={() => onChangePage(num)}
          >
            {num}
          </button>
        ))}

        <div className={`px-3 py-4 text font-medium text-slate-600 ${labels[labels.length - 1] === total ? 'invisible' : ''}`}>
          &hellip;
        </div>
      </div>

      <button
        className={`${page >= total ? 'invisible' : ''}`}
        onClick={() => onChangePage(page + 1)}
      >
        <ArrowRightIcon className="h-4 text-slate-600" />
      </button>
    </div>
  );
}

export default Pagination;
