/* ------ Module imports ------ */
import { useContext, useEffect, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Devices from './devices.view';

function DevicesContainer() {
  const { account } = useContext(PlatformContext);

  const [devices, setDevices] = useState(null);
  const [view, setView] = useState('loading');

  async function fetchDevices() {
    let loadedDevices = null;
    try {
      const { data } = (await api.get(`/device?account=${account.id}`)).data;
      loadedDevices = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedDevices) {
      setDevices(loadedDevices);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchDevices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Devices
      devices={devices}
      view={view}
    />
  );
}

export default DevicesContainer;
