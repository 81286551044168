/* ------ Module imports ------ */
import {useEffect, useState} from 'react';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import ResetPassword from './resetpassword.view';

function ResetPasswordContainer(props) {
  const { token } = props;

  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [expired, setExpired] = useState(false);

  async function onLoad(){
    try {
      const { data } = (await api.get(`/password_reset/${token}`, {}, false)).data;
    } catch (e) {
      setExpired(true);
      setShowForm(false);
    }
  }


  async function onSubmit(e) {
    e.preventDefault();
    setError(null);
    setSubmitting(true);
      if (confirmPassword === password) {
        try {
          const responseData = (await api.post(`/password_reset/${token}/complete`, { password }, {}, false)).data;
          setShowSuccess(true);
          setShowForm(false);
        } catch (e) {
          setError("Password must be at least 8 characters");
        }
      } else {
        setError("Passwords do not match");
      }
    setSubmitting(false);
  }

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ResetPassword
      error={error}
      expired={expired}
      setPassword={setPassword}
      setConfirmPassword={setConfirmPassword}
      password={password}
      showForm={showForm}
      onSubmit={onSubmit}
      submitting={submitting}
      showSuccess={showSuccess}
    />
  );

}


export default ResetPasswordContainer;
