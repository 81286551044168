/* ------ Components ------ */
import Input from 'components/input';
import Spinner from 'components/spinner';

function Login(props) {
  const {
    email,
    onEmailChanged,
    onPasswordChanged,
    onSubmit,
    password,
    submitting,
    error,
  } = props;

  return (
    <>
      <div className="p-6">
        {
          error &&
          (
            <div className="text-center mb-2">
              <span className="text-red-600">Invalid email or password</span>
            </div>
          )
        }
      <form
        disabled={submitting}
        onSubmit={onSubmit}
      >
        <Input
          disabled={submitting}
          label="Email"
          type='email'
          autoComplete='email'
          onChange={onEmailChanged}
          value={email}
          error={error}
        />

        <Input
          className="mt-6"
          disabled={submitting}
          label="Password"
          onChange={onPasswordChanged}
          type="password"
          autoComplete='current-password'
          value={password}
          error={error}
        />

        <div className="my-4 flex justify-center">
          <a
            className="self-center text-blue-500 hover:opacity-75 hover:underline font-semibold text-xs"
            href={`/reset-password?email=${encodeURIComponent(email)}`.trim()}
          >
            Forgotten Password?
          </a>
        </div>

        <button
          className="w-full bg-blue-500 hover:bg-blue-600 disabled:hover:bg-blue-500 rounded-lg text-white py-3 mt-4 text-sm font-semibold relative"
          disabled={submitting}
          type="submit"
        >
          <span className={submitting ? 'invisible': ''}>Login</span>
          {submitting && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <Spinner />
            </div>
          )}
        </button>
      </form>
    </div>
    </>
  );
}

export default Login;
