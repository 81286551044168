/* ------ Module imports ------ */
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Details from './details.view';

function DetailsContainer() {
  const {
    onUserUpdated,
    user,
  } = useContext(PlatformContext);

  const navigate = useNavigate();

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [modal, setModal] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  async function onConfirm() {
    let emailChanged = false;
    if (modal === 'email') {
      emailChanged = true;
    }

    setModal(null);
    setSubmitting(true);

    let updatedUser = null;
    try {
      const { data } = (await api.patch('/user/me', { name, email })).data;
      updatedUser = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (updatedUser) {
      if (emailChanged) {
        navigate('/login');
      } else {
        onUserUpdated(updatedUser);

        // TODO - success message
      }
    } else {
      // TODO - error
    }
  }

  function onSubmit() {
    if (email.trim() !== user.email) {
      setModal('email');
      return;
    }

    onConfirm();
  }

  return (
    <Details
      email={email}
      modal={modal}
      name={name}
      onChangePassword={() => setModal('password')}
      onCloseModal={() => setModal(null)}
      onConfirm={onConfirm}
      onEmailChanged={setEmail}
      onNameChanged={setName}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default DetailsContainer;
