/* ------ Module imports ------ */
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Channel from './channel.view';

function ChannelContainer(props) {
  const {
    channel,
    youtubeUser,
  } = props;

  const { account } = useContext(PlatformContext);

  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  async function onConfirm() {
    setSubmitting(true);

    const data = {
      account: account.id,
      youtube_id: channel.youtube_id,
      youtube_user: youtubeUser.id,
    };

    let err = null;
    try {
      await api.post('/youtube_channel', data);
    } catch (e) {
      err = e?.response?.data?.code || 'something_went_wrong';
    }

    setSubmitting(false);

    if (!err) {
      navigate('/settings');
    } else {
      setError(err);
    }
  }

  return (
    <Channel
      channel={channel}
      error={error}
      onCancel={() => navigate('/settings')}
      onConfirm={onConfirm}
      submitting={submitting}
    />
  );
}

export default ChannelContainer;
