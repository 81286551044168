/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Utils ------ */
import api from 'utils/api';
import useFileInput from 'utils/use-file-input';

/* ------ View import ------ */
import Image from './image.view';

function ImageContainer(props) {
  const {
    disabled,
    field,
    onImageUploaded,
  } = props;

  const [image, setImage] = useState(null);

  const onUpload = useFileInput(async files => {
    if (files.length > 0) {
      const file = files[0];

      const postData = new FormData();
      postData.append('file', file);

      let createdImage = null;
      try {
        const { data } = (await api.post('/image', postData)).data;
        createdImage = data;
      } catch (e) {
        // Silently ignore
      }

      if (createdImage) {
        setImage(createdImage);
        onImageUploaded(createdImage);
      } else {
        // TODO
      }
    }
  });

  return (
    <Image
      disabled={disabled}
      field={field}
      image={image}
      onUpload={onUpload}
    />
  );
}

export default ImageContainer;
