/* ------ Module imports ------ */
import React from 'react';
import { PhotographIcon, UploadIcon } from '@heroicons/react/outline';

function Image(props) {
  const {
    disabled,
    image,
    onUpload,
  } = props;

  return (
    <div className="mt-10 flex">
      <div
        className="w-12 h-12 flex items-center justify-center rounded-lg bg-gray-100 border border-dashed border-slate-400 bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: image ? `url(${image.path})` : undefined }}
      >
        {!image && <PhotographIcon className="w-6 text-slate-500" />}
      </div>

      <div className="ml-4 mt-1">
        <p className="text-slate-900">Logo (optional)</p>
        <p className="text-slate-600 text-xs">Please upload a jpg or png, no larger than 1mb</p>
        <button
          className="mt-4 bg-blue-600 text-white flex items-center rounded px-2 py-2"
          disabled={disabled}
          onClick={onUpload}
          type="button"
        >
          <UploadIcon className="h-4 text-white" />
          <span className="text-sm font-medium ml-2">{image ? 'Change image' : 'Upload'}</span>
        </button>
      </div>
    </div>
  );
}

export default Image;
