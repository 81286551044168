/* ------ Module imports ------ */
import { ExclamationIcon } from '@heroicons/react/solid';

/* ------ Components ------ */
import Spinner from 'components/spinner';

function Channel(props) {
  const {
    channel,
    error,
    onCancel,
    onConfirm,
    submitting,
  } = props;

  function getErrorText() {
    switch (error) {
      case 'live_streaming_not_enabled':
        return 'Livestreaming is not enabled on this YouTube channel';
      case 'already_added':
        return 'This YouTube channel has already been added to this account';
      default:
        return 'Something went wrong adding this channel';
    }
  }

  return (
    <div className="px-12 py-10">
      <div className="flex items-center mb-6">
        <div
          className="bg-cover w-12 h-12 rounded-full"
          style={{ backgroundImage: `url(${channel.profile_image_url})` }}
        />
        <h1 className="ml-4 font-black text-3xl text-slate-900">{channel.name}</h1>
      </div>

      {error && (
        <div className="w-full mb-6 inline-block bg-red-100 rounded-lg text-red-700 text-sm px-6 py-4 flex items-center">
          <ExclamationIcon className="h-6" />

          <div className="ml-4">
            <p className="font-semibold">{getErrorText()}</p>
            <p>Please see <a className="underline" target="_blank" href="https://myactionsport.com" rel="noreferrer">this link</a> for help</p>
          </div>
        </div>
      )}

      <div className="rounded-lg border border-slate-200 bg-slate-100 px-6 py-4">
        <p className="text-sm font-medium text-slate-900">Are you sure you want to add this YouTube channel to your account?</p>
        <p className="text-sm text-slate-900">This will allow users of this account to livestream to this channel</p>

        <div className="mt-4">
          <button
            className="bg-blue-200 text-blue-700 rounded px-3 py-2 font-medium"
            disabled={submitting}
            onClick={onCancel}
            type="button"
          >
            Cancel
          </button>
          <button
            className="ml-2 bg-blue-600 text-white rounded px-5 py-2 font-medium disabled:cursor-not-allowed relative"
            disabled={submitting}
            onClick={onConfirm}
            type="button"
          >
            <span className={submitting ? 'invisible': ''}>Confirm</span>
            {submitting && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <Spinner />
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Channel;
