import { useEffect, useState } from 'react';
import { dispatch } from './communicator';

let currentTime;
let duration;
let listeners = [];

export function useCurrentTime() {
  const [value, setValue] = useState(getTime());
  useEffect(() => {
    const onUpdate = () => {
      if (value !== getTime()) {
        setValue(getTime());
      }
    };
    return registerListener(onUpdate);
  }, [value]);
  return value;
}

export const useTime = useCurrentTime;

export function useDuration() {
  const [value, setValue] = useState(getDuration());
  useEffect(() => {
    const onUpdate = () => {
      if (value !== getDuration()) {
        setValue(getDuration());
      }
    };
    return registerListener(onUpdate);
  }, [value]);
  return value;
}

export default function init() {
  currentTime = null;
  duration = null;
  notifyListeners();
}

export function onPlayHighlight(highlight) {
  const id = typeof highlight === 'string' ? highlight : (typeof highlight === 'object' ? highlight?.id : undefined);
  if ((id?.length || 0) > 0) {
    dispatch({
      type: 'highlight',
      data: id,
    });
  }
}

export function getTime() {
  return currentTime;
}

export function getCurrentTime() {
  return getTime();
}

export function setTime(value) {
  if (typeof value === 'string') {
    try { value = parseFloat(value); } catch (err) { }
  }
  if (typeof value === 'number' && !isNaN(value) && isFinite(value)) {
    currentTime = value;
  }
  notifyListeners();
}

export function getDuration() {
  return duration;
}

export function setDuration(value) {
  if (typeof value === 'string') {
    try { value = parseFloat(value); } catch (err) { }
  }
  if (typeof value === 'number' && !isNaN(value) && isFinite(value)) {
    duration = value;
  }
  notifyListeners();
}

function notifyListeners() {
  for (const listener of listeners) {
    requestAnimationFrame(function () { try { listener(); } catch (err) { } });
  }
}

export function registerListener(listener) {
  listeners.push(listener);
  requestAnimationFrame(function () { try { listener(); } catch (err) { } });
  return () => deregisterListener(listener);
}

export function deregisterListener(listener) {
  listeners = listeners.filter(l => l !== listener);
}