import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import { getContainerExtension, useConversionProgress, useConvertedBlob, useDownloadProgress } from 'views/game/utils/game-file-downloader';
import { open } from 'views/game/utils/file-manager';
import dayjs from 'dayjs';
import { getGameTitle } from 'views/game/utils/game-manager';

function LinearProgressWithLabel(props) {
  return (
    <div className='flex flex-col w-full justify-start items-start gap-1'>
      <h2 className='text-lg leading-none font-semibold'>{props.title}</h2>
      <div className='flex w-full flex-row justify-center items-center gap-4'>
        <LinearProgress variant='determinate' {...props} className='flex-1' />
        <p className='min-w-[65px] text-center'>
          {`${props.value?.toFixed(2) || '--'}%`}
        </p>
      </div>
    </div>
  );
}

function DownloadProgress() {
  const progress = useDownloadProgress();
  return (
    <LinearProgressWithLabel
      title='Download Progress'
      value={(typeof progress === 'number' && progress >= 0 && progress <= 1.0 ? progress : 1.0) * 100} />
  );
}

function ConversionProgress() {
  const progress = useConversionProgress();
  return (
    <LinearProgressWithLabel
      title='Conversion Progress'
      value={(typeof progress === 'number' && progress >= 0 && progress <= 1.0 ? progress : 1.0) * 100} />
  );
}

export default function Download(props) {
  const blob = useConvertedBlob();
  return (
    <div className='flex flex-col w-full justify-start items-center gap-8 py-4'>
      <DownloadProgress />
      <ConversionProgress />
      <div className='flex flex-col w-full justify-start items-center'>
        <Button
          startIcon={<SaveOutlinedIcon />}
          size='small'
          color='inherit'
          variant='contained'
          disabled={!blob}
          onClick={() => {
            open(blob, `${getGameTitle() || dayjs.unix()}.${getContainerExtension()}`);
          }}
          className='bg-blue-500 hover:bg-blue-600 text-white'>
          Save File
        </Button>
        <Button
          size='small'
          color='inherit'
          variant='text'
          onClick={props.onCancel}
          className='text-blue-500 mt-2'>
          Cancel
        </Button>
        <span className='inline-block w-full text-center leading-tight text-xs text-slate-400 mt-4'>
          Downloading and converting a full game can take a while.<br />Please avoiding dismissing this dialog until complete.
        </span>
      </div>
    </div>
  );
}