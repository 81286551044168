/* ------ Module imports ------ */
import React from 'react';

/* ------ Local components ------ */
import CustomizableField from './customizable-field';

function CustomizationOptions(props) {
  const {
    disabled,
    onFieldUpdated,
    template,
  } = props;

  return (
    <div className="px-12 py-10 border-b border-slate-200">
      <h2 className="font-black text-xl text-slate-900">Customizations</h2>

      {template.customizable_fields && Object.keys(template.customizable_fields).map(key => (
        <CustomizableField
          disabled={disabled}
          field={template.customizable_fields[key]}
          key={key}
          onFieldUpdated={data => onFieldUpdated(key, data)}
        />
      ))}
      {!template.customizable_fields && (
        <div className="mt-6 inline-block bg-red-100 rounded-lg text-red-700 text-sm font-semibold px-4 py-2">
          The selected overlay is not customizable. Please select a different overlay to customize.
        </div>
      )}
    </div>
  );
}

export default CustomizationOptions;
