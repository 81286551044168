/* ------ Module imports ------ */
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';

function Color(props) {
  const {
    disabled,
    field,
    onUpdated,
  } = props;

  const [value, setValue] = useState('#0891b2');

  useEffect(() => {
    onUpdated(value);
  }, [value]);

  return (
    <div className="mt-10">
      <p className="mb-1 text-slate-900">{field.name}</p>
      <SketchPicker
        color={value}
        disableAlpha
        onChange={color => setValue(color.hex)}
      />
    </div>
  );
}

export default Color;
