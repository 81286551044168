/* ------ Module imports ------ */
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from './context';

/* ------ Utils ------ */
import api from 'utils/api';
import auth from 'utils/auth';

/* ------ Local components ------ */
import Header from './header';
import Sidebar from './sidebar';

/* ------ View imports ------ */
import AccountSelector from 'views/account-selector';
import Device from 'views/device';
import Devices from 'views/devices';
import Collections from 'views/devices';
import Game from 'views/game';
import Invitation from 'views/invitation';
import Livestreams from 'views/livestreams';
import NewAccount from 'views/new-account';
import NewDevice from 'views/new-device';
import NewOverlay from 'views/new-overlay';
import NewTeam from 'views/new-team';
import Overlay from 'views/overlay';
import Overlays from 'views/overlays';
import Profile from 'views/profile';
import Settings from 'views/settings';
import Team from 'views/team';
import Teams from 'views/teams';
import User from 'views/user';
import Users from 'views/users';
import YoutubeCallback from 'views/youtube-callback';

function Platform() {
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);

  const [sidebarOpen, setSidebarOpen] = useState(true);

  async function fetchUser() {
    let loadedUser = null;
    try {
      const { data } = (await api.get('/user/me?expand=last_used_account')).data;
      loadedUser = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedUser) {
      if (loadedUser.last_used_account) {
        setAccount(loadedUser.last_used_account);
      }

      setUser(loadedUser);
    } else {
      // TODO - error
    }
  }

  async function checkAuth() {
    await auth.init();
    await fetchUser();
  }

  useEffect(() => {
    checkAuth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onAccountSelected(acc) {
    setUser({ ...user, last_used_account: acc.id });
    setAccount(acc);
  }

  if (!user) {
    return null; // TODO - loading spinner
  }

  const context = {
    account,
    onAccountSelected,
    onUserUpdated: setUser,
    user,
  };

  function renderRoutes() {
    if (!account) {
      return (
        <>
          <Route path="/account/new" element={<NewAccount />} />
          <Route path="/invitation/:token" element={<Invitation />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<AccountSelector />} />
        </>
      );
    }

    return (
      <>
        <Route path="/account/new" element={<NewAccount />} />
        <Route path="/device/new" element={<NewDevice />} />}
        <Route path="/collections" element={<Livestreams />} />
        <Route path="/device/:id" element={<Device />} />
        <Route path="/devices" element={<Devices />} />
        <Route path="/game/:id" element={<Game />} />
        <Route path="/invitation/:token" element={<Invitation />} />
        <Route path="/overlay/new" element={<NewOverlay />} />
        <Route path="/overlay/:id" element={<Overlay />} />
        <Route path="/overlays" element={<Overlays />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/team/new" element={<NewTeam />} />
        <Route path="/team/:id" element={<Team />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/user/:id" element={<User />} />
        <Route path="/users" element={<Users />} />
        <Route path="/youtube/callback" element={<YoutubeCallback />} />
        <Route path="/" element={<Livestreams />} />
      </>
    );
  }

  return (
    <PlatformContext.Provider value={context}>
      <div className="flex">
        <Header onToggleSidebar={account ? () => setSidebarOpen(!sidebarOpen) : null} />
        {account && <Sidebar open={sidebarOpen} />}

        <div className={`flex-1 pt-12 pl-16 ${account ? (sidebarOpen ? 'lg:pl-80' : '') : ''}`}>
          <Routes>
            {renderRoutes()}
          </Routes>
        </div>
      </div>
    </PlatformContext.Provider>
  );
}

export default Platform;
