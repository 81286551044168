/* ------ Module imports ------ */
import { useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

/* ------ Utils ------ */
import auth from 'utils/auth';
import getToken from 'utils/greptcha';
import config from 'config';

/* ------ View import ------ */
import Signup from './signup.view';

function SignupContainer() {
  const [searchParams] = useSearchParams();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  function onChange(key, value) {
    switch (key) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      default:
      // Ignore
    }
  }

  async function onSubmit(e) {
    e.preventDefault();

    setSubmitting(true);

    try {
      (await axios.post(
        `${config.urls.api}/user`,
        {
          email,
          name,
          password,
          confirmPassword,
        },
        {
          headers: {
            'X-Captcha-Token': await getToken('user_create'),
            'X-Captcha-Action': 'user_create',
          },
        },
      ));
    } catch (e) {
      if (e.response.data.code === 'already_signed_up') {
        setError("Email already exists");
      }
      if (e.response.data.code === 'passwords_do_not_match') {
        setError("Passwords didn't match");
      }
      else if (e?.response?.data?.validation?.[0]) {
        setError(`Enter a ${e.response.data.validation[0]} (and please ensure your password is at least 8 characters long).`);
      }
      else {
        setError("Please check your inputs and try again.");
      }
    }

    let success = false;
    try {
      await auth.login(email, password);
      success = true;
    } catch (e) {
      // Silently ignore
    }

    if (success) {
      let redirectUrl = '/';
      if (searchParams.get('redirect')) {
        redirectUrl = searchParams.get('redirect');
      }

      navigate(redirectUrl);
    } else {
      setSubmitting(false);
    }
  }

  return (
    <Signup
      confirmPassword={confirmPassword}
      email={email}
      name={name}
      onChange={onChange}
      onSubmit={onSubmit}
      password={password}
      submitting={submitting}
      error={error}
    />
  );
}

export default SignupContainer;
