/* ------ Module imports ------ */
import { useEffect, useState } from 'react';

/* ------ View import ------ */
import ImageList from './image-list.view';

function ImageListContainer(props) {
  const {
    field,
    onUpdated,
  } = props;

  const [images, setImages] = useState([{ path: null }]);

  useEffect(() => {
    const imagePaths = images
      .filter(img => img.path)
      .map(img => img.path);

    onUpdated(imagePaths);
  }, [images]);

  function onAdd() {
    const updatedImages = [...images];
    updatedImages.push({ path: null });
    setImages(updatedImages);
  }

  function onRemove(index) {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  }

  function onUploaded(image, index) {
    const updatedImages = [...images];
    updatedImages[index].path = image.path;
    setImages(updatedImages);
  }

  return (
    <ImageList
      field={field}
      images={images}
      onAdd={onAdd}
      onRemove={onRemove}
      onUploaded={onUploaded}
    />
  );
}

export default ImageListContainer;
