/* ------ Module imports ------ */
import React from 'react';

import UploadIcon from '@heroicons/react/outline/UploadIcon';
import PhotographIcon from '@heroicons/react/outline/PhotographIcon';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '@mui/material/IconButton';

function Image(props) {
  const {
    data,
    field,
    onRemove,
  } = props;

  return (
    <div className="mt-10 flex">
      <div
        className="w-12 h-12 flex items-center justify-center rounded-lg bg-gray-100 border border-dashed border-slate-400 bg-contain bg-center bg-no-repeat relative"
        style={{ backgroundImage: data ? `url(${data})` : undefined }}
      >
        {!data && <PhotographIcon className="w-6 text-slate-500" />}
        {
          data && onRemove &&
          <IconButton onClick={onRemove} className='absolute -top-2 -right-2 w-[16px] h-[16px] border border-solid border-slate-400 bg-white hover:bg-white'>
            <CloseIcon className='text-xs' />
          </IconButton>
        }
      </div>

      <div className="ml-4 mt-1">
        <p className="text-slate-900">{field.name}</p>
        <p className="text-slate-600 text-xs">Please upload a jpg or png, no larger than 2mb</p>
        <button
          className="mt-4 bg-blue-600 text-white flex items-center rounded px-2 py-2"
          type="button">
          <UploadIcon className="h-4 text-white" />
          <span className="text-sm font-medium ml-2">{data ? 'Change' : 'Upload'}</span>
        </button>
      </div>
    </div>
  );
}

export default Image;
