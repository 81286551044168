/* ------ Module imports ------ */
import axios from 'axios';

/* ------ Config ------ */
import config from 'config';

/* ------ Utils ------ */
import auth from 'utils/auth';

async function request(path, method, data, authed, redirect) {
  const req = {
    url: `${config.urls.api}${path}`,
    method,
    data,
    headers: {}
  };

  if (authed) {
    try {
      const token = await auth.getToken();
      req.headers.Authorization = `Bearer ${token}`;
    } catch (e) {
      if(redirect){
        window.location = '/login';
      }
    }
  }

  const res = await axios(req);

  return res;
}

function get(path, authed = true, redirect = true) {
  return request(path, 'GET', null, authed, redirect);
}

function post(path, data = null, authed = true, redirect = true) {
  return request(path, 'POST', data, authed, redirect);
}

function patch(path, data = null, authed = true, redirect = true) {
  return request(path, 'PATCH', data, authed, redirect);
}

function remove(path, data = null, authed = true, redirect = true) {
  return request(path, 'DELETE', data, authed, redirect);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get,
  post,
  patch,
  remove,
};
