/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/solid';

function Overlays(props) {
  const {
    onTabChanged,
    overlays,
    tab,
    view,
  } = props;

  function renderOverlay(overlay) {
    const className = 'text-left px-4 py-2 w-full even:bg-slate-100 flex';
    const inner = (
      <>
        <p className="truncate text-sm text-slate-900 flex-1">{overlay.name}</p>
        <p className="text-sm text-slate-900 w-80">{overlay.template.sport.name}</p>
      </>
    );

    if (overlay.account) {
      return (
        <Link
          className={className}
          key={overlay.id}
          to={`/overlay/${overlay.id}`}
        >
          {inner}
        </Link>
      );
    }

    return (
      <div
        className={className}
        key={overlay.id}
      >
        {inner}
      </div>
    );
  }

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <div className="px-12 pt-10 border-b border-slate-200">
        <div className="flex items-center justify-between">
          <h1 className="font-black text-3xl text-slate-900">Video overlays</h1>
          <Link
            className="bg-blue-600 text-white flex items-center rounded px-2 py-2"
            to="/overlay/new"
          >
            <PlusCircleIcon className="h-4 text-white" />
            <span className="text-sm font-medium ml-2">New overlay</span>
          </Link>
        </div>

        <div className="mt-10 -mb-px flex">
          <button
            className={`px-4 py-3 border-b rounded-t text-sm font-medium ${tab === 'custom' ? 'text-blue-600 bg-blue-100 border-blue-200' : 'text-slate-600 bg-slate-100 border-slate-200'}`}
            onClick={() => onTabChanged('custom')}
            type="button"
          >
            My overlays
          </button>
          <button
            className={`ml-2 px-4 py-3 border-b rounded-t text-sm font-medium ${tab === 'default' ? 'text-blue-600 bg-blue-100 border-blue-200' : 'text-slate-600 bg-slate-100 border-slate-200'}`}
            onClick={() => onTabChanged('default')}
            type="button"
          >
            Default overlays
          </button>
        </div>
      </div>

      <div className="px-12 pt-6 pb-10">
        <div className="border border-slate-200 rounded overflow-hidden">
          <div className="border-b border-slate-200 flex px-4 py-2">
            <p className="text-sm text-slate-600 flex-1">Overlay name</p>
            <p className="text-sm text-slate-600 w-80">Sport</p>
          </div>

          {overlays.map(renderOverlay)}
          {overlays.length === 0 && (
            <div className="px-4 py-2">
              <p className="text-sm text-slate-900">No overlays created yet</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Overlays;
