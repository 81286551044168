/* ------ Module imports ------ */
import { useState } from 'react';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Remove from './remove.view';

function RemoveContainer(props) {
  const {
    invite,
    onClose,
    onRemoved,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  async function onSubmit() {
    setError(false);
    setSubmitting(true);

    let success = false;
    try {
      await api.remove(`/invite/${invite.id}`);
      success = true;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (success) {
      onRemoved(invite);
      onClose();
    } else {
      setError(true);
    }
  }

  return (
    <Remove
      error={error}
      invite={invite}
      onCancel={onClose}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default RemoveContainer;
