import { useState } from 'react';

import { FolderAddIcon, ExternalLinkIcon, RefreshIcon } from '@heroicons/react/outline';

import AccessoryContainer, { Title, IconButton } from '../accessory-container';

/* ------ Local components ------ */
import Spinner from 'components/spinner/spinner';

export default function Collections(props) {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  return (
    <AccessoryContainer
      title={
        <Title title='Collections'>
          <IconButton
            tip='Refresh Collections'
            title='Refresh'
            loading={loading}
            icon={<RefreshIcon />}
            onClick={async () => {
              setLoading(true);
              //TODO
            }} />
          <IconButton
            tip='Create new Collection'
            title='Create new'
            loading={creating}
            icon={<FolderAddIcon />}
            onClick={() => setCreating(true)}
            className='-mx-1' />
          <IconButton
            tip='View all Collections'
            title='View Collections'
            component='a'
            href='/collections'
            icon={<ExternalLinkIcon />}
            className='-mr-2' />
        </Title>
      }
      empty='Group your favourite moments together in your Collections.'
      {...props}>
      {
        loading &&
        <div className='flex justify-center items-center absolute inset-0'>
          <Spinner className='text-blue-500' />
        </div>
      }
    </AccessoryContainer>
  );
}