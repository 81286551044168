/* ------ Module imports ------ */
import { useContext, useState } from 'react';
import { ExternalLinkIcon, UserGroupIcon } from '@heroicons/react/solid';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import capitalize from 'utils/capitalize';
import usePathname from 'utils/use-pathname';
import initials from 'utils/initials';

/* ------ Local components ------ */
import Link from './link';

function AccountIcon(props) {
  const { user, account } = useContext(PlatformContext);
  const [src, setSrc] = useState(account?.['profile_image']?.startsWith('http') ? account['profile_image'] : undefined);
  return (
    <div className={`flex justify-center items-center text-center w-full h-full rounded-full border border-slate-300 bg-slate-200 overflow-hidden ${props.className || ''}`.trim()}>
      {
        src?.startsWith('http') &&
        <img
          alt=""
          role='presentation'
          className="w-full h-full object-cover"
          onError={() => setSrc(null)}
          src={src} />
      }
      {
        !src?.startsWith('http') &&
        <>
          {
            ((initials(account?.name)?.length || 0) > 0 || (initials(user?.name)?.length || 0) > 0) &&
            <p className='w-full h-full flex text-center justify-center items-center text-white dark:text-blue-500 bg-blue-600 dark:bg-white leading-[0] font-semibold'>
              {(initials(account?.name)?.length || 0) > 0 ? initials(account.name) : initials(user.name)}
            </p>
          }
          {
            (initials(account?.name)?.length || 0) === 0 && (initials(user?.name)?.length || 0) === 0 &&
            <UserGroupIcon className="w-12 leading-[0] text-slate-700" />
          }
        </>
      }
    </div>
  );
}

function Sidebar(props) {
  const { open } = props;

  const { account } = useContext(PlatformContext);

  const pathname = usePathname();

  return (
    <>
      <div className={`fixed z-30 top-0 left-0 w-16 min-h-screen max-h-screen overflow-auto bg-slate-100 border-r border-slate-200 ${open ? 'lg:hidden' : ''}`}>
        <div className="pt-12">
          <div className="p-4 text-center border-b border-slate-200">
            <div className="w-8 h-8">
              <AccountIcon className='text-[0.8rem]' />
            </div>
          </div>

          <div className="px-2 pt-2">
            <Link
              href="/"
              icon="book"
              selected={pathname === '' || pathname.startsWith('/game/')}
              small
            />
            {/* <Link
              href="/collections"
              icon="collection"
              selected={pathname.startsWith('/collections')}
              small
            /> */}
            <Link
              href="/devices"
              icon="camera"
              selected={pathname.startsWith('/device')}
              small
            />
            <Link
              href="/teams"
              icon="whistle"
              selected={pathname.startsWith('/team')}
              small
            />
            <Link
              href="/overlays"
              icon="paint-bucket"
              selected={pathname.startsWith('/overlay')}
              small
            />
            <Link
              href="/users"
              icon="users"
              selected={pathname.startsWith('/user')}
              small
            />
            <Link
              href="/settings"
              icon="settings"
              selected={pathname === '/settings'}
              small
            />
          </div>
        </div>
      </div>

      <div className={`fixed z-30 top-0 left-0 w-full max-w-xs min-h-screen max-h-screen overflow-auto bg-slate-100 border-r border-slate-200 hidden ${open ? 'lg:block' : ''}`}>
        <div className="pt-12">
          <div className="flex flex-col justify-center items-center px-6 py-6 text-center border-b border-slate-200">
            <div className="w-24 h-24">
              <AccountIcon className='text-[2rem]' />
            </div>
            <h2 className="mt-4 text-lg font-black text-slate-900">{account.name}</h2>
            <p className="text-slate-900">{capitalize(account.type)}</p>
            <a
              href={`https://scoring.myactionsport.com?code=${account.code}`}
              target='_blank'
              rel='noopener noreferrer'
              className='flex flex-row justify-center items-center text-blue-500 hover:underline opacity-100 hover:opacity-75 gap-1 mt-4'>
              Start Scoring
              <ExternalLinkIcon className="h-4" />
            </a>
            <p className='text-xs'>{`Scoring code: ${(account.code?.slice(0, 4) || 'N') + '-' + (account.code?.slice(4) || 'A')}`}</p>
          </div>
        </div>

        <div className="pt-2 pl-2">
          <Link
            href="/"
            icon="book"
            label="Livestreams"
            selected={pathname === '' || pathname.startsWith('/game/')}
          />
          {/* <Link
            href="/collections"
            icon="collection"
            label="Collections"
            selected={pathname.startsWith('/collections')}
          /> */}
          <Link
            href="/devices"
            icon="camera"
            label="Devices"
            selected={pathname.startsWith('/device')}
          />
          <Link
            href="/teams"
            icon="whistle"
            label="Teams"
            selected={pathname.startsWith('/team')}
          />
          <Link
            href="/overlays"
            icon="paint-bucket"
            label="Overlays & Logos"
            selected={pathname.startsWith('/overlay')}
          />
          <Link
            href="/users"
            icon="users"
            label="User Permissions"
            selected={pathname.startsWith('/user')}
          />
          <Link
            href="/settings"
            icon="settings"
            label="Billing & Account"
            selected={pathname === '/settings'}
          />
        </div>
      </div>
    </>
  );
}

export default Sidebar;
