import { ShareIcon } from '@heroicons/react/outline';

import ShareDialog from '../../dialogs/share-dialog';
import Button from './menu-item';

async function onAttemptNativeNavigatorShare(data) {
  let success = false;
  if ('share' in navigator && navigator.canShare(data)) {
    try {
      await navigator.share(data);
      success = true;
    } catch (err) {
      if (err?.name !== 'AbortError') {
        console.error(err);
      }
      else {
        success = true;
      }
    }
  }
  return success;
}

function getUrl(game, highlight) {
  let url;
  let params = [];
  if (game?.visibility === 'unlisted' || game?.visibility === 'public') {
    url = 'https://watch.myactionsport.com';
  }
  else {
    url = 'https://player.myactionsport.com';
    params.push('autoplay=1');
  }
  if (typeof highlight === 'string' && highlight.startsWith('hlg_')) {
    url += `/highlight/${highlight}`;
  }
  else if (typeof highlight === 'string' && highlight.startsWith('gm_')) {
    url += `/game/${highlight}`;
  }
  else if ((highlight?.id?.length || 0) > 0) {
    url += `/highlight/${highlight.id}`;
  }
  else if ((game?.id?.length || 0) > 0 || (highlight?.game?.id?.length || 0) > 0) {
    url += `/game/${(game?.id?.length || 0) > 0 ? game.id : highlight.game.id}`;
    if (typeof highlight?.['timestamp_start'] === 'number' && typeof game?.['stream_started'] === 'number') {
      params.push(`t=${(highlight['timestamp_start'] - game['stream_started']) / 1000}`);
    }
  }
  params = params.filter(p => (p?.length || 0) > 0);
  if (params.length > 0) {
    url += `?${params.join('&')}`
  }
  return url;
}

export default function ShareButton(props) {
  const data = {
    title: props.title || props.highlight?.title || 'My Action Sport',
    text: props.text || props.game?.title || 'Watch local sport LIVE on My Action Sport for free, the home of community sport.',
    url: props.url || getUrl(props.game, props.id || props.highlight),
  };
  return (
    <Button
      id={`share_${props.id || props.highlight?.id}`}
      onClick={async () => {
        if (props.onClose) {
          props.onClose();
        }
        if ((!await onAttemptNativeNavigatorShare(data))) {
          ShareDialog.open(props.title || props.highlight?.title ? `Share "${props.title || props.highlight?.title}"` : undefined, data);
        }
      }}
      icon={<ShareIcon />}>
      Share link
    </Button>
  );
}