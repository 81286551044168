import React, { useState } from 'react';

/* ------ Local components ------ */
import AccessoryContainer, { Title, IconButton } from '../accessory-container';

import { FolderAddIcon, FolderDownloadIcon, TrashIcon } from '@heroicons/react/outline';

import { download, open } from '../utils/file-manager';
import { clear, getSelected, useSelected } from '../utils/workspace-manager';

import { toast } from 'react-toastify';

const View = React.forwardRef(function View(props, ref) {
  const [removing, setRemoving] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const highlights = useSelected();
  const massActionsDisabled = (highlights?.length || 0) === 0;
  return (
    <AccessoryContainer
      ref={ref}
      title={
        <Title title='Workspace Selection'>
          <IconButton
            tip='Create a Collection from these highlights'
            title='Create collection'
            disabled={massActionsDisabled}
            icon={<FolderAddIcon />}
            onClick={async () => {
              //TODO
            }} />
          <IconButton
            tip='Download all as a .zip'
            title='Download all'
            loading={downloading}
            disabled={massActionsDisabled}
            icon={<FolderDownloadIcon />}
            onClick={async () => {
              setDownloading(true);
              try {
                open(await download(getSelected().map(s => s?.highlight)));
              } catch (err) {
                console.error(err);
                toast.error('Could not download files, please reload and try again or contact support for help.');
              }
              setDownloading(false);
            }}
            className='-mx-1' />
          <IconButton
            tip='Clear the workspace (does not delete these highlights from the server)'
            title='Clear all'
            loading={removing}
            disabled={massActionsDisabled}
            icon={<TrashIcon />}
            onClick={async () => {
              setRemoving(true);
              await clear();
              setRemoving(false);
            }}
            className='-mr-2' />
        </Title>
      }
      empty='Add files here for download or adding to a Collection'
      {...props}>
      {props.children}
    </AccessoryContainer>
  );
});

export default View;