import { useContext, useEffect, useState } from 'react';

import Stepper from '@mui/material/Stepper';
import MaterialStep from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';

import Check from '@mui/icons-material/Check';
import UserAddIcon from '@heroicons/react/solid/UserAddIcon';
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';

import PlatformContext from 'views/platform/context';

import storage from 'utils/storage';

const STORAGE_KEY = 'induction-step';

const steps = [
  {
    label: 'Invite Users',
    description: 'Add in the users who may need to help with things like: billing, creating highlight reels, managing overlays, and teams/sponsors.\n\nYou do not need to add scorers in this section.',
    button: {
      label: 'Manage users',
      href: '/users',
      icon: {
        start: <UserAddIcon className='h-4' />
      }
    }
  },
  {
    label: 'Create teams',
    description: 'Setting up some teams will allow your replays and overlays to show team logos.',
    button: {
      label: 'Create teams',
      href: '/teams',
      icon: {
        start: <Groups2OutlinedIcon className='h-4' />
      }
    }
  },
  {
    label: 'Style overlays',
    description: 'All sports will have great looking default overlays that will work with your teams. You may want to style some things like colours and sponsors.',
    button: {
      label: 'Manage overlays',
      href: '/overlays',
      icon: {
        start: <FormatPaintOutlinedIcon className='h-4' />
      }
    }
  },
  {
    label: 'Go live!',
    description: 'When you are ready, you can share the following code/link with your scorers and go live:\n\n<b>${account_code}</b>',
    button: {
      label: 'Start streaming',
      href: 'https://scoring.myactionsport.com?code=${account.code}',
      target: '_blank',
      rel: 'noopener noreferrer',
      icon: {
        start: <SensorsOutlinedIcon className='h-4' />
      }
    }
  },
];

let listeners = [];

export function useStep() {
  const [step, setStep] = useState();
  useEffect(() => {
    const onUpdate = async () => {
      const newStep = await getStep();
      if (typeof newStep === 'number' && !isNaN(newStep) && isFinite(newStep) && step !== newStep) {
        setStep(newStep);
      }
    };
    onUpdate();
    return registerListener(onUpdate);
  }, [step]);
  return step;
}

export function registerListener(listener) {
  if (typeof listener === 'function') {
    listeners.push(listener);
    requestAnimationFrame(async () => { try { listener(await getStep()); } catch (err) { } });
  }
  return () => deregisterListener(listener);
}

export function deregisterListener(listener) {
  listeners = listeners.filter(l => l !== listener);
}

function notifyListeners() {
  for (const listener of listeners) {
    requestAnimationFrame(async () => { try { listener(await getStep()); } catch (err) { } });
  }
}

export async function getStep() {
  let latestStep = await storage.get(STORAGE_KEY);
  if (typeof latestStep !== 'number' || isNaN(latestStep) || !isFinite(latestStep)) {
    latestStep = 0;
  }
  return latestStep;
}

export async function setStep(value) {
  await storage.set(STORAGE_KEY, value);
  notifyListeners();
}

function Step(props) {
  const { account } = useContext(PlatformContext);
  const hasLink = (props.values?.button?.label?.length || 0) > 0 && (props.values?.button?.href?.length || 0) > 0;
  return (
    <MaterialStep index={props.index}>
      <StepLabel optional={props.index === steps.length - 1 ? 'Last step' : undefined}>
        {props.values.label.replace('${account_code}', account.code)}
      </StepLabel>
      <StepContent className='flex flex-col justify-start items-start'>
        <div
          dangerouslySetInnerHTML={{
            __html: props.values.description.replace('${account_code}', account.code)
          }}
          className='flex flex-col justify-start items-start whitespace-pre-line gap-4' />
        {
          hasLink &&
          <Button
            size='small'
            color='inherit'
            variant='contained'
            startIcon={props.values.button?.icon?.start}
            endIcon={props.values.button?.icon?.end}
            onClick={props.onNext}
            href={props.values.button.href}
            target={props.values.button.target}
            rel={props.values.button.rel}
            className='text-white bg-blue-500 hover:bg-blue-600 mt-6 mb-2'>
            {props.values.button.label}
          </Button>
        }
        <div className='flex flex-row justify-start items-center mt-2'>
          <Button
            size='small'
            variant={hasLink ? 'text' : 'contained'}
            color='inherit'
            onClick={props.onNext}
            className={hasLink ? 'text-blue-500 -ml-2' : 'text-white bg-blue-500 hover:bg-blue-600'}>
            {props.index === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
          <Button
            disabled={props.index === 0}
            size='small'
            onClick={props.onBack}
            className='text-blue-500'>
            Back
          </Button>
        </div>
      </StepContent>
    </MaterialStep>
  );
}

export default function Induction() {
  const step = useStep();
  if (typeof step === 'number' && !isNaN(step) && isFinite(step) && step > -1 && step <= steps.length) {
    return (
      <div className='flex justify-center items-center px-12 py-10'>
        <div className='flex flex-col justify-start items-start max-w-[calc(1080px-3rem)]'>
          <h1 className='font-black text-3xl text-slate-900'>Account Setup</h1>
          <span className='mt-1'>Welcome to My Action Sport. If this is your first time using our platform, here are some handy steps to get you and your organisation up&nbsp;to&nbsp;speed.</span>
          <Button
            color='inherit'
            startIcon={<Check />}
            onClick={() => setStep(-1)}
            className='text-blue-500 mt-2'>
            Mark All Completed
          </Button>
          <div className='flex flex-col justify-center items-center w-full'>
            <div className='w-full max-w-[500px] mt-10'>
              <Stepper activeStep={step} orientation='vertical'>
                {
                  steps.map((values, index) => {
                    return (
                      <Step
                        key={index}
                        values={values}
                        index={index}
                        onNext={() => setStep(step + 1)}
                        onBack={() => setStep(step - 1)} />
                    );
                  })
                }
              </Stepper>
              {
                step === steps.length &&
                <div className='flex flex-col justify-start items-start p-3'>
                  All steps completed - you&apos;re all ready&nbsp;to&nbsp;go!
                  <div className='flex flex-row justify-center items-center gap-4 mt-4'>
                    <Button
                      color='inherit'
                      size='small'
                      variant='contained'
                      startIcon={<Check />}
                      onClick={() => setStep(-1)}
                      className='text-white bg-blue-500 hover:bg-blue-600'>
                      Done
                    </Button>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={() => setStep(0)}
                      className='text-blue-500 -ml-2'>
                      Restart
                    </Button>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={() => setStep(steps.length - 1)}
                      className='text-blue-500 -ml-4'>
                      Back
                    </Button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return null;
  }
}