/* ------ Module imports ------ */
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/solid';

function User(props) {
  const {
    user,
    view,
  } = props;

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <div>
      <div className="px-12 py-10 border-b border-slate-200">
        <Link
          className="inline-block rounded border border-slate-200 bg-slate-100 rounded px-2 py-2"
          to="/users"
        >
          <div className="flex items-center">
            <ChevronLeftIcon className="h-4 text-slate-900" />
            <span className="text-sm text-slate-700 ml-2">Back to users</span>
          </div>
        </Link>

        <h1 className="mt-6 font-black text-3xl text-slate-900">{user.name}</h1>
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <h2 className="font-black text-xl text-slate-900 mb-4">Details</h2>
        <div className="flex items-center justify-between">
          <p className="w-48 text-sm text-slate-700">Name</p>
          <p className="flex-1 text-sm font-medium text-slate-900">{user.name}</p>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <p className="w-48 text-sm text-slate-700">Email</p>
          <p className="flex-1 text-sm font-medium text-slate-900">{user.email}</p>
        </div>
      </div>
    </div>
  );
}

export default User;
