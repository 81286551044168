/* ------ Module imports ------ */
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

/* ------ Local components ------ */
import Remove from './remove';
import Input from "../../components/input";

function Device(props) {
  const {
    device,
    onCancelRemoving,
    onRemove,
    onRemoved,
    removing,
    onRename,
    renaming,
    deviceName,
    onNameChange,
    onSubmit,
    submitting,
    view,
    error,
  } = props;

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <div className="px-12 py-10 border-b border-slate-200">
        <Link
          className="inline-block rounded border border-slate-200 bg-slate-100 rounded px-2 py-2"
          to="/devices"
        >
          <div className="flex items-center">
            <ChevronLeftIcon className="h-4 text-slate-900" />
            <span className="text-sm text-slate-700 ml-2">Back to devices</span>
          </div>
        </Link>

        {!renaming && <h1 className="mt-6 font-black text-3xl text-slate-900">{deviceName}</h1>}
        {
          renaming && (
            <form
              disabled={submitting}
              onSubmit={onSubmit}
            >
              {error && <div className="text-red-600 mt-4 flex flex-row"> <ExclamationCircleIcon className="mt-1 mr-2 h-4"/> {error} </div> }
              <Input
                className="mt-4"
                disabled={submitting}
                label="Device name"
                onChange={onNameChange}
                value={deviceName}
              />
              <button
                className="mt-4 w-52 bg-blue-500 hover:bg-blue-600 disabled:hover:bg-blue-500 rounded-lg text-white py-3 text-sm font-semibold relative"
                disabled={submitting}
                type="submit"
              > Change device name </button>
            </form>

          )
        }
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <h2 className="font-black text-xl text-slate-900 mb-4">Details</h2>

        <div className="mt-2 flex items-center">
          <p className="w-48 text-sm text-slate-700">Name</p>
          <p className="flex-1 text-sm text-slate-900">{device.name}</p>
        </div>
        <div className="mt-2 flex items-center">
          <p className="w-48 text-sm text-slate-700">Type</p>
          <p className="flex-1 text-sm text-slate-900">{device.type.toUpperCase()}</p>
        </div>
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <h2 className="font-black text-xl text-slate-900 mb-4">{`${device.type.toUpperCase()} details`}</h2>

        {device.type === 'rtmp' && (
          <>
            <div className="mt-2 flex items-center justify-between">
              <p className="w-48 text-sm text-slate-700">RTMP url</p>
              <div className="flex-1">
                <div className="inline-block bg-slate-100 rounded-lg border border-slate-200 overflow-hidden px-2 py-1">
                  <p className="text-sm font-medium font-mono text-slate-900">{device.rtmp_url.base_url}</p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="w-48 text-sm text-slate-700">Stream key</p>
              <div className="flex-1">
                <div className="inline-block bg-slate-100 rounded-lg border border-slate-200 overflow-hidden px-2 py-1">
                  <p className="flex-1 text-sm font-medium font-mono text-slate-900">{device.rtmp_url.stream_key}</p>
                </div>
              </div>
            </div>
          </>
        )}

        {device.type === 'rtsp' && (
          <div className="mt-2 flex items-center justify-between">
            <p className="w-48 text-sm text-slate-700">RTSP url</p>
            <div className="flex-1">
              <div className="inline-block bg-slate-100 rounded-lg border border-slate-200 overflow-hidden px-2 py-1">
                <p className="text-sm font-medium font-mono text-slate-900">{device.rtsp_url}</p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <h2 className="font-black text-xl text-slate-900 mb-4">Actions</h2>
        {!removing && (
          <>
            <button
              className="bg-red-100 hover:bg-red-200 text-red-600 rounded-lg px-2 py-1 inline-block font-semibold text-sm mr-4"
              onClick={onRemove}
            >
              Delete this device
            </button>
            <button
            className="bg-blue-100 hover:bg-blue-200 text-blue-600 rounded-lg px-2 py-1 inline-block font-semibold text-sm"
            onClick={onRename}
            >
            Rename Device
            </button>
          </>
        )}

        {removing && (
          <Remove
            device={device}
            onCancel={onCancelRemoving}
            onRemoved={onRemoved}
          />
        )}
      </div>
    </>
  );
}

export default Device;
