/* ------ Module imports ------ */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ Local components ------ */
import Channel from './channel';
import List from './list';

function YoutubeCallback() {
  const [searchParams] = useSearchParams();

  const [view, setView] = useState('loading');
  const [youtubeUser, setYoutubeUser] = useState(null);
  const [youtubeChannels, setYoutubeChannels] = useState(null);
  const [selected, setSelected] = useState(null);

  async function onCallback() {
    const code = searchParams.get('code');
    if (!code) {
      setView('error');
      return;
    }

    let ytUser = null;
    let ytChannels = null;
    try {
      const { data } = (await api.post('/youtube_user/callback', { code })).data;
      ytUser = data.youtube_user;
      ytChannels = data.channels;
    } catch (e) {
      // Silently ignore
    }

    if (ytUser && ytChannels) {
      setYoutubeUser(ytUser);
      setYoutubeChannels(ytChannels);

      if (ytChannels.length === 1) {
        setSelected(ytChannels[0]);
      }

      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    onCallback();
  }, []);

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  if (selected) {
    return (
      <Channel
        channel={selected}
        youtubeUser={youtubeUser}
      />
    );
  }

  return (
    <List
      channels={youtubeChannels}
      onSelect={setSelected}
    />
  );
}

export default YoutubeCallback;
