/* ------ Module imports ------ */
import React from 'react';

/* ------ Components ------ */
import Input from 'components/input';
import Select from 'components/select';

/* ------ Local components ------ */
import CustomizationOptions from './customization-options';
import TemplateSelector from './template-selector';

function NewOverlay(props) {
  const {
    canSave,
    name,
    onCancel,
    onFieldUpdated,
    onNameChanged,
    onSave,
    onSportChanged,
    onTemplateSelected,
    sport,
    sports,
    submitting,
    template,
    view,
  } = props;

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <div>
      <div className="px-12 py-10 border-b border-slate-200">
        <h1 className="font-black text-3xl text-slate-900">New custom overlay</h1>
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <h2 className="font-black text-xl text-slate-900">Details</h2>

        <div className="mt-10 w-full">
          <p className="text-sm text-slate-700 mb-1">Overlay name</p>
          <Input
            className="w-full max-w-sm"
            disabled={submitting}
            onChange={onNameChanged}
            value={name}
          />
        </div>
        <div className="mt-6 w-full">
          <p className="text-sm text-slate-700 mb-1">Sport</p>
          <Select
            className="w-full max-w-sm"
            disabled={submitting}
            onChange={onSportChanged}
            value={sport}
          >
            <option disabled value="">Select a sport...</option>
            {sports.map(sport => (
              <option
                key={sport.id}
                value={sport.id}
              >
                {sport.name}
              </option>
            ))}
          </Select>
        </div>

        <TemplateSelector
          disabled={submitting}
          onTemplateSelected={onTemplateSelected}
          sport={sport}
        />
      </div>

      {template && (
        <CustomizationOptions
          disabled={submitting}
          onFieldUpdated={onFieldUpdated}
          template={template}
        />
      )}

      <div className="px-12 py-10">
        <button
          className="bg-blue-200 text-blue-700 rounded px-3 py-2 font-medium"
          disabled={submitting}
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>
        <button
          className="ml-2 bg-blue-600 text-white rounded px-5 py-2 font-medium disabled:cursor-not-allowed"
          disabled={submitting || !canSave}
          onClick={onSave}
          type="button"
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default NewOverlay;
