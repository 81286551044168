/* ------ Module imports ------ */
import { ExclamationCircleIcon } from '@heroicons/react/solid';

/* ------ Components ------ */
import Input from 'components/input';
import Modal from 'components/modal';

function Invite(props) {
  const {
    email,
    error,
    onCancel,
    onEmailChanged,
    onSubmit,
    submissionError,
    submitting,
  } = props;

  function getSubmissionError() {
    if (submissionError === 'already_invited') {
      return 'This email addresses has already been invited';
    }

    if (submissionError === 'already_joined') {
      return 'This email addresses is already registered to this account';
    }

    return 'Could not invite user. Something went wrong.';
  }

  return (
    <Modal>
      <div className="px-6 py-4">
        <h3 className="font-black text-slate-900 text-lg mb-6">Invite a user</h3>

        <Input
          className="mr-2 flex-1"
          disabled={submitting}
          error={error}
          label="Email"
          onChange={onEmailChanged}
          type="email"
          value={email}
        />
      </div>

      <div className="border-t border-slate-200 px-6 py-4">
        {submissionError && (
          <div className="rounded-lg bg-red-200 px-4 py-3 mb-4 relative flex">
            <ExclamationCircleIcon className="h-5 text-red-600 flex-shrink-0" />
            <p className="ml-4 text-sm text-red-600 font-medium">
              {getSubmissionError()}
            </p>
          </div>
        )}

        <div className="flex justify-end">
          <button
            className="bg-slate-200 text-slate-700 flex items-center rounded px-3 py-2 text-sm font-medium"
            disabled={submitting}
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="ml-2 bg-blue-600 text-white flex items-center rounded px-3 py-2 text-sm font-medium"
            disabled={submitting}
            onClick={onSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default Invite;
