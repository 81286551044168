/* ------ Module imports ------ */
import { Link } from 'react-router-dom';

function AccountSelector(props) {
  const { view } = props;

  if (view === 'empty') {
    return (
      <div>
        <div className="px-12 py-10 border-b border-slate-200">
          <h1 className="font-black text-3xl text-slate-900">No accounts</h1>
        </div>

        <div className="px-12 py-10">
          <p className="text-slate-900 font-semibold">You don't have any accounts on My Action Sport</p>
          <p className="mt-4 text-slate-900">If you have been invited to join an account, click the link in the email you received.</p>
          <p className="mt-1 text-slate-900">If you'd like to start a new account, <Link className="underline text-blue-500" to="/account/new">click here to create an account.</Link></p>
        </div>
      </div>
    );
  }

  return null;
}

export default AccountSelector;
