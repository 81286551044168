/* ------ Module imports ------ */
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import NewDevice from './new-device.view';

function NewDeviceContainer() {
  const { account } = useContext(PlatformContext);

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [type, setType] = useState(null);
  const [rtspUrl, setRtspUrl] = useState('');
  const [submitting, setSubmitting] = useState(false);

  async function onSave() {
    if (!name.trim()) {
      return;
    }

    if (!type) {
      return;
    }

    if (type === 'rtsp') {
      if (!rtspUrl.trim()) {
        return;
      }

      if (!rtspUrl.trim().startsWith('rtsp://')) {
        return;
      }
    }

    setSubmitting(true);

    const postData = {
      account: account.id,
      rtsp_url: type === 'rtsp' ? rtspUrl : null,
      name,
      type,
    };

    let createdDevice = null;
    try {
      const { data } = (await api.post('/device', postData)).data;
      createdDevice = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (createdDevice) {
      navigate(`/device/${createdDevice.id}`);
    } else {
      // TODO - error
    }
  }

  return (
    <NewDevice
      name={name}
      onCancel={() => navigate('/devices')}
      onNameChanged={setName}
      onRtspUrlChanged={setRtspUrl}
      onSave={onSave}
      onTypeChanged={setType}
      rtspUrl={rtspUrl}
      submitting={submitting}
      type={type}
    />
  );
}

export default NewDeviceContainer;
