/* ------ Module imports ------ */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Remove from './remove.view';

function RemoveContainer(props) {
  const {
    onClose,
    team,
  } = props;

  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  async function onSubmit() {
    setError(false);
    setSubmitting(true);

    let success = false;
    try {
      await api.remove(`/team/${team.id}`);
      success = true;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (success) {
      navigate('/teams');
    } else {
      setError(true);
    }
  }

  return (
    <Remove
      error={error}
      onCancel={onClose}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default RemoveContainer;
