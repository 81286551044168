/* ------ Module imports ------ */
import { ChevronDownIcon } from '@heroicons/react/solid';

function Select(props) {
  const {
    children,
    className,
    disabled,
    label,
    onChange,
    value,
  } = props;

  return (
    <div className={className}>
      {label && <p className="text-slate-700 text-sm font-semibold mb-1">{label}</p>}
      <div className="relative">
        <select
          className="appearance-none w-full border border-slate-200 bg-slate-100 rounded-lg px-4 py-2"
          disabled={disabled}
          onChange={e => onChange(e.target.value)}
          value={value}
        >
          {children}
        </select>

        <div className="absolute top-0 right-0 bottom-0 pointer-events-none">
          <div className="h-full flex items-center px-4">
            <ChevronDownIcon className="h-4 text-gray-600" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Select;
