function Remove(props) {
  const {
    onCancel,
    onRemove,
  } = props;

  return (
    <div>
      <p className="text-sm text-slate-900 font-medium">Are you sure you want to delete this device?</p>
      <p className="text-sm text-slate-900">If there is already a game in progress that this device is streaming to, it will stop streaming</p>

      <div className="mt-6">
        <button
          className="bg-slate-200 hover:bg-slate-300 text-slate-900 rounded-lg px-2 py-1 inline-block font-semibold text-sm"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="ml-2 bg-red-100 hover:bg-red-200 text-red-600 rounded-lg px-2 py-1 inline-block font-semibold text-sm"
          onClick={onRemove}
        >
          Delete it
        </button>
      </div>
    </div>
  );
}

export default Remove;
