/* ------ Module imports ------ */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Team from './team.view';

function TeamContainer() {
  const { id } = useParams();

  const [team, setTeam] = useState(null);
  const [view, setView] = useState('loading');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(null);

  async function fetchData() {
    setView('loading');

    let loadedTeam = null;
    try {
      const { data } = (await api.get(`/team/${id}?expand=sport`)).data;
      loadedTeam = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedTeam) {
      setTeam(loadedTeam);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function onRenamed(name) {
    setTeam({ ...team, name });
    setModal(null);
  }

  function onRecoded(code) {
    setTeam({ ...team, code });
    setModal(null);
  }

  return (
    <Team
      dropdownOpen={dropdownOpen}
      modal={modal}
      onCloseDropdown={() => setDropdownOpen(false)}
      onCloseModal={() => setModal(null)}
      onDelete={() => setModal('remove')}
      onOpenDropdown={() => setDropdownOpen(true)}
      onRecode={() => setModal('recode')}
      onRename={() => setModal('rename')}
      onRenamed={onRenamed}
      onRecoded={onRecoded}
      team={team}
      view={view}
    />
  );
}

export default TeamContainer;
