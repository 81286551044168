/* ------ Local components ------ */
import Color from './color';
import Image from './image';
import ImageList from './image-list';
import Text from './text';

function CustomizableField(props) {
  const {
    disabled,
    field,
    onFieldUpdated,
  } = props;

  switch (field.type) {
    case 'color':
      return (
        <Color
          disabled={disabled}
          field={field}
          onUpdated={onFieldUpdated}
        />
      );
    case 'image':
      return (
        <Image
          disabled={disabled}
          field={field}
          onImageUploaded={img => onFieldUpdated(img.path)}
        />
      );
    case 'image_list':
      return (
        <ImageList
          disabled={disabled}
          field={field}
          onUpdated={onFieldUpdated}
        />
      );
    case 'text':
      return (
        <Text
          disabled={disabled}
          field={field}
          onUpdated={onFieldUpdated}
        />
      );
    default:
      return null;
  }
}

export default CustomizableField;
