export default function initials(string) {
  if (typeof string !== 'string') {
    string = string?.toString() || '';
  }
  if (string.length > 0) {
    if (string.includes(' ')) {
      let exploded = [...string.matchAll(new RegExp(/(\p{L}{1})\p{L}+/, 'gu'))] || [];
      exploded = (
        (exploded.shift()?.[1] || '') + (exploded.pop()?.[1] || '')
      ).toUpperCase();
      if (exploded.length < 2) {
        exploded = string.substring(0, Math.min(string.length, 2)).toUpperCase();
      }
      return exploded;
    }
    else {
      return string.substring(0, Math.min(string.length, 2)).toUpperCase();
    }
  }
  else {
    return '';
  }
}