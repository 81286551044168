/* ------ Module imports ------ */
import { useState } from 'react';

/* ------ View import ------ */
import YoutubeChannel from './youtube-channel.view';

function YoutubeChannelContainer(props) {
  const {
    channel,
    onRemoved,
  } = props;

  const [expanded, setExpanded] = useState(false);

  return (
    <YoutubeChannel
      channel={channel}
      expanded={expanded}
      onRemoved={onRemoved}
      onToggleExpanded={() => setExpanded(!expanded)}
    />
  );
}

export default YoutubeChannelContainer;
