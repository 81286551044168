/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, DotsHorizontalIcon } from '@heroicons/react/solid';

/* ------ Local components ------ */
import CustomizableField from './customizable-field';
import Dropdown from './dropdown';
import RemoveModal from './modals/remove';
import RenameModal from './modals/rename';

function Overlay(props) {
  const {
    dropdownOpen,
    modal,
    onCloseDropdown,
    onCloseModal,
    onDelete,
    onOpenDropdown,
    onRename,
    onRenamed,
    overlay,
    view,
  } = props;

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <div>
        <div className="px-12 py-10 border-b border-slate-200">
          <Link
            className="inline-block rounded border border-slate-200 bg-slate-100 rounded px-2 py-2"
            to="/overlays"
          >
            <div className="flex items-center">
              <ChevronLeftIcon className="h-4 text-slate-900" />
              <span className="text-sm text-slate-700 ml-2">Back to overlays</span>
            </div>
          </Link>

          <div className="mt-6 flex items-center justify-between">
            <h1 className="font-black text-3xl text-slate-900">{overlay.name}</h1>

            <div className="relative">
              <button
                className="flex items-center justify-center rounded-lg h-8 w-8 bg-blue-100 hover:bg-blue-200"
                onClick={onOpenDropdown}
              >
                <DotsHorizontalIcon className="h-4 text-blue-700" />
              </button>

              {dropdownOpen && (
                <div className="relative">
                  <Dropdown
                    onClose={onCloseDropdown}
                    onDelete={onDelete}
                    onRename={onRename}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="px-12 py-10 border-b border-slate-200">
          <h2 className="font-black text-xl text-slate-900">Details</h2>

          <div className="mt-6 flex">
            <div className="w-48">
              <p className="text-sm text-slate-700">Template</p>
              <Link
                className="text-sm font-semibold text-blue-600"
                to="/overlays"
              >
                {overlay.template.name}
              </Link>
            </div>

            <div>
              <p className="text-sm text-slate-700">Sport</p>
              <p className="text-sm font-medium text-slate-900">
                {overlay.template.sport.name}
              </p>
            </div>
          </div>
        </div>

        <div className="px-12 py-10">
          <h2 className="font-black text-xl text-slate-900">Customizations</h2>
          {Object.keys(overlay.template.customizable_fields).map(key => (
            <CustomizableField
              data={overlay.custom_data?.[key]}
              field={overlay.template.customizable_fields[key]}
              onRemove={() => {}}
              key={key}
            />
          ))}
        </div>
      </div>

      {modal === 'rename' && (
        <RenameModal
          onClose={onCloseModal}
          onRenamed={onRenamed}
          overlay={overlay}
        />
      )}

      {modal === 'remove' && (
        <RemoveModal
          onClose={onCloseModal}
          overlay={overlay}
        />
      )}
    </>
  );
}

export default Overlay;
