/* ------ Module imports ------ */
import React from 'react';

/* ------ Local components ------ */
import Image from './image';
import ImageList from './image-list';

function CustomizableField(props) {
  const {
    field,
  } = props;

  switch (field.type) {
    case 'image':
      return <Image {...props} />;
    case 'image_list':
      return <ImageList {...props} />;
    default:
      return null;
  }
}

export default CustomizableField;
