import { useEffect, useState } from 'react';

import prettyBytes from 'pretty-bytes';

import { getManifest } from 'views/game/utils/game-file-downloader';

export default function DataWarning() {
  const [manifest, setManifest] = useState();
  useEffect(() => {
    (async () => {
      setManifest((await getManifest())?.manifest);
    })();
  }, []);
  if ((manifest?.segments?.length || 0) > 0) {
    return (
      <span className='inline-block text-xs text-slate-400 leading-tight mt-2'>
        {`Est. data download required ~${prettyBytes((manifest.segments.reduce((a, s) => (a || 0) + ((s?.duration || 0) * 500), 0) || 0) * 1000)}.`}
      </span>
    );
  }
  else {
    return null;
  }
}