/* ------ Module imports ------ */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Device from './device.view';

function DeviceContainer() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [device, setDevice] = useState(null);
  const [error, setError] = useState(null);
  const [name, setName] = useState(null);
  const [view, setView] = useState('loading');
  const [removing, setRemoving] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [submitting, setSubmitting] = useState(false);


  async function changeDeviceName(e) {
    e.preventDefault();

    if (!name.trim()) {
      setError("Name can not be empty");
      return;
    }

    setError(null);

    setSubmitting(true);
    try {
      const { data } = (await api.patch(`/device/${id}`, { name })).data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);
    setRenaming(false);
  }

  async function fetchData() {
    setView('loading');

    let loadedDevice = null;
    try {
      const { data } = (await api.get(`/device/${id}`)).data;
      loadedDevice = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedDevice) {
      setDevice(loadedDevice);
      setView('main');
      setName(loadedDevice.name);
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Device
      device={device}
      onCancelRemoving={() => setRemoving(false)}
      onRemove={() => setRemoving(true)}
      onRemoved={() => navigate('/devices')}
      removing={removing}
      onRename={() => setRenaming(true)}
      renaming={renaming}
      deviceName={name}
      onNameChange={setName}
      onSubmit={changeDeviceName}
      submitting={submitting}
      view={view}
      error={error}
    />
  );
}

export default DeviceContainer;
