import React, { useCallback, useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { AdjustmentsIcon, CloudDownloadIcon, ExternalLinkIcon, EyeIcon, PencilAltIcon, ShareIcon } from '@heroicons/react/outline';
import Button from '@mui/material/Button';

import DownloadGameDialog from '../dialogs/download-game/download-game-dialog';
import RenameMatchTitleDialog from '../dialogs/rename-match-title-dialog';
import ChangeVideoVisibilityDialog from '../dialogs/change-video-visibility-dialog';
import ShareDialog from '../dialogs/share-dialog';

import { getGame, useGame } from '../utils/game-manager';
import { getCurrentTime } from '../utils/player-functions';

import dayjs from 'dayjs';

async function onAttemptNativeNavigatorShare(data) {
  let success = false;
  if ('share' in navigator && navigator.canShare(data)) {
    try {
      await navigator.share(data);
      success = true;
    } catch (err) {
      if (err?.name !== 'AbortError') {
        console.error(err);
      }
      else {
        success = true;
      }
    }
  }
  return success;
}

function getUrl(game, time) {
  let url;
  let params = [typeof time === 'number' && time >= 1 ? `t=${Math.max(Math.floor(time), 0)}` : ''];
  if (game?.visibility === 'unlisted' || game?.visibility === 'public') {
    url = 'https://watch.myactionsport.com';
  }
  else {
    url = 'https://player.myactionsport.com';
    params.push('autoplay=1');
  }
  if ((game?.id?.length || 0) > 0) {
    url += `/game/${game.id}`;
  }
  params = params.filter(p => (p?.length || 0) > 0);
  if (params.length > 0) {
    url += `?${params.join('&')}`
  }
  return url;
}

function getData(game) {
  return {
    title: game.title || 'My Action Sport',
    text: game.text || (game.status === 'live' ? 'LIVE now on My Action Sport' : (game['stream_started'] || game.created ? `Played ${dayjs.unix(game['stream_started'] || game.created).format('dddd Do MMMM, YYYY')}` : 'Watch on My Action Sport')),
    url: game.url || getUrl(game, getCurrentTime()),
  };
}

async function onShare(game) {
  if ((!await onAttemptNativeNavigatorShare(getData(game)))) {
    ShareDialog.open(game.title ? `Share "${game.title || game.highlight?.title}"` : undefined, getData(game), Math.max(Math.floor(getCurrentTime()), 0));
  }
}

export default function ActionsMenu() {
  const game = useGame();
  const [anchor, setAnchor] = useState();
  const onClose = useCallback(() => {
    if (anchor) {
      setAnchor(null);
    }
  }, [anchor]);
  const open = !!anchor;
  return (
    <>
      <Button
        id='context_menu_game_button'
        variant='outlined'
        startIcon={<AdjustmentsIcon className='h-5' />}
        color='inherit'
        aria-controls={open ? 'context-menu-game' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => setAnchor(e?.currentTarget)}>
        Actions
      </Button>
      <Menu
        id='context-menu-game'
        anchorEl={anchor}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'context_menu_game_button',
          className: 'max-w-[300px]'
        }}>
        <MenuItem
          component='a'
          disabled={game?.visibility === 'private' || (game?.id?.length || 0) === 0}
          href={`https://watch.myactionsport.com/game/${game?.id}`}
          target='_blank'
          rel='noopener noreferrer'
          onClick={onClose}>
          <ListItemIcon>
            <ExternalLinkIcon className='h-5' />
          </ListItemIcon>
          <ListItemText disableTypography={true} className='inline-flex flex-col justify-start items-start'>
            Open in Watch
            {
              (game?.visibility === 'private' || (game?.id?.length || 0) === 0) &&
              <span className='text-xs whitespace-normal'>This game is currently set to private or unavailable on Watch.</span>
            }
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          onClose();
          onShare(getGame());
        }}>
          <ListItemIcon>
            <ShareIcon className='h-5' />
          </ListItemIcon>
          <ListItemText disableTypography={true}>Share</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose();
            DownloadGameDialog.open();
          }}>
          <ListItemIcon>
            <CloudDownloadIcon className='h-5' />
          </ListItemIcon>
          <ListItemText disableTypography={true}>Download</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose();
            RenameMatchTitleDialog.open();
          }}>
          <ListItemIcon>
            <PencilAltIcon className='h-5' />
          </ListItemIcon>
          <ListItemText disableTypography={true}>Edit Video Title</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose();
            ChangeVideoVisibilityDialog.open();
          }}>
          <ListItemIcon>
            <EyeIcon className='h-5' />
          </ListItemIcon>
          <ListItemText disableTypography={true}>Change Visibility</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}