import { Component } from 'react';

import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import dayjs from 'dayjs';

import Setup from './setup';
import Download from './download';

import init, { cancel, download, getConvertedBlob, getProgress } from 'views/game/utils/game-file-downloader';
import Spinner from 'components/spinner/spinner';
import { getGameId, useGame } from 'views/game/utils/game-manager';

let instance;

function Title() {
  const game = useGame();
  return (
    <DialogTitle id='download-dialog-title' className='inline-flex flex-col text-lg font-semibold'>
      {'Download Video File'}
      {
        (game?.title?.length || 0) > 0 &&
        <>
          <span className={`font-normal text-slate-500 text-sm ${(game?.created || game?.['stream_started']) ? 'mt-2' : ''}`.trim()}>{game.title}</span>
          {
            (game?.created || game?.['stream_started']) &&
            <span className='font-normal text-slate-500 text-xs'>{dayjs.unix(game.created || game['stream_started']).format('dddd Do MMMM, YYYY')}</span>
          }
        </>
      }
    </DialogTitle>
  );
}

export default class DownloadGameDialog extends Component {
  static open() {
    return new Promise((resolve) => {
      if (instance && !instance?.state.open) {
        instance.setState({
          open: true,
        }, () => resolve(!!instance?.state?.open));
      }
      else {
        resolve(!!instance?.state?.open);
      }
    });
  }
  static close() {
    return new Promise((resolve) => {
      if (instance && instance?.state.open) {
        instance.setState({
          open: false,
        }, () => resolve(!instance?.state?.open));
      }
      else {
        resolve(!instance?.state?.open);
      }
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      open: false,
      view: typeof getProgress()?.conversion === 'number' || typeof getProgress()?.download === 'number' || getConvertedBlob() ? 'download' : 'setup',
    };
    instance = this;
  }
  componentDidMount() {
    instance = this;
  }
  componentWillUnmount() {
    instance = instance === this ? null : instance;
  }
  componentDidUpdate(_prevProps, prevState) {
    if (!prevState.open && this.state.open) {
      this.setState({
        loading: true,
      }, async () => {
        await init();
        this.setState({ loading: false });
      })
    }
  }
  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={this.state.open && (getGameId()?.length || 0) > 0}
        onClose={DownloadGameDialog.close}
        aria-labelledby='download-dialog-title'>
        <Title />
        <DialogContent
          dividers={true}
          className='p-4'>
          {
            this.state.loading &&
            <div className='flex flex-col justify-center items-center min-h-[150px] gap-2'>
              <Spinner className='text-blue-500' />
              Preparing...
            </div>
          }
          {
            !this.state.loading &&
            <>
              {
                this.state.view === 'setup' &&
                <Setup
                  onStart={() => {
                    this.setState({ view: 'download' });
                    download();
                  }} />
              }
              {
                this.state.view === 'download' &&
                <Download
                  onCancel={() => {
                    cancel();
                    this.setState({ view: 'setup' });
                  }} />
              }
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            color='inherit'
            onClick={DownloadGameDialog.close}>
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}