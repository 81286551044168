function ImageList(props) {
  const {
    data,
    field,
  } = props;

  return (
    <div className="mt-10">
      <p className="text-slate-900">{field.name}</p>
      <p className="text-slate-600 text-xs">Please upload a jpgs or pngs, no larger than 1mb each</p>

      <div className="mt-4 flex">
        {data && data.map((image, i) => (
          <div
            className="mr-4 inline-block w-20 h-20 flex items-center justify-center rounded-lg bg-gray-100 border border-dashed border-slate-400 bg-contain bg-center bg-no-repeat hover:border-blue-500"
            key={i}
            style={{ backgroundImage: image ? `url(${image})` : undefined }}
          />
        ))}
      </div>
    </div>
  );
}

export default ImageList;
