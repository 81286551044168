import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';

import sha256 from 'crypto-js/sha256';

import pLimit from 'p-limit';

import createMP4 from './highlight-to-mp4';
import createSupercut from './supercut';
import storage from 'utils/storage';
import createZip from './archive';
import { getGameId } from './game-manager';

let files;
let listeners = [];

const STORAGE_KEY = 'file-manager';
const RATE_LIMITER = pLimit(5);

dayjs.extend(AdvancedFormat);

export function useFiles() {
  const [invalidating, setInvalidating] = useState(false);
  useEffect(() => {
    const onUpdate = () => {
      setInvalidating(true);
      requestAnimationFrame(() => setInvalidating(false));
    };
    return registerListener(onUpdate);
  }, []);
  return !invalidating ? getFiles() : undefined;
}

export default async function init() {
  await invalidateStorage();
  files = [];
  if ((getGameId()?.length || 0) > 0) {
    files = (await storage.get(STORAGE_KEY) || {})[getGameId()] || [];
  }
  notifyListeners();
  return getFiles();
}

/**
 * Relieves disk pressure, these files can get big and constraining
 */
async function invalidateStorage() {
  const now = dayjs();
  const stored = await storage.get(STORAGE_KEY) || {};
  for (const key of Object.keys(stored)) {
    let gameFiles = stored[key];
    if (Array.isArray(gameFiles) && gameFiles.length > 0) {
      gameFiles = gameFiles.filter(f => {
        let expires;
        try { expires = dayjs(f.created).add(1, 'months'); } catch (err) { }
        return dayjs.isDayjs(expires) && now.isBefore(expires) && (f.blob?.size || 0) > 0;
      });
    }
    if (!Array.isArray(gameFiles) || gameFiles.length === 0) {
      delete stored[key];
    }
    else {
      stored[key] = gameFiles;
    }
  }
  await storage.set(STORAGE_KEY, stored);
  try {
    const quota = await navigator.storage.estimate();
    if (((quota.usage / quota.quota) * 100) > 0.9) {
      await storage.remove(STORAGE_KEY);
    }
  } catch (err) { } //Good luck safari users
  notifyListeners();
}

export function getFiles() {
  try { return files.slice(); } catch (err) { return []; }
}

export async function download(highlights) {
  let result;
  if (!Array.isArray(highlights) && typeof highlights === 'object' && (highlights?.id?.length || 0) > 0) {
    highlights = [highlights];
  }
  highlights = (Array.isArray(highlights) ? highlights : []).filter(h => (h?.id?.length || 0) > 0);
  if ((highlights?.length || 0) > 0) {
    const asFiles = [];
    for (const highlight of highlights) {
      asFiles.push({
        id: highlight.id,
        title: highlight.title,
        filename: assignUniqueFilename(highlight, asFiles),
        blob: null,
        created: dayjs(),
        duration: null,
        highlight: highlight,
      });
    }
    if ((asFiles?.length || 0) === 1) {
      result = await downloadOne(asFiles[0]);
    }
    else if ((asFiles?.length || 0) > 1) {
      result = await downloadMany(asFiles);
    }
  }
  return result;
}

function assignUniqueFilename(highlight, all) {
  let proposed;
  if ((highlight?.title?.length || 0) > 0) {
    try { proposed = `${encodeURIComponent(((highlight?.['timestamp_start'] || dayjs().unix()) + '-' + highlight.type + '-' + highlight.title).trim().replace(/\s+/g, '-').replace(/_+/g, '-').replace(/--+/g, '-').toLowerCase())}`.trim(); } catch (err) { }
  }
  if ((proposed?.length || 0) === 0 || proposed === '.mp4') {
    proposed = `${highlight?.id}-${dayjs().unix()}`.trim();
  }
  let copy = 0;
  all = (all || []).map(h => h?.filename?.replace(/\.[^/.]+$/, ''))?.filter(f => (f?.length || 0) > 0) || []; //Removes any file extensions
  while (all.find((e) => e === proposed)) {
    proposed = `${proposed.replace(/\s\(\d+\)$/, '')} (${++copy})`.trim(); //Appends a count
  }
  return `${proposed}.mp4`;
}

async function downloadOne(file) {
  let existing = files.find(f => f?.highlight?.id === file?.highlight.id);
  if (!existing) {
    const conversion = await createMP4(file?.highlight);
    if (conversion.blob) {
      file.duration = conversion.metadata?.duration;
      file.blob = conversion.blob;
      await add(file);
    }
  }
  else {
    file.duration = existing.duration;
    file.blob = existing.blob;
  }
  return file;
}

async function downloadMany(requested) {
  requested = [].concat(requested || []);
  const concatenatedFileIds = sha256(requested.map(h => h.id).join(''));
  let archive = files.find(f => f?.id === concatenatedFileIds + '-archive');
  if (!archive) {
    const deduplicated = [];
    for (const file of requested) {
      if ((file?.id?.length || 0) > 0 && (file?.highlight?.id?.length || 0) > 0 && !deduplicated.find(f => f?.highlight?.id === file?.highlight.id)) {
        deduplicated.push(file);
      }
    }
    let promises = [];
    for (const file of deduplicated) {
      promises.push(RATE_LIMITER(() => downloadOne(file))); //Only download and convert the actual source file once
    }
    await Promise.allSettled(promises);
    promises = [];
    for (const file of requested) {
      if ((file?.id?.length || 0) > 0 && !file.blob) {
        promises.push(RATE_LIMITER(() => downloadOne(file))); //It will now pull from cache or download it in an emergency
      }
    }
    await Promise.allSettled(promises);
    let supercut = files.find(f => f?.id === concatenatedFileIds + '-supercut');
    if (!supercut) {
      try { supercut = await createSupercut(requested); } catch (err) { console.error(err) }
      if (supercut?.blob) {
        supercut = await add({
          id: concatenatedFileIds + '-supercut',
          title: `${dayjs().format('dddd MMMM Do YYYY')} Supercut`,
          filename: `${dayjs().unix()}-supercut.mp4`,
          created: supercut.metadata?.created || dayjs().toISOString(),
          duration: supercut.metadata?.duration,
          blob: supercut.blob
        });
      }
    }
    if (supercut) {
      requested.push(supercut);
    }
    if ((requested?.length || 0) > 0) {
      archive = await add({
        id: concatenatedFileIds + '-archive',
        title: `${dayjs().format('dddd MMMM Do YYYY')} Highlights`,
        filename: `${dayjs().format('YYYY-MM-DD')}.zip`,
        created: dayjs().toISOString(),
        blob: await createZip(requested)
      });
    }
  }
  return archive;
}

async function add(file) {
  files.push(file);
  const stored = await storage.get(STORAGE_KEY) || {};
  stored[getGameId()] = files;
  await storage.set(STORAGE_KEY, stored);
  await invalidateStorage();
  return file;
}

export async function remove(fileOrId) {
  if (typeof fileOrId === 'object') {
    fileOrId = fileOrId?.id;
  }
  if (typeof fileOrId === 'string') {
    files = files.filter(f => f?.id !== fileOrId);
    const stored = await storage.get(STORAGE_KEY) || {};
    stored[getGameId()] = files;
    await storage.set(STORAGE_KEY, stored);
    notifyListeners();
  }
}

export async function clear() {
  files = [];
  const stored = await storage.get(STORAGE_KEY) || {};
  stored[getGameId()] = files;
  await storage.set(STORAGE_KEY, stored);
  notifyListeners();
}

export function open(fileOrBlob, filename = fileOrBlob?.filename) {
  if (!(fileOrBlob instanceof Blob)) {
    fileOrBlob = fileOrBlob?.blob;
  }
  if (fileOrBlob instanceof Blob) {
    const dummyATag = document.createElement('a');
    document.body.appendChild(dummyATag);
    const url = URL.createObjectURL(fileOrBlob);
    dummyATag.href = url;
    dummyATag.download = filename || 'video.mp4';
    dummyATag.click();
    requestAnimationFrame(() => {
      URL.revokeObjectURL(url);
      document.body.removeChild(dummyATag);
    });
  }
}

function notifyListeners() {
  for (const listener of listeners) {
    requestAnimationFrame(function () { try { listener(); } catch (err) { } });
  }
}

export function registerListener(listener) {
  listeners.push(listener);
  requestAnimationFrame(function () { try { listener(); } catch (err) { } });
  return () => deregisterListener(listener);
}

export function deregisterListener(listener) {
  listeners = listeners.filter(l => l !== listener);
}