import React, { useEffect, useState } from 'react';

/* ------ Local components ------ */
import Highlights from './highlights';
import Workspace from './workspace';
import Downloads from './downloads/downloads';
import Collections from './collections';
import initCommunicator from './utils/communicator';
import Title from './title/title';

import { useGameId, useGameTitle } from './utils/game-manager';

const iframe = React.createRef();
let listeners = [];

export function getVideo() {
  return iframe.current || (typeof window !== 'undefined' ? document.getElementById('video-host') : undefined);
}

export function useVideo() {
  const [element, setElement] = useState(getVideo());
  useEffect(() => {
    const onUpdate = () => {
      if (element !== getVideo()) {
        setElement(getVideo());
      }
    };
    onUpdate();
    return registerListener(onUpdate);
  }, [element]);
  return element;
}

export function registerListener(listener) {
  if (typeof listener === 'function') {
    listeners.push(listener);
    requestAnimationFrame(() => { try { listener(getVideo()); } catch (err) { } });
  }
  return () => deregisterListener(listener);
}

export function deregisterListener(listener) {
  listeners = listeners.filter(l => l !== listener);
}

function notifyListeners() {
  for (const listener of listeners) {
    requestAnimationFrame(() => { try { listener(getVideo()); } catch (err) { } });
  }
}

export default function Game() {
  const id = useGameId();
  const title = useGameTitle();
  return (
      <div className='px-12 py-10 flex flex-wrap justify-start items-stretch gap-8 max-w-[1920px]'>
        <div className='flex w-full justify-start items-start gap-8'>
          <div className='flex flex-col justify-start items-start flex-1 max-h-[1080px]'>
            <div className='w-full aspect-w-16 aspect-h-9'>
              <iframe
                id='video-host'
                ref={(ref) => {
                  iframe.current = ref;
                  initCommunicator();
                  notifyListeners();
                }}
                allow='autoplay; picture-in-picture; fullscreen'
                className='w-full h-full rounded'
                src={`https://player.myactionsport.com/game/${id}?autoplay=1&no_flashy_end_screen=1`}
                title={title} />
            </div>
            <Title />
          </div>
          <Highlights className='hidden xl:flex max-w-xs flex-[1_0_0]' />
        </div>
        <div className='w-full flex justify-start items-stretch flex-col gap-8'>
          <div className='flex flex-col md:flex-row w-full justify-start items-stretch gap-8'>
            <Highlights className='xl:hidden flex-[1_0_0] min-h-[max(25vh,_250px)]' />
            <Workspace className='flex-[1_1_0] min-h-[max(25vh,_250px)]' />
          </div>
          <div className='flex flex-col md:flex-row w-full justify-start items-stretch gap-8'>
            <Downloads className='flex-[1_1_0] xl:flex-[2_1_0] min-h-[max(25vh,_250px)]' />
            <Collections className='flex-[1_1_0] min-h-[max(25vh,_250px)]' />
          </div>
        </div>
      </div>
  );
}