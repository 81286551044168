import dayjs from 'dayjs';
import { Parser } from 'm3u8-parser';

import { create as createFFmpeg, destroy as destroyFFmpeg } from './ffmpeg.js';
import { fetchFile } from '@ffmpeg/ffmpeg';

import pLimit from 'p-limit';
import { getGameId } from './game-manager.js';

const RATE_LIMITER = pLimit(20);

async function getManifest(highlight) {
  if (typeof highlight === 'object') {
    highlight = highlight?.id;
  }
  let m3u8 = (await (await fetch(`https://playlists.myactionsport.com/highlight/${highlight}.m3u8`)).text()).split('\n');
  for (let i = 0; i < m3u8.length; i++) {
    const line = m3u8[i];
    if (line.startsWith('http')) {
      m3u8[i] = line.substring(line.lastIndexOf('/') + 1);
    }
  }
  m3u8 = m3u8.join('\n');
  const parser = new Parser();
  parser.push(m3u8);
  parser.end();
  return { m3u8, manifest: parser.manifest };
}

export default async function convert(highlight) {
  if ((highlight?.id?.length || 0) > 0 && (getGameId()?.length || 0) > 0) {
    const target = highlight.filename || `${dayjs().unix()}.mp4`;
    let promises = await Promise.allSettled([createFFmpeg(), getManifest(highlight)]);
    const ffmpeg = promises[0].value;
    const { m3u8, manifest } = promises[1].value;
    const ts = manifest.segments.map(s => s?.uri || '');
    promises = await Promise.allSettled(ts.map(t => RATE_LIMITER(() => fetchFile(`https://streams.ralli.co.nz/${getGameId()}/output/${t}`))));
    for (let i = 0; i < ts.length; i++) {
      ffmpeg.FS('writeFile', ts[i], promises[i].value);
    }
    ffmpeg.FS('writeFile', `${highlight.id}.m3u8`, m3u8);
    await ffmpeg.run(
      '-y',
      '-hide_banner',
      '-i', `${highlight.id}.m3u8`,
      '-c:v', 'copy',
      '-c:a', 'copy',
      target
    );
    const data = ffmpeg.FS('readFile', target);
    destroyFFmpeg(ffmpeg);
    return {
      blob: new Blob([data.buffer], { type: 'video/mp4' }),
      metadata: {
        type: 'video/mp4',
        created: dayjs().toISOString(),
        duration: manifest.segments.map(s => s.duration || 0).reduce((a, v) => a + v, 0),
      }
    };
  }
  else {
    throw new Error('Invalid highlight provided, please check and try again.');
  }
}