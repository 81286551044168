import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { toast } from 'react-toastify';

import { DownloadIcon, PlusIcon } from '@heroicons/react/solid';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import CircularProgress from '@mui/material/CircularProgress';

import capitalize from 'utils/capitalize';

import { download, open } from '../utils/file-manager';
import ContextMenuButton from './context-menu/context-menu';
import { add } from '../utils/workspace-manager';
import { Row, RowButton } from '../accessory-container';

import { useDrag } from 'react-dnd';
import { ItemTypes } from '../game.container';

import { onPlayHighlight, registerListener, useCurrentTime, getCurrentTime } from '../utils/player-functions';
import formatDuration from '../utils/timestamp-formatter';
import { useStarted } from '../utils/game-manager';

function useIsPlaying(highlight) {
  const epoch = useStarted(true);
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    return registerListener(() => {
      const time = getCurrentTime();
      const start = highlight?.['timestamp_start'];
      const end = highlight?.['timestamp_end'];
      if (
        typeof time === 'number' && !isNaN(time) && isFinite(time) && time >= 0 &&
        typeof epoch === 'number' && !isNaN(epoch) && isFinite(epoch) && epoch >= 0 &&
        typeof start === 'number' && !isNaN(start) && isFinite(start) && start >= epoch &&
        typeof end === 'number' && !isNaN(end) && isFinite(end) && end >= start
      ) {
        const min = Math.ceil(start - epoch);
        const max = Math.floor(end - epoch);
        if (time >= min && time < max && !playing) {
          setPlaying(true);
        }
        else if ((time < min || time >= max) && playing) {
          setPlaying(false);
        }
      }
      else if (playing) {
        setPlaying(false);
      }
    });
  }, [epoch, highlight, playing]);
  return playing;
}

export function Timestamp(props) {
  const formattedStarted = props.started ? formatDuration(props.started) : undefined;
  let formattedDuration = dayjs.duration(Math.max(props.duration || 0, 0), 'seconds');
  if ((formattedStarted?.length || 0) > 0 || formattedDuration.asSeconds() > 0) {
    return (
      <p className='inline whitespace-pre text-xs text-slate-500'>
        {` ${formattedStarted || ''}${formattedDuration.asSeconds() > 0 ? (formattedStarted ? ' +' : '') + (formattedDuration.asSeconds() < 60 ? formattedDuration.asSeconds() + 's' : formattedDuration.asMinutes() + 'm') : ''}`}
      </p>
    );
  }
  else {
    return null;
  }
}

function PlayIcon(props) {
  const time = useCurrentTime();
  const epoch = useStarted(true);
  const start = props.highlight?.['timestamp_start'];
  const end = props.highlight?.['timestamp_end'];
  let percentage;
  try {
    const min = Math.ceil(start - epoch);
    const max = Math.floor(end - epoch);
    percentage = ((time - min) / (max - min)) * 100;
  } catch (err) { }
  return (
    <div className='relative w-[12px] h-[12px] mr-2'>
      {
        typeof percentage === 'number' && percentage >= 0 && percentage <= 100 &&
        <CircularProgress size='12px' variant='determinate' value={percentage} className='absolute inset-0' />
      }
      <div className='flex justify-center items-center absolute inset-0 w-full h-full'>
        <PlayArrowIcon className='w-[10px] text-blue-600' />
      </div>
    </div>
  );
}

function Subtitle(props) {
  let values = [];
  const tag = props.tags?.[0]?.trim();
  if ((tag?.length || 0) > 0) {
    if (props.type?.replace('-', ' ').replace('_', ' ').toLowerCase() !== props.title?.toLowerCase()) {
      values.push(props.title);
    }
    values.push(tag);
  }
  else {
    values.push(props.title);
  }
  values = values.map(v => v?.trim()).filter(v => (v?.length || 0) > 0).join(', ');
  if ((values?.length || 0) > 0) {
    return (
      <p className='text-xs text-slate-400 leading-snug line-clamp-1'>
        {values}
      </p>
    );
  }
  else {
    return null;
  }
}

export default function Highlight(props) {
  const {
    id,
    highlight,
  } = props;
  const started = useStarted();
  const isPlaying = useIsPlaying(highlight);
  const [downloading, setDownloading] = useState(false);
  console.log(props);
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.HIGHLIGHT_ITEM,
    item: () => highlight,
    isDragging: (monitor) => id === monitor.getItem().id,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });
  return (
    <Row
      ref={drag}
      dragging={isDragging}
      title={highlight.title}
      className={`cursor-move ${isPlaying ? 'bg-blue-100' : ''}`}
      buttons={
        <>
          <RowButton
            tip='Download highlight as MP4'
            title='Download'
            loading={downloading}
            icon={<DownloadIcon />}
            onClick={async () => {
              setDownloading(true);
              try {
                open(await download(highlight));
              } catch (err) {
                toast.error('Something went wrong, please reload and try again or contact support for help.');
              }
              setDownloading(false);
            }}
            className='ml-1' />
          <RowButton
            tip='Add highlight to workspace'
            title='Add to workspace'
            onClick={() => add(highlight)}
            icon={<PlusIcon />}
            className='-mx-2' />
          <ContextMenuButton {...props} />
        </>
      }>
      <button
        title='Jump to highlight in live feed'
        onClick={(e) => {
          try { e?.currentTarget?.scrollIntoView({ block: 'center', behavior: 'smooth' }); } catch (err) { }
          onPlayHighlight(highlight);
        }}
        className='w-full text-left'>
        <span className='inline-flex justify-start items-center text-sm font-semibold leading-none'>
          {
            isPlaying &&
            <PlayIcon highlight={highlight} />
          }
          {capitalize(highlight?.type?.replace('-', ' ').replace('_', ' ') || 'Highlight')}
          <Timestamp
            started={(highlight?.['timestamp_start'] || 0) > (started || 0) ? highlight['timestamp_start'] - started : undefined}
            duration={typeof highlight?.['timestamp_start'] === 'number' && typeof highlight?.['timestamp_end'] === 'number' ? highlight['timestamp_end'] - highlight['timestamp_start'] : undefined} />
        </span>
        <Subtitle {...highlight} />
      </button>
    </Row>
  );
}