/* ------ Module imports ------ */
import { useEffect, useState } from 'react';

function Popover(props) {
  const {
    children,
    className,
    onClose,
    side,
  } = props;

  const [closing, setClosing] = useState(false);

  function popoverListener() {
    setClosing(true);
    setTimeout(() => {
      setClosing(false);
      onClose();
    }, 300);

    window.removeEventListener('click', popoverListener);
  }

  useEffect(() => {
    setTimeout(() => window.addEventListener('click', popoverListener));
    return () => window.removeEventListener('click', popoverListener);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let animationClass = 'animate-popopen';
  if (closing) {
    animationClass += ' animate-popclose';
  }

  return (
    <div
      className={`border border-slate-200 shadow-xl rounded-lg absolute w-48 top-0 ${side === 'left' ? 'left-0' : 'right-0'} mt-3 z-20 ${animationClass} ${className}`}
      onClick={e => e.stopPropagation()}
    >
      <div className={`border border-slate-200 w-4 h-4 bg-white rotate-45 rounded-tl absolute top-0 -mt-1 ${side === 'left' ? 'left-0 ml-3' : 'right-0 mr-3'}`} />
      <div className="relative z-10 rounded-lg bg-white overflow-hidden">
        {children}
      </div>
    </div>
  );
}

export default Popover;
