import { useState } from 'react';

import { PlusIcon } from '@heroicons/react/outline';

import { useHighlights as useUnfilteredHighlights } from '../../utils/highlights-manager';

import Type from './type';
import Time from './time';
import Tags from './tags';
import { useFilters } from 'views/game/utils/highlights-filter-manager';

import dayjs from 'dayjs';

function Toggle(props) {
  return (
    <button
      {...props}
      className={`flex flex-row justify-center items-center rounded-full bg-gray-50 border border-gray-300 text-slate-600 font-normal text-xs py-1 px-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hover:bg-gray-100 hover:dark:bg-gray-500 ${props.className || ''}`.trim()}>
      {props.children}
      <PlusIcon className='ml-2 h-3' />
    </button>
  );
}

export default function Filters() {
  const filters = useFilters();
  const [typeOpen, setTypeOpen] = useState((filters?.type?.length || 0) > 0);
  const [timeOpen, setTimeOpen] = useState(dayjs.isDayjs(filters?.time?.start) || dayjs.isDayjs(filters?.time?.end));
  const [tagsOpen, setTagsOpen] = useState((filters?.tags?.length || 0) > 0);
  const highlights = useUnfilteredHighlights();
  if ((highlights?.length || 0) > 0) {
    return (
      <div className='flex flex-col gap-2 w-full justify-start items-start mt-2'>
        {
          typeOpen &&
          <Type onClose={() => setTypeOpen(false)} />
        }
        {
          timeOpen &&
          <Time onClose={() => setTimeOpen(false)} />
        }
        {
          tagsOpen &&
          <Tags onClose={() => setTagsOpen(false)} />
        }
        {
          (!typeOpen || !timeOpen || !tagsOpen) &&
          <div className='flex flex-wrap flex-row gap-2 justify-start items-center'>
            {
              !typeOpen &&
              <Toggle onClick={() => setTypeOpen(true)}>
                Types
              </Toggle>
            }
            {
              !timeOpen &&
              <Toggle onClick={() => setTimeOpen(true)}>
                Time Range
              </Toggle>
            }
            {
              !tagsOpen &&
              <Toggle onClick={() => setTagsOpen(true)}>
                Tags
              </Toggle>
            }
          </div>
        }
      </div>
    );
  }
  else {
    return null;
  }
}