/* ------ Components ------ */
import Input from 'components/input';

function NewDevice(props) {
  const {
    name,
    onCancel,
    onNameChanged,
    onRtspUrlChanged,
    onSave,
    onTypeChanged,
    rtspUrl,
    submitting,
    type,
  } = props;

  return (
    <>
      <div className="px-12 py-10 border-b border-slate-200">
        <h1 className="font-black text-3xl text-slate-900">New device</h1>
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <h2 className="font-black text-xl text-slate-900">Details</h2>

        <div className="mt-10">
          <p className="text-sm text-slate-700 mb-1">Name</p>
          <Input
            className="w-full max-w-sm"
            disabled={submitting}
            onChange={onNameChanged}
            value={name}
          />
        </div>

        <p className="mt-10 text-sm text-slate-700 mb-1">What type of device are you adding?</p>
        <button
          className={`text-left mt-4 w-full max-w-sm flex items-center border ${type === 'rtmp' ? 'border-blue-500' : 'border-slate-400'} rounded-lg px-4 py-2 bg-white hover:bg-slate-100`}
          onClick={() => onTypeChanged('rtmp')}
        >
          <div className={`w-4 h-4 border ${type === 'rtmp' ? 'border-blue-500' : 'border-slate-400'} rounded-full flex items-center justify-center`}>
            {type === 'rtmp' && <div className="w-2 h-2 rounded-full bg-blue-500" />}
          </div>
          <div className="ml-4">
            <p className="text-sm text-slate-900">RTMP</p>
            <p className="text-sm text-slate-600">eg. streaming from OBS or VMix</p>
          </div>
        </button>
        <button
          className={`text-left mt-4 w-full max-w-sm flex items-center border ${type === 'rtsp' ? 'border-blue-500' : 'border-slate-400'} rounded-lg px-4 py-2 bg-white hover:bg-slate-100`}
          onClick={() => onTypeChanged('rtsp')}
        >
          <div className={`w-4 h-4 border ${type === 'rtsp' ? 'border-blue-500' : 'border-slate-400'} rounded-full flex items-center justify-center`}>
            {type === 'rtsp' && <div className="w-2 h-2 rounded-full bg-blue-500" />}
          </div>
          <div className="ml-4">
            <p className="text-sm text-slate-900">RTSP</p>
            <p className="text-sm text-slate-600">eg. streaming from security/IP cameras</p>
          </div>
        </button>
      </div>

      {type === 'rtsp' && (
        <div className="px-12 py-10 border-b border-slate-200">
          <h2 className="font-black text-xl text-slate-900">RTSP settings</h2>

          <div className="mt-10">
            <p className="text-sm text-slate-700 mb-1">RTSP url</p>
            <Input
              className="w-full max-w-sm"
              disabled={submitting}
              onChange={onRtspUrlChanged}
              value={rtspUrl}
            />
          </div>
        </div>
      )}

      <div className="px-12 py-10">
        <button
          className="bg-blue-200 text-blue-700 rounded px-3 py-2 font-medium"
          disabled={submitting}
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>
        <button
          className="ml-2 bg-blue-600 text-white rounded px-5 py-2 font-medium disabled:cursor-not-allowed"
          disabled={submitting}
          onClick={onSave}
          type="button"
        >
          Save
        </button>
      </div>
    </>
  );
}

export default NewDevice;
