import { useState } from 'react';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import Time from './time';
import VideoCodec from './video-codec';
import AudioCodec from './audio-codec';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color='inherit' className='text-slate-600 text-xs' />}
    {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  paddingLeft: 0,
  paddingRight: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    borderBottom: 'none',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export default function Settings() {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      disableGutters={true}
      elevation={0}
      square={true}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      className={`w-full !mt-6 ${!expanded ? '-mb-2' : ''}`.trim()}>
      <AccordionSummary
        aria-controls='settings-content'
        id='settings-header'
        className='text-slate-600 text-sm'>
        Advanced Download Settings
      </AccordionSummary>
      <AccordionDetails id='settings-content'>
        <div className='flex flex-col w-full justify-center items-center gap-4'>
          <Time />
          <div className='flex flex-row w-full justify-start items-start gap-4'>
            <VideoCodec />
            <AudioCodec />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}