/* ------ Module imports ------ */
import { useContext, useEffect, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Livestreams from './livestreams.view';

function LivestreamsContainer() {
  const { account } = useContext(PlatformContext);

  const [live, setLive] = useState(null);
  const [livePage, setLivePage] = useState(1);
  const [liveTotalPages, setLiveTotalPages] = useState(null);

  const [games, setGames] = useState(null);
  const [gamesPage, setGamesPage] = useState(1);
  const [gamesTotalPages, setGamesTotalPages] = useState(null);

  const [view, setView] = useState('loading');

  async function fetchLiveGames() {
    const { data } = (await api.get(`/game?account=${account.id}&live=true&page=${livePage}`)).data;
    return data;
  }

  async function fetchGames() {
    const { data } = (await api.get(`/game?account=${account.id}&live=false&page=${gamesPage}`)).data;
    return data;
  }

  async function fetchData() {
    let loadedLiveGames = null;
    let loadedGames = null;
    try {
      [loadedLiveGames, loadedGames] = await Promise.all([
        fetchLiveGames(),
        fetchGames(),
      ]);
    } catch (e) {
      // Silently ignore
    }

    if (loadedLiveGames && loadedGames) {
      setLive(loadedLiveGames.results);
      setLiveTotalPages(loadedLiveGames.total_pages);

      setGames(loadedGames.results);
      setGamesTotalPages(loadedGames.total_pages);

      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateGames() {
    let data = null;
    try {
      data = await fetchGames();
    } catch (e) {
      // Silently ignore
    }

    if (data) {
      setGames(data.results);
      setGamesTotalPages(data.total_pages);
    } else {
      // TODO - error
    }
  }

  async function updateLive() {
    let data = null;
    try {
      data = await fetchLiveGames();
    } catch (e) {
      // Silently ignore
    }

    if (data) {
      setLive(data.results);
      setLiveTotalPages(data.total_pages);
    } else {
      // TODO - error
    }
  }

  useEffect(() => {
    updateGames();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamesPage]);

  useEffect(() => {
    updateLive();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livePage]);

  return (
    <Livestreams
      games={games}
      gamesPage={gamesPage}
      gamesTotalPages={gamesTotalPages}
      live={live}
      livePage={livePage}
      liveTotalPages={liveTotalPages}
      onChangeGamesPage={setGamesPage}
      onChangeLivePage={setLivePage}
      view={view}
    />
  );
}

export default LivestreamsContainer;
