/* ------ Components ------ */
import Select from 'components/select';

function TemplateSelector(props) {
  const {
    disabled,
    onSelected,
    template,
    templates,
    view,
  } = props;

  if (view === 'hidden') {
    return null;
  }

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <div className="mt-6">
      <p className="text-sm text-slate-700 mb-1">Select an overlay to customize</p>
      <Select
        className="w-full max-w-sm"
        disabled={disabled}
        onChange={onSelected}
        value={template}
      >
        <option disabled value="">Select an overlay...</option>
        {templates.map(t => (
          <option
            key={t.id}
            value={t.id}
          >
            {t.name}
          </option>
        ))}
      </Select>
    </div>
  );
}

export default TemplateSelector;
