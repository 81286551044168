/* ------ Module imports ------ */
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import NewAccount from './new-account.view';

function NewAccountContainer() {
  const { onAccountSelected } = useContext(PlatformContext);

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [type, setType] = useState('club');
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit() {
    setSubmitting(true);

    const postData = {
      name,
      type,
    };

    let createdAccount = null;
    try {
      const { data } = (await api.post('/account', postData)).data;
      createdAccount = data;
    } catch (e) {
      // Silently ignore
    }

    if (!createdAccount) {
      // TODO - error
      setSubmitting(false);
      return;
    }

    try {
      await api.patch('/user/me', { last_used_account: createdAccount.id });
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    onAccountSelected(createdAccount);
    navigate('/');
  }

  return (
    <NewAccount
      name={name}
      onNameChanged={setName}
      onSubmit={onSubmit}
      onTypeChanged={setType}
      submitting={submitting}
      type={type}
    />
  );
}

export default NewAccountContainer;
