/* ------ Module imports ------ */
import { useContext, useEffect, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import AccountSelector from './account-selector.view';

function AccountSelectorContainer() {
  const { onAccountSelected } = useContext(PlatformContext);

  const [view, setView] = useState('loading');

  async function onLoad() {
    let loadedAccounts = null;
    try {
      const { data } = (await api.get('/account')).data;
      loadedAccounts = data;
    } catch (e) {
      // Silently ignore
    }

    if (!loadedAccounts) {
      setView('error');
      return;
    }

    if (loadedAccounts.length === 0) {
      setView('empty');
      return;
    }

    let updatedUser = null;
    try {
      const { data } = (await api.patch('/user/me', { last_used_account: loadedAccounts[0].id })).data;
      updatedUser = data;
    } catch (e) {
      // Silently ignore
    }

    if (updatedUser) {
      onAccountSelected(loadedAccounts[0]);
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccountSelector view={view} />
  );
}

export default AccountSelectorContainer;
