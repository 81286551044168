/* ------ Module imports ------ */
import { useEffect, useRef } from 'react';

function Modal(props) {
  const {
    children,
    onClose,
  } = props;

  const ref = useRef();

  function clickListener(e) {
    e.stopPropagation();

    if (e.target === ref.current && onClose) {
      onClose();
    }
  }

  useEffect(() => {
    window.addEventListener('click', clickListener);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('click', clickListener);
      document.body.style.overflow = 'initial';
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="fixed z-40 top-0 left-0 w-full h-full flex items-center justify-center">
      <div
        className="fixed z-40 top-0 left-0 w-full h-full bg-slate-900/80"
        ref={ref}
      />
      <div className="relative z-50 rounded-lg shadow-lg bg-white w-full max-w-lg">
        {children}
      </div>
    </div>
  );
}

export default Modal;
