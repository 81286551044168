/* ------ Module imports ------ */
import { BrowserRouter, Route, Routes } from 'react-router-dom';

/* ------ Routes ------ */
import AcceptInvite from './views/accept-invite';
import Auth from './views/auth';
import Platform from './views/platform';

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

function createMaterial() {
  const rootElement = typeof window !== 'undefined' ? document.getElementById('__next') : undefined;
  return {
    typography: {
      fontFamily: [
        'ui-sans-serif',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ].join(','),
    },
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
    palette: {
      primary: {
        main: '#3157fe',
        dark: '#111144',
      },
      secondary: {
        main: '#ff0036',
        dark: '#ff0036',
      },
    },
  };
}

function App() {
  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={createTheme(createMaterial())}>
        <BrowserRouter>
          <Routes>
            <Route path="/accept-invite" element={<AcceptInvite />} />
            <Route path="/login" element={<Auth view="login" />} />
            <Route path="/reset-password" element={<Auth view="reset-password" />} />
            <Route path="/signup" element={<Auth view="signup" />} />
            <Route path="*" element={<Platform />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
