import { useCallback, useEffect, useState } from "react";

export default function useResizeObserver(element) {
  const [dimensions, setDimensions] = useState({
    width: element?.offsetWidth,
    height: element?.offsetHeight,
  });
  const onResize = useCallback(() => {
    if (element?.offsetWidth !== dimensions.width || element?.offsetHeight !== dimensions.height) {
      setDimensions({
        width: element?.offsetWidth,
        height: element?.offsetHeight,
      });
    }
  }, [dimensions, element]);
  useEffect(() => {
    let observer;
    if (element) {
      observer = new ResizeObserver(onResize);
      observer.observe(element);
    }
    onResize();
    return () => {
      try { observer.disconnect(); } catch (err) { }
    }
  }, [element, onResize]);
  return dimensions;
}