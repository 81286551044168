/* ------ Components ------ */
import Input from 'components/input';
import Spinner from 'components/spinner';

function RequestResetPassword(props) {
  const {
    onSubmit,
    email,
    error,
    setEmail,
    submitting,
    showSuccess
  } = props;

  return (
    <>
      <div className="flex px-6 border-b border-slate-200 justify-center">
        <h3 className="font-semibold m-4"> {showSuccess ? "Email sent" : "Reset password"}</h3>
      </div>
      <div className="p-6">
        {error && <div className="text-red-500 text-center"> An error has occurred please try again </div>}
        {
          !showSuccess && (
            <form
              onSubmit={onSubmit}
            >
              <Input
                label="Email"
                type='email'
                autoComplete='email'
                error={error}
                value={email}
                onChange={setEmail}
              />
              <button
                className="mt-4 w-full bg-blue-500 hover:bg-blue-600 disabled:hover:bg-blue-500 rounded-lg text-white py-3 text-sm font-semibold relative"
                disabled={submitting}
                type="submit"
              >
                <span className={submitting ? 'invisible' : ''}> Send Recovery Email</span>
                {submitting && (
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <Spinner />
                  </div>
                )}
              </button>
              <hr className='w-full bg-slate-300 mt-6' />
              <div className="flex flex-row justify-center items-center gap-4 mt-4">
                <a className="text-blue-600 text-sm opacity-100 hover:opacity-75 hover:underline" href="/login">
                  Login
                </a>
                <hr className='inline-block w-[1px] h-4 bg-slate-300' />
                <a className="text-blue-600 text-sm opacity-100 hover:opacity-75 hover:underline" href="/signup">
                  Sign Up
                </a>
              </div>
            </form>
          )
        }
        {
          showSuccess && (
            <>
              <div>
                {`You will receive an email at ${email} if this account exists`}
              </div>
              <a
                className="mt-6 w-full bg-blue-500 text-center hover:bg-blue-600 disabled:hover:bg-blue-500 rounded-lg text-white py-3 text-sm font-semibold relative"
                type="submit"
                href="/login"
              >
                Return to login
              </a>
            </>
          )
        }
      </div>
    </>
  )
}

export default RequestResetPassword
