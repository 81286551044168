/* ------ Components ------ */
import Input from 'components/input';
import Select from 'components/select';

/* ------ Local components ------ */
import Image from './image';

function NewTeam(props) {
  const {
    code,
    name,
    onCancel,
    onCodeChanged,
    onLogoUploaded,
    onNameChanged,
    onSave,
    onSportChanged,
    sport,
    sports,
    submitting,
    view,
  } = props;

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <div className="px-12 py-10 border-b border-slate-200">
        <h1 className="font-black text-3xl text-slate-900">New team</h1>
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <h2 className="font-black text-xl text-slate-900">Details</h2>

        <div className="mt-10">
          <p className="text-sm text-slate-700 mb-1">Name</p>
          <Input
            className="w-full max-w-sm"
            disabled={submitting}
            onChange={onNameChanged}
            value={name}
          />
        </div>

        <div className="mt-6">
          <p className="text-sm text-slate-700 mb-1">3 letter team code</p>
          <Input
            className="w-full max-w-sm"
            disabled={submitting}
            onChange={onCodeChanged}
            placeholder="Eg. WEL"
            value={code}
          />
        </div>

        <div className="mt-6">
          <p className="text-sm text-slate-700 mb-1">Sport</p>
          <Select
            className="w-full max-w-sm"
            disabled={submitting}
            onChange={onSportChanged}
            value={sport}
          >
            <option disabled value="">Select a sport...</option>
            {sports.map(sport => (
              <option
                key={sport.id}
                value={sport.id}
              >
                {sport.name}
              </option>
            ))}
          </Select>
        </div>

        <div className="mt-6">
          <Image
            disabled={submitting}
            onImageUploaded={onLogoUploaded}
          />
        </div>
      </div>

      <div className="px-12 py-10">
        <button
          className="bg-blue-200 text-blue-700 rounded px-3 py-2 font-medium"
          disabled={submitting}
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>
        <button
          className="ml-2 bg-blue-600 text-white rounded px-5 py-2 font-medium disabled:cursor-not-allowed"
          disabled={submitting}
          onClick={onSave}
          type="button"
        >
          Save
        </button>
      </div>
    </>
  );
}

export default NewTeam;
