/* ------ Module imports ------ */
import { useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

/* ------ Utils ------ */
import getToken from 'utils/greptcha';
import config from 'config';

/* ------ View import ------ */
import RequestResetPassword from './requestresetpassword.view';

function RequestResetPasswordContainer() {
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState(searchParams.get('email') || '');
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    if (!email.trim()) {
      setError(true);
      return;
    }

    setSubmitting(true);
    try {
      (await axios.post(
        `${config.urls.api}/password_reset`,
        { email },
        {
          headers: {
            'X-Captcha-Token': await getToken('user_create'),
            'X-Captcha-Action': 'user_create',
          },
        },
        ));
    } catch (e) {
      // Silently ignore
    }
    setSubmitting(false);
    setShowSuccess(true);
  }

  return (
    <RequestResetPassword
      setEmail={setEmail}
      error={error}
      email={email}
      onSubmit={onSubmit}
      submitting={submitting}
      showSuccess={showSuccess}
    />
  );
}


export default RequestResetPasswordContainer;
