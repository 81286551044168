import { createFFmpeg } from '@ffmpeg/ffmpeg';

export async function create(options) {
  const ffmpeg = createFFmpeg({
    ...options,
    mainName: 'main',
    corePath: 'https://unpkg.com/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js', //https://github.com/ffmpegwasm/ffmpeg.wasm/issues/137 - coop/cope are too restrictive on other imports but bring significant performance gains
  });
  await ffmpeg.load();
  return ffmpeg;
}

export function destroy(ffmpeg) {
  if (ffmpeg) {
    try {
      for (const path of ffmpeg.FS('readdir', '/')) {
        if (path?.endsWith('.mp4') || path?.endsWith('.m3u8') || path?.endsWith('.ts') || path.endsWith('.zip') || path.endsWith('.txt')) {
          try { ffmpeg.FS('unlink', path); } catch (err) { error(err); }
        }
      }
    } catch (err) {
      error(err);
    }
    try { ffmpeg.exit(); } catch (err) { error(err); }
  }
}

function error(err) {
  console.error(err); //TODO production/staging error reporting
}