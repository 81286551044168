/* ------ Module imports ------ */
import { useState, useContext } from 'react';
import { ChevronDownIcon, MenuIcon, UserCircleIcon } from '@heroicons/react/solid';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';
import initials from 'utils/initials';

/* ------ Local components ------ */
import Dropdown from './dropdown';

function AccountIcon() {
  const { user, account } = useContext(PlatformContext);
  const [src, setSrc] = useState(account?.['profile_image']?.startsWith('http') ? account['profile_image'] : undefined);
  return (
    <div className="flex justify-center items-center text-center w-8 h-8 rounded-full border border-slate-300 overflow-hidden">
      {
        src?.startsWith('http') &&
        <img
          alt=""
          role='presentation'
          className="w-full h-full object-cover"
          onError={() => setSrc(null)}
          src={src} />
      }
      {
        !src?.startsWith('http') &&
        <>
          {
            ((initials(account?.name)?.length || 0) > 0 || (initials(user?.name)?.length || 0) > 0) &&
            <p className='w-full h-full flex text-center justify-center items-center text-white dark:text-blue-500 bg-blue-600 dark:bg-white leading-[0] text-[0.8rem] font-semibold'>
              {(initials(user?.name)?.length || 0) > 0 ? initials(user.name) : initials(account.name)}
            </p>
          }
          {
            (initials(account?.name)?.length || 0) === 0 && (initials(user?.name)?.length || 0) === 0 &&
            <UserCircleIcon className="w-full h-full text-slate-700" />
          }
        </>
      }
    </div>
  );
}

function Header(props) {
  const { onToggleSidebar } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="bg-white border-b border-gray-200 fixed z-40 top-0 left-0 w-full pl-4 flex justify-between">
      <div className="flex items-center">
        {onToggleSidebar && (
          <button
            className="hidden lg:flex rounded-full items-center justify-center hover:bg-gray-200 p-2"
            onClick={onToggleSidebar}
            type="button"
          >
            <MenuIcon className="h-6 text-blue-500" />
          </button>
        )}
        <img
          alt=""
          className="w-12"
          src="/assets/img/logo.png"
        />
      </div>

      <div>
        <button
          className="h-full hover:bg-slate-100 flex items-center px-4"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          type="button"
        >
          <AccountIcon />
          <ChevronDownIcon className="h-4 text-slate-700" />
        </button>

        {dropdownOpen && (
          <div className="relative">
            <Dropdown onClose={() => setDropdownOpen(false)} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
