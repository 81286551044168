/* ------ Module imports ------ */
import { useState } from 'react';

/* ------ Utils ------ */
import auth from 'utils/auth';

/* ------ View import ------ */
import ChangePassword from './change-password.view';

function ChangePasswordContainer(props) {
  const { onClose } = props;

  const [existingPassword, setExistingPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    if (/\s+/.test(newPassword)) {
      // TODO - error
      return;
    }

    if (newPassword.length < 8) {
      // TODO - error
      return;
    }

    if (newPassword !== confirmNewPassword) {
      // TODO - error
      return;
    }

    setSubmitting(true);

    let success = false;
    try {
      await auth.changePassword(existingPassword, newPassword);
      success = true;
    } catch (e) {
      console.log(e);
      // Silently ignore
    }

    setSubmitting(false);

    if (success) {
      onClose();

      // TODO - success message
    } else {
      // TODO - error
    }
  }

  return (
    <ChangePassword
      confirmNewPassword={confirmNewPassword}
      existingPassword={existingPassword}
      newPassword={newPassword}
      onCancel={onClose}
      onConfirmNewPasswordChanged={setConfirmNewPassword}
      onExistingPasswordChanged={setExistingPassword}
      onNewPasswordChanged={setNewPassword}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default ChangePasswordContainer;
