import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Spinner from 'components/spinner/spinner';

export default function Button(props) {
  const buttonProps = Object.assign({}, props);
  delete buttonProps.icon;
  delete buttonProps.label;
  delete buttonProps.loading;
  return (
    <MenuItem
      {...buttonProps}
      disableGutters={true}
      disabled={props.disabled || props.loading}
      className={`flex flex-row w-full justify-start items-center pl-2 pr-4 py-2 ${props.disabled || props.loading ? 'bg-slate-200 text-slate-500' : 'hover:bg-slate-100'} ${props.disabled ? 'cursor-not-allowed' : ''} ${props.loading ? 'cursor-wait' : ''} ${props.className || ''}`.trim()}>
      <>
        {
          (props.loading || props.icon) &&
          <ListItemIcon className='!min-w-[16px] mr-3.5'>
            {
              !props.loading && props.icon &&
              React.cloneElement(props.icon, { fontSize: 'small', className: 'h-4' })
            }
            {
              props.loading &&
              <Spinner className='text-blue-500 h-4' />
            }
          </ListItemIcon>
        }
        <ListItemText disableTypography={true}>
          {props.label || props.children}
        </ListItemText>
      </>
    </MenuItem>
  );
}