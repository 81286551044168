import React, { useCallback, useState } from 'react';

import Menu from '@mui/material/Menu';

import { toast } from 'react-toastify';

import { DotsVerticalIcon, VideoCameraIcon, PlusIcon, DownloadIcon, FolderAddIcon, ExternalLinkIcon } from '@heroicons/react/outline';

import { download, open } from '../../utils/file-manager';
import { add } from '../../utils/workspace-manager';
import { onPlayHighlight } from '../../utils/player-functions';

import Button from './menu-item';
import Share from './share';

function Play(props) {
  return (
    <Button
      id={`play_${props.id || props.highlight?.id}`}
      onClick={() => {
        props.onClose();
        onPlayHighlight(props.id || props.highlight);
      }}
      icon={<VideoCameraIcon />}>
      Play highlight
    </Button>
  );
}

function Download(props) {
  const [downloading, setDownloading] = useState(false);
  return (
    <Button
      id={`download_${props.id || props.highlight?.id}`}
      loading={downloading}
      onClick={async () => {
        setDownloading(true)
        open(await download(props.highlight));
        setDownloading(false);
        props.onClose();
      }}
      icon={<DownloadIcon />}>
      Download clip
    </Button>
  );
}

function Open(props) {
  return (
    <Button
      id={`open_${props.id || props.highlight?.id}`}
      component='a'
      href={`https://player.myactionsport.com/highlight/${props.id || props.highlight?.id}?autoplay=1`}
      target='_blank'
      rel='noopener noreferrer'
      icon={<ExternalLinkIcon />}>
      Open in new tab
    </Button>
  );
}

function Workspace(props) {
  return (
    <Button
      id={`workspace_${props.id || props.highlight?.id}`}
      onClick={() => {
        add(props.highlight);
        props.onClose();
      }}
      icon={<PlusIcon />}>
      Add to workspace
    </Button>
  );
}

function Collection(props) {
  return (
    <Button
      id={`collection_${props.id || props.highlight?.id}`}
      disabled={true}
      onClick={() => {
        toast.error('Coming soon')
        props.onClose();
      }}
      icon={<FolderAddIcon />}>
      Copy to collection
    </Button>
  );
}

export default function ContextMenuButton(props) {
  const [anchor, setAnchor] = useState();
  const onClose = useCallback(() => {
    if (anchor) {
      setAnchor(null);
    }
  }, [anchor]);
  const open = !!anchor;
  return (
    <>
      <button
        id={`context_menu_open_${props.id || props.highlight?.id}`}
        title='Open context menu'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => setAnchor(e?.currentTarget)}
        className='flex justify-center items-center p-2 rounded-full text-slate-400 group-hover:text-slate-700 hover:bg-slate-300 -mr-2'>
        <DotsVerticalIcon className='h-4' />
      </button>
      <Menu
        id={`context_menu_${props.id || props.highlight?.id}`}
        anchorEl={anchor}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': `context_menu_open_${props.id || props.highlight?.id}`,
          dense: true,
          disablePadding: true,
        }}>
        <Play {...props} onClose={onClose} />
        <Download {...props} onClose={onClose} />
        <Open {...props} onClose={onClose} />
        <Share {...props} onClose={onClose} />
        <Workspace {...props} onClose={onClose} />
        <Collection {...props} onClose={onClose} />
      </Menu>
    </>
  );
}