/* ------ Components ------ */
import Modal from 'components/modal';

function ChangeEmail(props) {
  const {
    onCancel,
    onConfirm,
  } = props;

  return (
    <Modal onClose={onCancel}>
      <div className="px-6 py-4">
        <h3 className="font-black text-slate-900 text-lg mb-2">You are attempting to change your email address</h3>
        <p className="text-sm text-slate-900">After changing your email address, you will be required to sign in again</p>
      </div>

      <div className="border-t border-slate-200 px-6 py-4 flex justify-end">
        <button
          className="bg-slate-200 text-slate-700 flex items-center rounded px-3 py-2 text-sm font-medium"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="ml-2 bg-blue-600 text-white flex items-center rounded px-3 py-2 text-sm font-medium"
          onClick={onConfirm}
        >
          Continue
        </button>
      </div>
    </Modal>
  );
}

export default ChangeEmail;
