/* ------ Module imports ------ */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import NewTeam from './new-team.view';

function NewTeamContainer() {
  const { account } = useContext(PlatformContext);

  const navigate = useNavigate();

  const [view, setView] = useState('loading');
  const [sports, setSports] = useState(null);

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [sport, setSport] = useState('');
  const [logo, setLogo] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  async function fetchSports() {
    let loadedSports = null;
    try {
      const { data } = (await api.get('/sport')).data;
      loadedSports = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedSports) {
      setSports(loadedSports);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchSports();
  }, []);

  async function onSave() {
    if (!name.trim() || !sport || code.trim().length !== 3) {
      return;
    }

    setSubmitting(true);

    const postData = {
      account: account.id,
      code,
      logo: logo ? logo.id : null,
      name,
      sport,
    };

    let createdTeam = null;
    try {
      const { data } = (await api.post('/team', postData)).data;
      createdTeam = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (createdTeam) {
      navigate(`/team/${createdTeam.id}`);
    } else {
      // TODO - error
    }
  }

  function onCodeChanged(newCode) {
    setCode(newCode.trim().toUpperCase().slice(0, 3));
  }

  return (
    <NewTeam
      code={code}
      name={name}
      onCancel={() => navigate('/teams')}
      onCodeChanged={onCodeChanged}
      onLogoUploaded={setLogo}
      onNameChanged={setName}
      onSave={onSave}
      onSportChanged={setSport}
      sport={sport}
      sports={sports}
      submitting={submitting}
      view={view}
    />
  );
}

export default NewTeamContainer;
