/* ------ Components ------ */
import Input from 'components/input';
import Select from 'components/select';

function NewAccount(props) {
  const {
    name,
    onNameChanged,
    onSubmit,
    onTypeChanged,
    submitting,
    type,
  } = props;

  return (
    <>
      <div className="px-12 py-10 border-b border-slate-200">
        <h1 className="font-black text-3xl text-slate-900">New account</h1>
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <div>
          <p className="text-sm text-slate-700 mb-1">Organisation/Club/Company Name</p>
          <Input
            className="w-full max-w-sm"
            autoComplete='organization'
            disabled={submitting}
            onChange={onNameChanged}
            value={name}
          />
        </div>

        <div className="mt-6">
          <p className="text-sm text-slate-700 mb-1">Type</p>
          <Select
            className="w-full max-w-sm"
            disabled={submitting}
            onChange={onTypeChanged}
            value={type}
          >
            <option disabled value="">Select a business type...</option>
            <option value="club">Club</option>
            <option value="school">School</option>
          </Select>
        </div>
      </div>

      <div className="px-12 py-10">
        <button
          className="ml-2 bg-blue-600 text-white rounded px-5 py-2 font-medium disabled:cursor-not-allowed"
          disabled={submitting}
          onClick={onSubmit}
          type="button"
        >
          Confirm
        </button>
      </div>
    </>
  );
}

export default NewAccount;
