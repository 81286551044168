/* ------ Module imports ------ */
import { Link } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/solid';

function Teams(props) {
  const {
    teams,
    view,
  } = props;

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <div className="px-12 py-10 border-b border-slate-200">
        <div className="flex items-center justify-between">
          <h1 className="font-black text-3xl text-slate-900">Teams</h1>
          <div>
            <Link
              className="bg-blue-600 text-white flex items-center rounded px-2 py-2"
              to="/team/new"
            >
              <PlusCircleIcon className="h-4 text-white" />
              <span className="text-sm font-medium ml-2">New team</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="px-12 pt-6 pb-10">
        <div className="border border-slate-200 rounded overflow-hidden">
          <div className="border-b border-slate-200 flex px-4 py-2">
            <div className="w-8" />
            <p className="ml-4 text-sm text-slate-600 flex-1">Name</p>
            <p className="text-sm text-slate-600 w-40">Team code</p>
            <p className="text-sm text-slate-600 w-60">Sport</p>
          </div>

          {teams.map(team => (
            <Link
              className="text-left px-4 py-2 w-full even:bg-slate-100 flex items-center"
              key={team.id}
              to={`/team/${team.id}`}
            >
              <div
                className="w-8 h-8 rounded-full bg-cover bg-slate-200 border border-slate-200"
                style={{ backgroundImage: team.logo_url ? `url(${team.logo_url})` : '' }}
              />
              <p className="ml-4 truncate text-sm text-slate-900 flex-1">{team.name}</p>
              <p className="text-sm text-slate-900 w-40">{team.code}</p>
              <p className="text-sm text-slate-900 w-60">{team.sport.name}</p>
            </Link>
          ))}
          {teams.length === 0 && (
            <div className="px-4 py-2">
              <p className="text-sm text-slate-900">No teams added yet</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Teams;
