/* ------ Module imports ------ */
import { PlusCircleIcon, ExternalLinkIcon } from '@heroicons/react/solid';

/* ------ Components ------ */
import Input from 'components/input';
import Select from 'components/select';

/* ------ Local components ------ */
import YoutubeChannel from './youtube-channel';

function Account(props) {
  const {
    account,
    addingYoutubeChannel,
    onAddYoutubeChannel,
    onChannelRemoved,
    view,
    youtubeChannels,
  } = props;

  if (view === 'loading' || view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <div className="px-12 py-10 border-b border-slate-200">
        <h2 className="font-black text-xl text-slate-900 mb-6">Details</h2>

        <div className="flex flex-col max-w-sm">
          <Input
            label="Account name"
            value={account.name}
          />
          <Select
            className="mt-6"
            label="Organization type"
            value={account.type}
          >
            <option value="school">School</option>
            <option value="club">Club</option>
          </Select>
          <p className="text-slate-700 text-sm font-semibold mt-6 mb-1">
            Scoring Platform Code
          </p>
          <p className='inline-flex flex-row justify-start items-center gap-2'>
          {(account.code?.slice(0, 4) || 'N') + '-' + (account.code?.slice(4) || 'A')}
          <a
              href={`https://scoring.myactionsport.com?code=${account.code}`}
              target='_blank'
              rel='noopener noreferrer'
              className='inline-flex flex-row justify-center items-center gap-1 text-blue-500 hover:underline opacity-100 hover:opacity-75'>
              Open
              <ExternalLinkIcon className='h-4' />
            </a>
          </p>
          <span className='inline text-xs text-slate-500 mt-1'>
            {'You can share this code with members of your organisation without having to have them create usernames and passwords. Anyone with this code can start new games on the '}
            <a
              href={`https://scoring.myactionsport.com?code=${account.code}`}
              rel='noopener noreferrer'
              className='inline-flex flex-row justify-center items-center gap-1 text-blue-500 hover:underline opacity-100 hover:opacity-75'>
              scoring platform.
              <ExternalLinkIcon className='h-3' />
            </a>
          </span>
        </div>
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <div className="flex items-center mb-6">
          <img
            alt="YouTube"
            className="w-8"
            src="/assets/img/youtube.svg"
          />
          <h2 className="ml-4 font-black text-xl text-slate-900">YouTube Live</h2>
        </div>

        {youtubeChannels.map(channel => (
          <YoutubeChannel
            channel={channel}
            key={channel.id}
            onRemoved={() => onChannelRemoved(channel)}
          />
        ))}

        <button
          className="bg-blue-100 hover:bg-blue-200 text-blue-600 rounded-lg px-4 py-2 inline-block"
          disabled={addingYoutubeChannel}
          onClick={onAddYoutubeChannel}
        >
          <div className="flex items-center">
            <PlusCircleIcon className="h-4" />
            <p className="ml-2 font-semibold text-xs">{`Add ${youtubeChannels.length > 0 ? 'another' : 'a'} YouTube channel`}</p>
          </div>
        </button>
      </div>

      <div className="px-12 py-10 border-b border-slate-200">
        <div className="flex items-center mb-6">
          <img
            alt="Facebook"
            className="w-8"
            src="/assets/img/facebook.svg"
          />
          <h2 className="ml-4 font-black text-xl text-slate-900">Facebook Live</h2>
        </div>

        <p>Support for Facebook Live is coming soon!</p>
      </div>
    </>
  );
}

export default Account;
