import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { setVideoCodec, useVideoCodec } from 'views/game/utils/game-file-downloader';

export default function VideoCodec() {
  const codec = useVideoCodec();
  return (
    <FormControl size='small' fullWidth={true}>
      <InputLabel id='video-codec'>Video File</InputLabel>
      <Select
        labelId='video-codec'
        id='video-codec'
        value={codec || 'none'}
        label='Video File'
        renderValue={(v) => {
          let label;
          switch (v || '') {
            case 'libx264':
              label = 'MP4';
              break;
            case 'libx265':
              label = 'MP4 (x265)';
              break;
            case 'libvpx-vp9':
              label = 'WebM';
              break;
            case 'libvpx':
              label = 'WebM (VP8)';
              break;
            case '':
            case 'none':
            default:
              label = 'None';
              break;
          }
          return label;
        }}
        onChange={(e) => setVideoCodec(e.target.value)}>
        <MenuItem value='none' className='flex flex-col justify-start items-start text-left'>
          <em>None</em>
          <span className='text-sm text-slate-400'>The download will be audio only</span>
        </MenuItem>
        <MenuItem value='libx264' className='flex flex-col justify-start items-start text-left'>
          MP4
          <span className='text-sm text-slate-400'>x264</span>
        </MenuItem>
        <MenuItem value='libx265' disabled={true} className='flex flex-col justify-start items-start text-left'>
          MP4
          <span className='text-sm text-slate-400'>x265</span>
        </MenuItem>
        <MenuItem value='libvpx-vp9' className='flex flex-col justify-start items-start text-left'>
          WebM
          <span className='text-sm text-slate-400'>VP9</span>
          <span className='text-xs italic text-slate-400'>Warning: CPU intensive, will take a long time</span>
        </MenuItem>
        <MenuItem value='libvpx' className='flex flex-col justify-start items-start text-left'>
          WebM
          <span className='text-sm text-slate-400'>VP8</span>
          <span className='text-xs italic text-slate-400'>Warning: CPU intensive, will take a long time</span>
        </MenuItem>
      </Select>
    </FormControl>
  );
}