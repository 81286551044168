/* ------ Module imports ------ */
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

/* ------ Utils ------ */
import auth from 'utils/auth';

/* ------ View import ------ */
import Login from './login.view';

function LoginContainer() {
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  async function onSubmit(e) {
    e.preventDefault();

    if (!email || !password) {
      setError(true);
    }

    setSubmitting(true);
    setError(false);

    let success = false;
    try {
      await auth.login(email, password);
      success = true;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (success) {
      let redirectUrl = '/';
      if (searchParams.get('redirect')) {
        redirectUrl = searchParams.get('redirect');
      }

      navigate(redirectUrl);
    } else {
      setError(true);
    }
  }

  return (
    <Login
      email={email}
      onEmailChanged={setEmail}
      onPasswordChanged={setPassword}
      onSubmit={onSubmit}
      password={password}
      submitting={submitting}
      error={error}
    />
  );
}

export default LoginContainer;
