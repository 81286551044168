/* ------ Module imports ------ */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import NewOverlay from './new-overlay.view';

function NewOverlayContainer() {
  const { account } = useContext(PlatformContext);

  const [view, setView] = useState('loading');
  const [sports, setSports] = useState(null);

  const [name, setName] = useState('');
  const [sport, setSport] = useState('');
  const [template, setTemplate] = useState(null);
  const [customizationData, setCustomizationData] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  async function fetchSports() {
    let loadedSports = null;
    try {
      const { data } = (await api.get('/sport')).data;
      loadedSports = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedSports) {
      setSports(loadedSports);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchSports();
  }, []);

  useEffect(() => {
    setTemplate(null);
  }, [sport]);

  useEffect(() => {
    setCustomizationData({});
  }, [template]);

  function onFieldUpdated(key, data) {
    const updatedData = { ...customizationData };
    updatedData[key] = data;
    setCustomizationData(updatedData);
  }

  async function onSave() {
    if (!name.trim() || !sport.trim() || !template || Object.keys(customizationData).length === 0) {
      return;
    }

    setSubmitting(true);

    const postData = {
      account: account.id,
      custom_data: customizationData,
      name,
      sport,
      template: template.id,
    };

    let overlay = null;
    try {
      const { data } = (await api.post('/overlay', postData)).data;
      overlay = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (overlay) {
      navigate(`/overlay/${overlay.id}`);
    } else {
      // TODO - error
    }
  }

  return (
    <NewOverlay
      canSave={name.trim() && Object.keys(customizationData).length > 0}
      name={name}
      onCancel={() => navigate('/overlays')}
      onFieldUpdated={onFieldUpdated}
      onNameChanged={setName}
      onSave={onSave}
      onSportChanged={setSport}
      onTemplateSelected={setTemplate}
      sport={sport}
      sports={sports}
      submitting={submitting}
      template={template}
      view={view}
    />
  );
}

export default NewOverlayContainer;
