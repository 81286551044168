import dayjs from 'dayjs';

import { create as createFFmpeg, destroy as destroyFFmpeg } from './ffmpeg.js';

export default async function create(files) {
  files = (files || []).filter(f => (f?.blob?.size || 0) > 0 && (f.filename?.length || 0) > 0);
  if (files.length > 1) {
    const ffmpeg = await createFFmpeg();
    const now = dayjs().unix();
    const target = `${now}-supercut.mp4`;
    const concatenated = [];
    for (const file of files) {
      try {
        ffmpeg.FS('writeFile', file.filename, new Uint8Array(await file.blob.arrayBuffer()));
        concatenated.push({ name: file.filename, duration: file.duration || 0 });
      } catch (err) { 
        console.log(err);
      }
    }
    ffmpeg.FS('writeFile', `${now}.txt`, concatenated.map(c => `file '${c.name}'`).join('\n'));
    await ffmpeg.run(
      '-y',
      '-hide_banner',
      '-f', 'concat',
      '-safe', '0',
      '-i', `${now}.txt`,
      '-c:v', 'copy',
      '-c:a', 'copy',
      target
    );
    const data = ffmpeg.FS('readFile', target);
    destroyFFmpeg(ffmpeg);
    return {
      blob: new Blob([data.buffer], { type: 'video/mp4' }),
      metadata: {
        type: 'video/mp4',
        created: dayjs().toISOString(),
        duration: concatenated.map(c => c.duration || 0).reduce((a, v) => a + v, 0),
      }
    };
  }
  else {
    throw new Error('Invalid highlights provided, please check and try again.');
  }
}