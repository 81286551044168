import { useContext } from 'react';

import Pagination from 'components/pagination';

/* ------ Local components ------ */
import Game from './game';

import PlatformContext from 'views/platform/context';

import ExitToAppIcon from '@mui/icons-material/ExitToAppOutlined';
import LiveIcon from '@mui/icons-material/LiveTvOutlined';
import ReplayIcon from '@mui/icons-material/ReplayOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import Button from '@mui/material/Button';

import Spinner from 'components/spinner/spinner';

import { toast } from 'react-toastify';

import Induction, { useStep } from 'views/induction/induction';

function Error() {
  return (
    <div className='flex flex-col justify-center items-center px-12 py-10'>
      <div className='flex flex-col justify-center items-center max-w-[500px] w-full text-center'>
        <ReportOutlinedIcon className='text-8xl' />
        <h1 className='text-4xl my-6'>Oops</h1>
        <p className='whitespace-pre-line text-center leading-tight'>Something went wrong. Please reload and try again or reach out to support for&nbsp;help.</p>
        <Button
          onClick={() => window.location.reload()}
          variant='outlined'
          size='small'
          color='inherit'
          startIcon={<ReplayIcon />}
          className='mt-6 text-blue-500'>
          Reload
        </Button>
      </div>
    </div>
  );
}

function Empty() {
  const { account } = useContext(PlatformContext);
  return (
    <div className='flex flex-col justify-center items-center px-12 py-10'>
      <div className='flex flex-col justify-center items-center max-w-[500px] w-full text-center'>
        <LiveIcon className='text-8xl' />
        <h1 className='text-4xl my-6'>No Livestreams (yet!)</h1>
        <p className='whitespace-pre-line leading-tight'>Your livestreams will end up here. You can share this login code with your scorers and videographers so they can get&nbsp;started:</p>
        <button
          onClick={() => {
            navigator.clipboard.writeText(`https://scoring.myactionsport.com?code=${account.code}`);
            toast.info('Copied scoring link to clipboard.\nPaste it in a message to your scorers.');
          }}
          className='text-lg leading-none font-bold mt-8'>
          {`https://scoring.myactionsport.com?code=${account.code}`}
        </button>
        <Button
          component='a'
          href={`https://scoring.myactionsport.com?code=${account.code}`}
          target='_blank'
          rel='noopener noreferrer'
          variant='contained'
          color='inherit'
          startIcon={<ExitToAppIcon />}
          className='mt-6 bg-blue-500 hover:bg-blue-600 text-white'>
          Go Live Now
        </Button>
      </div>
    </div>
  );
}

function Live(props) {
  const {
    live,
    livePage,
    liveTotalPages,
    onChangeLivePage,
  } = props;
  return (
    <div className='px-12 py-10 border-t border-b border-slate-200'>
      <h2 className='font-black text-xl text-slate-900 mb-10'>Currently live</h2>
      <div className='flex flex-wrap -mx-2'>
        {
          live.map((game) =>
            <Game
              game={game}
              key={game.id} />
          )
        }
      </div>
      {
        liveTotalPages > 1 &&
        <Pagination
          onChangePage={onChangeLivePage}
          page={livePage}
          total={liveTotalPages} />
      }
    </div>
  );
}

function Recent(props) {
  const {
    games,
    gamesPage,
    gamesTotalPages,
    onChangeGamesPage,
  } = props;
  return (
    <div className='px-12 py-10 border-b border-slate-200'>
      <h2 className='font-black text-xl text-slate-900 mb-10'>Recent games</h2>
      <div className='flex flex-wrap -mx-2'>
        {
          games.map((game) =>
            <Game
              game={game}
              key={game.id} />
          )
        }
      </div>
      {
        gamesTotalPages > 1 &&
        <Pagination
          onChangePage={onChangeGamesPage}
          page={gamesPage}
          total={gamesTotalPages} />
      }
    </div>
  );
}

function Livestreams(props) {
  const { games, live, view } = props;
  const currentInductionStep = useStep();
  const isInducting = (live?.length || 0) === 0 && (games?.length || 0) === 0 && typeof currentInductionStep === 'number' && !isNaN(currentInductionStep) && isFinite(currentInductionStep) && currentInductionStep > -1;
  return (
    <>
      {
        view === 'loading' &&
        <div className='flex flex-col justify-center items-center px-12 py-10 min-h-[150px]'>
          <Spinner className='text-blue-500' />
        </div>
      }
      {
        isInducting && view !== 'loading' &&
        <Induction />
      }
      {
        !isInducting && view !== 'loading' &&
        <>
          <div className='px-12 py-10'>
            <h1 className='font-black text-3xl text-slate-900'>Livestreams</h1>
          </div>
          {
            view === 'error' &&
            <Error />
          }
          {
            view === 'main' &&
            <>
              {
                (live?.length || 0) === 0 && (games?.length || 0) === 0 &&
                <Empty />
              }
              {
                ((live?.length || 0) > 0 || (games?.length || 0) > 0) &&
                <>
                  {
                    (live?.length || 0) > 0 &&
                    <Live
                      live={live}
                      livePage={props.livePage}
                      liveTotalPages={props.liveTotalPages}
                      onChangeLivePage={props.onChangeLivePage} />
                  }
                  {
                    (games?.length || 0) > 0 &&
                    <Recent
                      games={games}
                      gamesPage={props.gamesPage}
                      gamesTotalPages={props.gamesTotalPages}
                      onChangeGamesPage={props.onChangeGamesPage} />
                  }
                </>
              }
            </>
          }
        </>
      }
    </>
  )
}

export default Livestreams;