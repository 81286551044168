/* ------ Local components ------ */
import { useState } from 'react';

import { FolderDownloadIcon, TrashIcon } from '@heroicons/react/outline';

import AccessoryContainer, { Title, IconButton } from '../accessory-container';
import { clear, getFiles, open, useFiles } from '../utils/file-manager';
import Download from './download';
import zip from '../utils/archive';

export default function Downloads(props) {
  const [removing, setRemoving] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const files = useFiles();
  const massActionsDisabled = (files?.length || 0) === 0;
  return (
    <AccessoryContainer
      title={
        <Title title='Downloads'>
          <IconButton
            tip='Download all files in a .zip'
            title='Download all'
            loading={downloading}
            disabled={massActionsDisabled}
            icon={<FolderDownloadIcon />}
            onClick={async () => {
              setDownloading(true);
              open(await zip(getFiles()), 'download.zip');
              setDownloading(false);
            }} />
          <IconButton
            tip='Clear all files from download manager (you can create these again later, does not delete any highlights)'
            title='Clear all files from download manager'
            loading={removing}
            disabled={massActionsDisabled}
            icon={<TrashIcon />}
            onClick={async () => {
              setRemoving(true);
              await clear();
              setRemoving(false);
            }}
            className='-ml-1 -mr-2' />
        </Title>
      }
      empty='Any downloadable files you create will end up here so you can quickly access them again.'
      {...props}>
      {
        (files?.length || 0) > 0 &&
        files.map((file, i) => <Download key={i} {...file} />)
      }
    </AccessoryContainer>
  );
}