import React from 'react';

import Spinner from 'components/spinner/spinner';

import Tippy from '@tippyjs/react';

export function IconButton(props) {
  const button = React.createElement(
    props.loading || props.disabled ? 'span' : (props.component || 'button'),
    {
      title: props.title || props.tip || props.tippy,
      'aria-label': props['aria-label'],
      disabled: props.loading || props.disabled,
      href: props.href,
      download: props.download,
      target: props.target,
      rel: props.rel,
      onClick: props.onClick,
      className: `flex justify-center items-center p-2 rounded-full ${props.loading ? 'cursor-wait' : ''} ${props.disabled ? 'text-slate-400 cursor-not-allowed' : 'text-slate-500'} ${!props.loading && !props.disabled ? 'hover:text-slate-700 hover:bg-slate-300' : ''} ${props.className || ''}`.replace(/\s\s+/, ' ').trim()
    },
    props.loading ? <Spinner className='text-blue-500 h-5' /> : React.cloneElement(props.icon, { ...props.icon.props, className: `h-5 ${props.icon.props?.className || ''} ${props.icon.className || ''}`.replace(/\s\s+/, ' ').trim() })
  );
  if (props.tip || props.tippy) {
    return (
      <Tippy content={props.tip || props.tippy} delay={500}>
        {button}
      </Tippy>
    );
  }
  else {
    return button;
  }
}

export function Title(props) {
  return (
    <div className='w-full flex flex-row justify-start items-center -mt-2'>
      <p className='flex-1 mr-4'>{props.title}</p>
      {props.buttons || props.children}
    </div>
  );
}

export function RowButton(props) {
  return (
    <Tippy content={props.tip || props.tippy} delay={500}>
      {
        React.createElement(
          props.loading || props.disabled ? 'span' : (props.component || 'button'),
          {
            title: props.title || props.tip || props.tippy,
            'aria-label': props['aria-label'],
            disabled: props.loading || props.disabled,
            href: props.href,
            download: props.download,
            target: props.target,
            rel: props.rel,
            onClick: props.onClick,
            className: `flex justify-center items-center p-2 rounded-full ${props.loading ? 'cursor-wait' : ''} ${props.disabled ? 'text-slate-300 cursor-not-allowed' : 'text-slate-400'} ${!props.loading && !props.disabled ? 'group-hover:text-slate-700 hover:bg-slate-300' : ''} ${props.className || ''}`.replace(/\s\s+/, ' ').trim()
          },
          props.loading ? <Spinner className='text-blue-500 h-4' /> : React.cloneElement(props.icon, { className: `h-4 ${props.icon.className}`.replace(/\s\s+/, ' ').trim() })
        )
      }
    </Tippy>
  );
}

export const Row = React.forwardRef(function Row(props, ref) {
  return (
    <div
      ref={ref}
      title={props.title}
      data-handler-id={props.handlerId}
      className={`group w-full flex flex-row justify-start items-center hover:bg-slate-200 rounded-lg p-2 ${props.dragging ? 'opacity-0' : ''} ${props.className || ''}`.replace(/\s\s+/, ' ').trim()}
      style={props.style}>
      <div className='flex flex-col flex-1 items-start text-left justify-start text-slate-700 text-sm'>
        {props.content || props.children}
      </div>
      {props.actions || props.buttons}
    </div>
  );
});

const AccessoryContainer = React.forwardRef(function AccessoryContainer(props, ref) {
  return (
    <div ref={ref} style={props.style} className={`flex flex-col justify-start items-start w-full bg-slate-100 border border-slate-200 rounded relative ${props.className || ''}`.trim()}>
      <span className={`flex flex-row w-full font-black text-slate-900 bg-slate-100 border-slate-200 pt-4 px-4 pb-2 ${props.children ? 'border-b' : ''}`}>
        {props.title}
      </span>
      <div className='flex flex-1 w-full relative'>
        <div className='flex flex-col justify-start items-start w-full h-full absolute inset-0 overflow-auto px-2 py-1'>
          {
            !props.children &&
            <p className='text-slate-700 text-sm px-2 pb-4'>{props.empty}</p>
          }
          {props.children}
        </div>
      </div>
    </div>
  );
});

export default AccessoryContainer;