/* ------ Module imports ------ */
import { Link as RRLink } from 'react-router-dom';

function Link(props) {
  const {
    href,
    icon,
    label,
    selected,
    small,
  } = props;

  return (
    <RRLink
      className={`mb-1 flex items-center ${small ? 'w-full justify-center rounded' : 'px-4 rounded-l'} py-2 ${selected ? 'bg-blue-100' : 'hover:bg-blue-100'}`}
      to={href}
    >
      <i className={`text-2xl mar-icon-${icon} text-blue-600`} />
      {label && <span className="text-gray-900 ml-4 text-sm">{label}</span>}
    </RRLink>
  );
}

export default Link;
