/* ------ Module imports ------ */
import { useContext, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'views/platform/context';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Invite from './invite.view';

function InviteContainer(props) {
  const {
    onClose,
    onInviteAdded,
  } = props;

  const { account } = useContext(PlatformContext);

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);

  async function onSubmit() {
    if (!email.trim()) {
      setError(true);
      return;
    }

    setError(false);
    setSubmitting(true);

    let createdInvite = null;
    try {
      const { data } = (await api.post('/invite', { account: account.id, email })).data;
      createdInvite = data;
    } catch (e) {
      if (e?.response?.data?.code) {
        setSubmissionError(e.response.data.code);
      } else {
        setSubmissionError('something_went_wrong');
      }
    }

    setSubmitting(false);

    if (createdInvite) {
      onInviteAdded(createdInvite);
      onClose();
    }
  }

  return (
    <Invite
      email={email}
      error={error}
      onCancel={onClose}
      onEmailChanged={setEmail}
      onSubmit={onSubmit}
      submissionError={submissionError}
      submitting={submitting}
    />
  );
}

export default InviteContainer;
