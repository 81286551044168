import { useEffect, useState } from 'react';

import equal from 'fast-deep-equal';

import api from 'utils/api';

import initHighlightsManager from './highlights-manager';
import initFileManager from './file-manager';
import initWorkspaceManager from './workspace-manager';

let game;
let listeners = [];

export function useGame() {
  const [values, setValues] = useState(getGame());
  useEffect(() => {
    const onUpdate = () => {
      const updated = getGame();
      if (!equal(values, updated)) {
        setValues(updated);
      }
    }
    return registerListener(onUpdate);
  }, [values]);
  return values;
}

export function useGameValue(key = '') {
  const [value, setValue] = useState(getGame()?.[key]);
  useEffect(() => {
    const onUpdate = () => {
      const updated = getGame();
      if (!equal(value?.[key], updated?.[key])) {
        setValue(updated?.[key]);
      }
    }
    return registerListener(onUpdate);
  }, [value, key]);
  return value;
}

export function useGameTitle() {
  return useGameValue('title');
}

export function useGameId() {
  return useGameValue('id');
}

export function useSport() {
  return useGameValue('sport');
}

export function useStarted(orCreated) {
  const start = useGameValue('stream_started');
  const created = useGameValue('created')
  return start || (orCreated ? created : undefined);
}

export function useCreated(orStarted) {
  const start = useGameValue('stream_started');
  const created = useGameValue('created')
  return created || (orStarted ? start : undefined);
}

export default async function init(gameId) {
  const promises = await Promise.allSettled([
    (await api.get(`/game/${gameId}?expand=sport`))?.data?.data,
    (await api.get(`/highlight?game=${gameId}&expand=tags`))?.data?.data
  ]);
  game = promises[0].value || {};
  const highlights = Array.isArray(promises[1].value) ? promises[1].value : [];
  await Promise.allSettled([
    initHighlightsManager(highlights),
    initFileManager(),
  ]);
  await initWorkspaceManager();
  notifyListeners();
}

export async function invalidate() {
  if ((game?.id?.length || 0) > 0) {
    const newValues = (await api.get(`/game/${game.id}?expand=sport`))?.data?.data;
    if ((!equal(newValues, game))) {
      game = newValues;
      if (newValues?.id !== game?.id) {
        await init(newValues?.id);
      }
      else {
        notifyListeners();
      }
    }
  }
  return get();
}

export function get() {
  try {
    return structuredClone(game);
  } catch (err) {
    return typeof game !== 'undefined' && game !== null ? JSON.parse(JSON.stringify(game)) : null;
  }
}

export function getGame() {
  return get();
}

export function getValues() {
  return get();
}

export function getGameId() {
  return get()?.id;
}

export function getGameTitle() {
  return get()?.title;
}

export function getStarted() {
  return getGame()?.['stream_started'];
}

export function getCreated() {
  return getGame()?.created;
}

function notifyListeners() {
  for (const listener of listeners) {
    requestAnimationFrame(function () { try { listener(getGame()); } catch (err) { } });
  }
}

export function registerListener(listener) {
  listeners.push(listener);
  requestAnimationFrame(function () { try { listener(getGame()); } catch (err) { } });
  return () => deregisterListener(listener);
}

export function deregisterListener(listener) {
  listeners = listeners.filter(l => l !== listener);
}