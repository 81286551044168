/* ------ Module imports ------ */
import { ExclamationCircleIcon } from '@heroicons/react/solid';

/* ------ Components ------ */
import Modal from 'components/modal';
import Spinner from 'components/spinner';

function Remove(props) {
  const {
    error,
    onCancel,
    onSubmit,
    submitting,
  } = props;

  return (
    <Modal>
      <div className="px-6 py-4">
        <h3 className="font-black text-slate-900 text-lg mb-2">Delete team</h3>
        <p className="text-sm text-slate-900">Are you sure you want to delete this team?</p>
      </div>

      <div className="border-t border-slate-200 px-6 py-4">
        {error && (
          <div className="rounded-lg bg-red-200 px-4 py-3 mb-4 relative flex">
            <ExclamationCircleIcon className="h-5 text-red-600 flex-shrink-0" />
            <p className="ml-4 text-sm text-red-600 font-medium">Could not remove team. Something went wrong.</p>
          </div>
        )}

        <div className="flex justify-end">
          <button
            className="bg-slate-200 text-slate-700 flex items-center rounded px-3 py-2 text-sm font-medium"
            disabled={submitting}
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="ml-2 bg-red-600 text-white flex items-center rounded px-3 py-2 text-sm font-medium relative"
            disabled={submitting}
            onClick={onSubmit}
          >
            <span className={submitting ? 'invisible' : ''}>Delete team</span>
            {submitting && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <Spinner />
              </div>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default Remove;
