/* ------ Module imports ------ */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/* ------ Utils ------ */
import api from 'utils/api';

/* ------ View import ------ */
import Overlay from './overlay.view';

async function update(key, value) {
  
}

function OverlayContainer() {
  const { id } = useParams();

  const [overlay, setOverlay] = useState(null);
  const [view, setView] = useState('loading');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(null);

  async function fetchOverlay() {
    let loadedOverlay = null;
    try {
      const { data } = (await api.get(`/overlay/${id}?expand=template.sport`)).data;
      loadedOverlay = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedOverlay && loadedOverlay.account) {
      setOverlay(loadedOverlay);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchOverlay();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function onRenamed(name) {
    setOverlay({ ...overlay, name });
    setModal(null);
  }

  return (
    <Overlay
      dropdownOpen={dropdownOpen}
      modal={modal}
      onCloseDropdown={() => setDropdownOpen(false)}
      onCloseModal={() => setModal(null)}
      onDelete={() => setModal('remove')}
      onOpenDropdown={() => setDropdownOpen(true)}
      onRename={() => setModal('rename')}
      onRenamed={onRenamed}
      overlay={overlay}
      view={view}
    />
  );
}

export default OverlayContainer;
